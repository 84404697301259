import React from "react"
import ContentLoader from "react-content-loader"

export default function OneLineLoader(props) {
    return(
        <ContentLoader 
        speed={2}
        width={props.width || 230}
        height={props.height || 16.5}
        backgroundColor="var(--lightGrey)"
        foregroundColor="var(--darkLightGrey)"
        {...props}
        >
            <rect x="0" y={props.y || "8"} rx="2" ry="2" width={props.width || "230"} height={props.rectHeight || "6"} /> 
        </ContentLoader>
    )
}