import styles from './popup.module.css'

import React, { Suspense, lazy, useEffect, useReducer, useContext, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import { useTrack } from '../tracking';
import { useScrollbar } from '../scrollbar';

import Icon from '../../components/Icon'
import IconButton from '../../components/IconButton';

import CreateReviewPopup from '../../popups/CreateReviewPopup'; // const CreateReviewPopup = lazy(() => import('../../popups/CreateReviewPopup'));
import LoginPopup from '../../popups/LoginPopup'; // const LoginPopup = lazy(() => import('../../popups/LoginPopup'));
import SignUpPopup from '../../popups/SignUpPopup'; // const SignUpPopup = lazy(() => import('../../popups/SignUpPopup'));
import SearchContentPopup from '../../popups/SearchContentPopup'; // const SearchContentPopup = lazy(() => import('../../popups/SearchContentPopup'));
import AddToListPopup from '../../popups/AddToListPopup'; // const AddToListPopup = lazy(() => import('../../popups/AddToListPopup'));
import ManageListContentPopup from '../../popups/ManageListContentPopup'; // const ManageListContentPopup = lazy(() => import('../../popups/ManageListContentPopup'));
import ShareReviewPopup from '../../popups/ShareReviewPopup'; // const ShareReviewPopup = lazy(() => import('../../popups/ShareReviewPopup'));
import SaveDraftPopup from '../../popups/SaveDraftPopup'; // const SaveDraftPopup = lazy(() => import('../../popups/SaveDraftPopup'));

const UpdateReviewPopup = lazy(() => import('../../popups/UpdateReviewPopup'));
const UpdateRatingPopup = lazy(() => import('../../popups/UpdateRatingPopup'));
const EditTextPopup = lazy(() => import('../../popups/EditTextPopup'));

const ListLikesPopup = lazy(() => import('../../popups/ListLikesPopup'));

const BlockedUsersPopup = lazy(() => import('../../popups/BlockedUsersPopup'));
const ChangePasswordPopup = lazy(() => import('../../popups/ChangePasswordPopup'));

const SearchBannerPopup = lazy(() => import('../../popups/SearchBannerPopup'));
const ChooseBannerPopup = lazy(() => import('../../popups/ChooseBannerPopup'));
const ChooseProfilePicturePopup = lazy(() => import('../../popups/ChooseProfilePicturePopup'));
const ChooseImagePopup = lazy(() => import('../../popups/ChooseImagePopup'));
const CropGIFPopup = lazy(() => import('../../popups/CropGIFPopup'));
const CropGIFBannerPopup = lazy(() => import('../../popups/CropGIFBannerPopup'));
const ChooseProfileFramePopup = lazy(() => import('../../popups/ChooseProfileFramePopup'));

const WriteCommentPopup = lazy(() => import('../../popups/WriteCommentPopup'));
const EditCommentPopup = lazy(() => import('../../popups/EditCommentPopup'));
const DeleteCommentPopup = lazy(() => import('../../popups/DeleteCommentPopup'));

const PinContentPopup = lazy(() => import('../../popups/PinContentPopup'));

const DeleteReviewPopup = lazy(() => import('../../popups/DeleteReviewPopup'));
const DeleteRatingPopup = lazy(() => import('../../popups/DeleteRatingPopup'));
const DeleteListPopup = lazy(() => import('../../popups/DeleteListPopup'));
const DeleteBannerPopup = lazy(() => import('../../popups/DeleteBannerPopup'));
const DeleteMessagePopup = lazy(() => import('../../popups/DeleteMessagePopup'));
const DeleteGroupPopup = lazy(() => import('../../popups/DeleteGroupPopup'));
const DeletePollOptionPopup = lazy(() => import('../../popups/DeletePollOptionPopup'));
const BlockUserPopup = lazy(() => import('../../popups/BlockUserPopup'));
const SubmitBannerPopup = lazy(() => import('../../popups/SubmitBannerPopup'));
const DeleteAccountPopup = lazy(() => import('../../popups/DeleteAccountPopup'));
const DeleteAccountConfirmPopup = lazy(() => import('../../popups/DeleteAccountConfirmPopup'));
const DeleteSuggestedPopup = lazy(() => import('../../popups/DeleteSuggestedPopup'));
const ForgotPasswordPopup = lazy(() => import('../../popups/ForgotPasswordPopup'));
const ReportContentPopup = lazy(() => import('../../popups/ReportContentPopup'));
const ReportDuplicatePopup = lazy(() => import('../../popups/ReportDuplicatePopup'));
const DuplicatedAlbumsPopup = lazy(() => import('../../popups/DuplicatedAlbumsPopup'));
const DuplicatedTracksPopup = lazy(() => import('../../popups/DuplicatedTracksPopup'));
const ArtistDiscographyPopup = lazy(() => import('../../popups/ArtistDiscographyPopup'));

const BiographyPopup = lazy(() => import('../../popups/BiographyPopup'));
const ExtraInformationPopup = lazy(() => import('../../popups/ExtraInformationPopup'));
const CookiesPopup = lazy(() => import('../../popups/CookiesPopup'));

const HelpPopup = lazy(() => import('../../popups/HelpPopup'));
const ContributePopup = lazy(() => import('../../popups/ContributePopup'));
const ContributorInfoPopup = lazy(() => import('../../popups/ContributorInfoPopup'));
const ContributorProPopup = lazy(() => import('../../popups/ContributorProPopup'));
const BasicQuestionPopup = lazy(() => import('../../popups/BasicQuestionPopup'));
const MyContributionsPopup = lazy(() => import('../../popups/MyContributionsPopup'));
const SearchArtistAndSuggestedPopup = lazy(() => import('../../popups/SearchArtistAndSuggestedPopup'));
const FavoriteGenresPopup = lazy(() => import('../../popups/FavoriteGenresPopup'));
const ProfileInfoPopup = lazy(() => import('../../popups/ProfileInfoPopup'));
const ManageDraftsPopup = lazy(() => import('../../popups/ManageDraftsPopup'));
const ManageCommentsPopup = lazy(() => import('../../popups/ManageCommentsPopup'));
const ManageGroupPermissionsPopup = lazy(() => import('../../popups/ManageGroupPermissionsPopup'));
const GroupSettingsPopup = lazy(() => import('../../popups/GroupSettingsPopup'));
const GroupMembersPopup = lazy(() => import('../../popups/GroupMembersPopup'));

const ShareListPopup = lazy(() => import('../../popups/ShareListPopup'));
const ShareRatingPopup = lazy(() => import('../../popups/ShareRatingPopup'));
const ShareUserPopup = lazy(() => import('../../popups/ShareUserPopup'));
const ShareAlbumPopup = lazy(() => import('../../popups/ShareAlbumPopup'));
const ShareTrackPopup = lazy(() => import('../../popups/ShareTrackPopup'));
const ShareArtistPopup = lazy(() => import('../../popups/ShareArtistPopup'));
const ShareThreadPopup = lazy(() => import('../../popups/ShareThreadPopup'));
const ShareSuggestedAlbumPopup = lazy(() => import('../../popups/ShareSuggestedAlbumPopup'));

const CreateGroupPopup = lazy(() => import('../../popups/CreateGroupPopup'));
const AddGroupMemberPopup = lazy(() => import('../../popups/AddGroupMemberPopup'));
const SendContentPopup = lazy(() => import('../../popups/SendContentPopup'));
const ChooseGroupPicturePopup = lazy(() => import('../../popups/ChooseGroupPicturePopup'));
const EditGroupNamePopup = lazy(() => import('../../popups/EditGroupNamePopup'));
const EditGroupDescriptionPopup = lazy(() => import('../../popups/EditGroupDescriptionPopup'));
const UploadOrGIFPopup = lazy(() => import('../../popups/UploadOrGIFPopup'));
const SearchGIFPopup = lazy(() => import('../../popups/SearchGIFPopup'));
const ChooseProIntervalPopup = lazy(() => import('../../popups/ChooseProIntervalPopup'));
const ChooseProTierPopup = lazy(() => import('../../popups/ChooseProTierPopup'));
const GiveProGiftPopup = lazy(() => import('../../popups/GiveProGiftPopup'));
const AddStickerPopup = lazy(() => import('../../popups/AddStickerPopup'));
const StickerDetailsPopup = lazy(() => import('../../popups/StickerDetailsPopup'));
const SentGiftInfoPopup = lazy(() => import('../../popups/SentGiftInfoPopup'));
const AcceptGiftPopup = lazy(() => import('../../popups/AcceptGiftPopup'));

const ProThemePreviewPopup = lazy(() => import('../../popups/ProThemePreviewPopup'));
const ProProfilePreviewPopup = lazy(() => import('../../popups/ProProfilePreviewPopup'));
const ProListPreviewPopup = lazy(() => import('../../popups/ProListPreviewPopup'));
const ProStickerPreviewPopup = lazy(() => import('../../popups/ProStickerPreviewPopup'));
const NewVersionPopup = lazy(() => import('../../popups/NewVersionPopup'));

const StreamingLinksPopup = lazy(() => import('../../popups/StreamingLinksPopup'));

const CreateTrackPopup = lazy(() => import('../../popups/CreateTrackPopup'));

const PopupContext = React.createContext();

const titles = {
    CreateReview: "Create Review",
    ListLikes: "Likes",
    Login: "Log In to Musicboard",
    SignUp: "Join Musicboard",
    BlockedUsers: "Blocked Users",
    ChangePassword: "Change Password",
    ChooseBanner: "Change Banner",
    ChooseProfilePicture: "Change Profile Picture",
    ChooseGroupPicture: "Change Group Image",
    ProfileInfo: "Who Are You?",
    ChooseImage: "Change Image",
    WriteComment: "Reply",
    EditComment: "Edit Comment",
    SearchContent: "Search Music",
    UpdateReview: "Edit Review",
    UpdateRating: "Edit Rating",
    DeleteComment: "Delete Comment",
    DeleteReview: "Delete Review",
    DeleteRating: "Delete Rating",
    DeleteList: "Delete List",
    DeleteBanner: "Delete Banner",
    DeleteGroup: "Leave Group",
    DeletePollOption: "Delete Vote",
    BlockUser: "Block User",
    DeleteAccount: "Permanently Delete Your Account",
    DeleteAccountConfirm: "Confirm Account Deletion",
    SubmitBanner: "Public Banner",
    ForgotPassword: "Request Password Reset",
    EditText: "Edit Description",
    PinContent: "Edit Pins",
    AddToList: "Add to List",
    SearchBanner: "Manage Banners",
    Biography: "Biography",
    ExtraInformation: "Contribute Music",
    Cookies: "Cookies",
    ReportContent: "Report",
    Help: "Help Center",
    Contribute: "Contribution Center",
    ReportDuplicate: "Report Duplicate",
    ContributorInfo: "Contributing to Musicboard",
    ContributorPro: "Contributing to Musicboard",
    BasicQuestion: "Question",
    MyContributions: "My Contributions",
    SearchArtistAndSuggested: "Select Artist",
    FavoriteGenres: "Favorite Genres",
    ManageDrafts: "My Drafts",
    DeleteMessage: "Delete Message",
    CreateGroup: "Send Message",
    AddGroupMember: "Add Group Member",
    EditGroupName: "Edit Group Name",
    EditGroupDescription: "Edit Group Description",
    SaveDraft: "Save Draft",
    DeleteSuggested: "Delete Suggestion",
    DuplicatedAlbums: "Duplicated Versions",
    DuplicatedTracks: "Duplicated Versions",
    ArtistDiscography: "Discography",
    UploadOrGIF: "Choose a Picture",
    SearchGIF: "Search GIFs",
    CropGIF: "Crop GIF",
    CropGIFBanner: "Crop GIF",
    ChooseProfileFrame: "Choose Profile Frame",
    ChooseProInterval: "Upgrade to Pro",
    ChooseProTier: "Upgrade to Pro",
    GiveProGift: "Send a Gift",
    ManageComments: "Manage Comments",
    ManageGroupPermissions: "Manage Permissions",
    GroupSettings: "Group Settings",
    GroupMembers: "Group Members",
    AddSticker: "Add Sticker",
    StickerDetails: "Sticker",
    ProThemePreview: "Preview",
    ProProfilePreview: "Preview",
    ProListPreview: "Preview",
    ProStickerPreview: "Preview",
    SentGiftInfo: "Gift",
    AcceptGift: "Gift",
    NewVersion: "New Version!",
    ShareReview: "Review Published",
    ShareList: "List Published",
    ShareRating: "Share Rating",
    ShareUser: "Share User",
    ShareAlbum: "Share Album",
    ShareTrack: "Share Track",
    ShareArtist: "Share Artist",
    ShareThread: "Share Thread",
    ShareSuggestedAlbum: "Share Contribution",
    StreamingLinks: "Streaming Services",
    CreateTrack: "Edit Track",
}

const smallWidth = {
    ChooseProInterval: true,
    ChooseProTier: true,
    ManageComments: true,
    ProThemePreview: true,
    ProProfilePreview: true,
    ProListPreview: true,
    ProStickerPreview: true,
}

const midWidth = {
    SignUp: true,
    Login: true,
}

const midLargeWidth = {
    ChooseProfileFrame: true,
}

const midLargeSecondWidth = {
    CreateTrack: true,
}

const largeWidth = {
    ChooseBanner: true,
    CropGIFBanner: true,
    SearchBanner: true,
    CreateReview: true,
    UpdateReview: true,
    UpdateRating: true,
    EditText: true,
    BuyPro: true,
}

const largePadding = {
    CreateReview: true,
    UpdateReview: true,
    UpdateRating: true,
    SignUp: true,
    Login: true,
    ChangePassword: true,
    ForgotPassword: true,
    EditText: true,
    BuyPro: true,
    ChangePaymentMethod: true,
    ReportDuplicate: true,
    ProThemePreview: true,
    ProProfilePreview: true,
    ProListPreview: true,
    ProStickerPreview: true,
    GiveProGift: true,
    AcceptGift: true,
    SentGiftInfo: true,
    CreateTrack: true,
}

const smallPadding = {
    BlockedUsers: true,
    ListLikes: true,
    Help: true,
    Contribute: true,
    MyContributions: true,
    DuplicatedAlbums: true,
    DuplicatedTracks: true,
    ArtistDiscography: true,
    GroupMembers: true,
}

const noPadding = {
    ChooseBanner: true,
    ChooseProfilePicture: true,
    ChooseProfileFrame: true,
    CropGIF: true,
    CropGIFBanner: true,
    ChooseGroupPicture: true,
    ProfileInfo: true,
    ChooseImage: true,
    ExtraInformation: true,
    Cookies: true,
    ContributorInfo: true,
    ContributorPro: true,
    BasicQuestion: true,
    PinContent: true,
    AddToList: true,
    ManageDrafts: true,
    FavoriteGenres: true,
    SearchArtistAndSuggested: true,
    CreateGroup: true,
    AddGroupMember: true,
    SendContent: true,
    AddSticker: true,
    NewVersion: true,
}

const customHeader = {
    PinContent: true,
    ManageDrafts: true,
    AddToList: true,
    ManageListContent: true,
    FavoriteGenres: true,
    ContributorPro: true,
    CreateGroup: true,
    AddGroupMember: true,
    SendContent: true,
    SignUp: true,
    Login: true,
    ChooseProfileFrame: true,
    AddSticker: true,
    NewVersion: true,
}

const requestClose = {
    CreateReview: true,
    UpdateReview: true,
    UpdateRating: true,
    EditText: true,
    PinContent: true,
    AddToList: true,
    ManageListContent: true,
    FavoriteGenres: true,
    ContributorPro: true,
    SaveDraft: true,
    SearchArtistAndSuggested: true,
    ChooseGroupPicture: true,
    EditGroupName: true,
    EditGroupDescription: true,
    ManageGroupPermissions: true,
    Biography: true,
    DeleteGroup: true,
    BlockUser: true,
    AddGroupMember: true,
    GroupMembers: true,
    NewVersion: true,
}

export const usePopup = () => {
    const { state, dispatch } = useContext(PopupContext)

    return {
        popupName: state.popupName,
        options: state.options,
        requestClose: state.requestClose,
        openPopup: (popupName, options) => {
            dispatch({
                type: "OPEN_POPUP",
                payload: {
                    popupName,
                    options
                }
            })
        }
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case "OPEN_POPUP":
            return {
                ...state,
                popupName: action.payload.popupName,
                options: action.payload.options
            }
        case "REQUEST_CLOSE":
            return {
                ...state,
                requestClose: action.payload,
            }
        default:
            throw new Error()
    }
}

const defaultPopup = {
    popupName: null,
    options: null,
    requestClose: null,
}
  
const PopupProvider = ({ init, children }) => {
    //Hooks
    const { setScrollDisabled } = useScrollbar()
    const [ state, dispatch ] = useReducer(reducer, defaultPopup);
    const location = useLocation()
    const history = useHistory()
    const track = useTrack()

    //Responsive
    const isTablet = useMediaQuery({ maxWidth: 768 })

    //Close the popup if the url changes
    useEffect(() => {
        dispatch({
            type: "OPEN_POPUP",
            payload: {
                popupName: null,
                options: null
            }
        })
    }, [ location ])

    //Scrolling
    useEffect(() => {
        if (state.popupName) {
            //Track openings of popups
            track("Opened popup", { popupName: state.popupName });

            document.body.style.overflow = "hidden"
            setScrollDisabled(true)
        } else {
            document.body.style.overflow = "initial"
            setScrollDisabled(false)
        }
    }, [ state.popupName ])

    //Refs
    const scrollRef = useRef()
    const popupRef = useRef()

    // Handle Esc key press to close popup
    useEffect(() => {
        const handleEscKey = (event) => {
            if (event.key === "Escape" && state.popupName) {
                const activeElement = document.activeElement;
                const isInputActive = activeElement && (activeElement.tagName === "INPUT" || activeElement.tagName === "TEXTAREA");

                if (requestClose[state.popupName]) {
                    dispatch({
                        type: "REQUEST_CLOSE",
                        payload: Math.random()
                    })
                } else {
                    dispatch({
                        type: "OPEN_POPUP",
                        payload: {
                            popupName: null,
                            options: null
                        }
                    })
                }
            }
        };

        document.addEventListener('keydown', handleEscKey);

        return () => {
            document.removeEventListener('keydown', handleEscKey);
        };
    }, [state.popupName]);

    return (
        <PopupContext.Provider value={{ state, dispatch }}>
            {children}

            {state.popupName &&
                <div
                className={styles.overlay} 
                ref={scrollRef}
                onClick={(e) => {
                    if (popupRef.current && popupRef.current.contains(e.target)) {
                        return;
                    }

                    if (requestClose[state.popupName]) {
                        dispatch({
                            type: "REQUEST_CLOSE",
                            payload: Math.random()
                        })
                    } else {
                        dispatch({
                            type: "OPEN_POPUP",
                            payload: {
                                popupName: null,
                                options: null
                            }
                        })
                    }
                }}
                >
                    
                    <div className={`${styles.content}`}>
                        <div 
                        ref={popupRef}
                        className={`${styles.popup} ${smallWidth[state.popupName] ? styles.smallWidth : ""} ${midWidth[state.popupName] ? styles.midWidth : ""} ${midLargeWidth[state.popupName] ? styles.midLargeWidth : ""} ${midLargeSecondWidth[state.popupName] ? styles.midLargeSecondWidth : ""} ${largeWidth[state.popupName] ? styles.largeWidth : ""}`}
                        >
                            {!customHeader[state.popupName] &&
                                <div className={`flex-row justify-between ${styles.header}`}>

                                    <div style={{ width: 38, height: 38 }} />

                                    <h5 className="black" style={{ fontWeight: 700 }}>
                                        {state.options && state.options.popupTitle ? state.options.popupTitle : titles[state.popupName]}
                                    </h5>
                                    
                                    <IconButton
                                    type="transparent-bright"
                                    icon="close"
                                    onClick={() => {
                                        if (requestClose[state.popupName]) {
                                            dispatch({
                                                type: "REQUEST_CLOSE",
                                                payload: Math.random()
                                            })
                                        } else {
                                            dispatch({
                                                type: "OPEN_POPUP",
                                                payload: {
                                                    popupName: null,
                                                    options: null
                                                }
                                            })
                                        }
                                    }}
                                    />

                                </div>
                            }
                            
                            <div
                            className={`${styles.innerContent} ${largePadding[state.popupName] ? styles.largePadding : ""} ${smallPadding[state.popupName] ? styles.smallPadding : ""} ${(noPadding[state.popupName] || customHeader[state.popupName]) ? styles.noPadding : ""}`}
                            >
                                <Suspense 
                                fallback={
                                    <div className='flex-center empty-text margin-top-16 margin-bottom-16'>
                                        <Icon icon="loading" />
                                    </div>
                                }
                                >
                                    {state.popupName === "CreateReview" &&
                                        <CreateReviewPopup scrollRef={scrollRef} options={state.options} requestClose={state.requestClose} />
                                    }
                                    {state.popupName === "SearchContent" &&
                                        <SearchContentPopup scrollRef={scrollRef} options={state.options} />
                                    }
                                    
                                    {state.popupName === "ListLikes" &&
                                        <ListLikesPopup scrollRef={scrollRef} options={state.options} />
                                    }
                                    
                                    {state.popupName === "Login" &&
                                        <LoginPopup scrollRef={scrollRef} options={state.options} />
                                    }
                                    
                                    {state.popupName === "SignUp" &&
                                        <SignUpPopup scrollRef={scrollRef} options={state.options} />
                                    }
                                
                                    {state.popupName === "BlockedUsers" &&
                                        <BlockedUsersPopup scrollRef={scrollRef} options={state.options} />
                                    }
                                    
                                    {state.popupName === "ChangePassword" &&
                                        <ChangePasswordPopup scrollRef={scrollRef} options={state.options} />
                                    }
                                    
                                    {state.popupName === "ChooseBanner" &&
                                        <ChooseBannerPopup scrollRef={scrollRef} options={state.options} />
                                    }
                                    
                                    {state.popupName === "ChooseProfilePicture" &&
                                        <ChooseProfilePicturePopup scrollRef={scrollRef} options={state.options} />
                                    }
                                    
                                    {state.popupName === "ProfileInfo" &&
                                        <ProfileInfoPopup scrollRef={scrollRef} options={state.options} />
                                    }
                                    
                                    {state.popupName === "ChooseImage" &&
                                        <ChooseImagePopup scrollRef={scrollRef} options={state.options} />
                                    }
                                    
                                    {state.popupName === "WriteComment" &&
                                        <WriteCommentPopup scrollRef={scrollRef} options={state.options} />
                                    }
                                    
                                    {state.popupName === "EditComment" &&
                                        <EditCommentPopup scrollRef={scrollRef} options={state.options} />
                                    }
                                    
                                    {state.popupName === "DeleteComment" &&
                                        <DeleteCommentPopup scrollRef={scrollRef} options={state.options} />
                                    }
                                    
                                    {state.popupName === "UpdateReview" &&
                                        <UpdateReviewPopup scrollRef={scrollRef} options={state.options} requestClose={state.requestClose} />
                                    }
                                    
                                    {state.popupName === "UpdateRating" &&
                                        <UpdateRatingPopup scrollRef={scrollRef} options={state.options} requestClose={state.requestClose} />
                                    }
                                    
                                    {state.popupName === "DeleteReview" &&
                                        <DeleteReviewPopup scrollRef={scrollRef} options={state.options} />
                                    }
                                    
                                    {state.popupName === "DeleteRating" &&
                                        <DeleteRatingPopup scrollRef={scrollRef} options={state.options} />
                                    }
                                    
                                    {state.popupName === "DeleteList" &&
                                        <DeleteListPopup scrollRef={scrollRef} options={state.options} />
                                    }
                                    
                                    {state.popupName === "DeleteGroup" &&
                                        <DeleteGroupPopup scrollRef={scrollRef} options={state.options} requestClose={state.requestClose} />
                                    }
                                    
                                    {state.popupName === "DeletePollOption" &&
                                        <DeletePollOptionPopup scrollRef={scrollRef} options={state.options} />
                                    }
                                    
                                    {state.popupName === "BlockUser" &&
                                        <BlockUserPopup scrollRef={scrollRef} options={state.options} requestClose={state.requestClose} />
                                    }
                                    
                                    {state.popupName === "ForgotPassword" &&
                                        <ForgotPasswordPopup scrollRef={scrollRef} options={state.options} />
                                    }
                                    
                                    {state.popupName === "EditText" &&
                                        <EditTextPopup scrollRef={scrollRef} options={state.options} requestClose={state.requestClose} />
                                    }
                                    
                                    {state.popupName === "DeleteAccount" &&
                                        <DeleteAccountPopup scrollRef={scrollRef} options={state.options} />
                                    }
                                    
                                    {state.popupName === "DeleteAccountConfirm" &&
                                        <DeleteAccountConfirmPopup scrollRef={scrollRef} options={state.options} />
                                    }
                                    
                                    {state.popupName === "PinContent" &&
                                        <PinContentPopup scrollRef={scrollRef} options={state.options} requestClose={state.requestClose} />
                                    }
                                    {state.popupName === "AddToList" &&
                                        <AddToListPopup scrollRef={scrollRef} options={state.options} requestClose={state.requestClose} />
                                    }
                                    {state.popupName === "ManageListContent" &&
                                        <ManageListContentPopup scrollRef={scrollRef} options={state.options} requestClose={state.requestClose} />
                                    }
                                    
                                    {state.popupName === "SearchBanner" &&
                                        <SearchBannerPopup scrollRef={scrollRef} options={state.options} />
                                    }
                                    
                                    {state.popupName === "DeleteBanner" &&
                                        <DeleteBannerPopup scrollRef={scrollRef} options={state.options} />
                                    }
                                    
                                    {state.popupName === "SubmitBanner" &&
                                        <SubmitBannerPopup scrollRef={scrollRef} options={state.options} />
                                    }
                                    
                                    {state.popupName === "Biography" &&
                                        <BiographyPopup scrollRef={scrollRef} options={state.options} requestClose={state.requestClose} />
                                    }

                                    {state.popupName === "ExtraInformation" &&
                                        <ExtraInformationPopup scrollRef={scrollRef} options={state.options} />
                                    }
                                    
                                    {state.popupName === "Cookies" &&
                                        <CookiesPopup scrollRef={scrollRef} options={state.options} />
                                    }
                                    
                                    {state.popupName === "ReportContent" &&
                                        <ReportContentPopup scrollRef={scrollRef} options={state.options} />
                                    }
                                    
                                    {state.popupName === "ReportDuplicate" &&
                                        <ReportDuplicatePopup scrollRef={scrollRef} options={state.options} />
                                    }

                                    {state.popupName === "Help" &&
                                        <HelpPopup scrollRef={scrollRef} options={state.options} />
                                    }

                                    {state.popupName === "Contribute" &&
                                        <ContributePopup scrollRef={scrollRef} options={state.options} />
                                    }

                                    {state.popupName === "ContributorInfo" &&
                                        <ContributorInfoPopup scrollRef={scrollRef} options={state.options} />
                                    }

                                    {state.popupName === "ContributorPro" &&
                                        <ContributorProPopup scrollRef={scrollRef} options={state.options} requestClose={state.requestClose} />
                                    }

                                    {state.popupName === "BasicQuestion" &&
                                        <BasicQuestionPopup scrollRef={scrollRef} options={state.options} />
                                    }

                                    {state.popupName === "MyContributions" &&
                                        <MyContributionsPopup scrollRef={scrollRef} options={state.options} />
                                    }

                                    {state.popupName === "SearchArtistAndSuggested" &&
                                        <SearchArtistAndSuggestedPopup scrollRef={scrollRef} options={state.options} requestClose={state.requestClose} />
                                    }

                                    {state.popupName === "FavoriteGenres" &&
                                        <FavoriteGenresPopup scrollRef={scrollRef} options={state.options} requestClose={state.requestClose} />
                                    }

                                    {state.popupName === "ManageDrafts" &&
                                        <ManageDraftsPopup scrollRef={scrollRef} options={state.options} />
                                    }

                                    {state.popupName === "DeleteMessage" &&
                                        <DeleteMessagePopup scrollRef={scrollRef} options={state.options} />
                                    }

                                    {state.popupName === "CreateGroup" &&
                                        <CreateGroupPopup scrollRef={scrollRef} options={state.options} />
                                    }

                                    {state.popupName === "AddGroupMember" &&
                                        <AddGroupMemberPopup scrollRef={scrollRef} options={state.options} requestClose={state.requestClose} />
                                    }

                                    {state.popupName === "SendContent" &&
                                        <SendContentPopup scrollRef={scrollRef} options={state.options} />
                                    }

                                    {state.popupName === "ChooseGroupPicture" &&
                                        <ChooseGroupPicturePopup scrollRef={scrollRef} options={state.options} requestClose={state.requestClose} />
                                    }

                                    {state.popupName === "EditGroupName" &&
                                        <EditGroupNamePopup scrollRef={scrollRef} options={state.options} requestClose={state.requestClose} />
                                    }

                                    {state.popupName === "EditGroupDescription" &&
                                        <EditGroupDescriptionPopup scrollRef={scrollRef} options={state.options} requestClose={state.requestClose} />
                                    }

                                    {state.popupName === "SaveDraft" &&
                                        <SaveDraftPopup scrollRef={scrollRef} options={state.options} requestClose={state.requestClose} />
                                    }

                                    {state.popupName === "DeleteSuggested" &&
                                        <DeleteSuggestedPopup scrollRef={scrollRef} options={state.options} />
                                    }

                                    {state.popupName === "DuplicatedAlbums" &&
                                        <DuplicatedAlbumsPopup scrollRef={scrollRef} options={state.options} />
                                    }

                                    {state.popupName === "DuplicatedTracks" &&
                                        <DuplicatedTracksPopup scrollRef={scrollRef} options={state.options} />
                                    }

                                    {state.popupName === "ArtistDiscography" &&
                                        <ArtistDiscographyPopup scrollRef={scrollRef} options={state.options} />
                                    }

                                    {state.popupName === "UploadOrGIF" &&
                                        <UploadOrGIFPopup scrollRef={scrollRef} options={state.options} />
                                    }

                                    {state.popupName === "SearchGIF" &&
                                        <SearchGIFPopup scrollRef={scrollRef} options={state.options} />
                                    }

                                    {state.popupName === "CropGIF" &&
                                        <CropGIFPopup scrollRef={scrollRef} options={state.options} />
                                    }

                                    {state.popupName === "CropGIFBanner" &&
                                        <CropGIFBannerPopup scrollRef={scrollRef} options={state.options} />
                                    }

                                    {state.popupName === "ChooseProfileFrame" &&
                                        <ChooseProfileFramePopup scrollRef={scrollRef} options={state.options} />
                                    }

                                    {state.popupName === "ChooseProInterval" &&
                                        <ChooseProIntervalPopup scrollRef={scrollRef} options={state.options} />
                                    }

                                    {state.popupName === "ChooseProTier" &&
                                        <ChooseProTierPopup scrollRef={scrollRef} options={state.options} />
                                    }

                                    {state.popupName === "GiveProGift" &&
                                        <GiveProGiftPopup scrollRef={scrollRef} options={state.options} />
                                    }

                                    {state.popupName === "ManageComments" &&
                                        <ManageCommentsPopup scrollRef={scrollRef} options={state.options} />
                                    }

                                    {state.popupName === "GroupSettings" &&
                                        <GroupSettingsPopup scrollRef={scrollRef} options={state.options} />
                                    }

                                    {state.popupName === "GroupMembers" &&
                                        <GroupMembersPopup scrollRef={scrollRef} options={state.options} requestClose={state.requestClose} />
                                    }

                                    {state.popupName === "ManageGroupPermissions" &&
                                        <ManageGroupPermissionsPopup scrollRef={scrollRef} options={state.options} requestClose={state.requestClose} />
                                    }

                                    {state.popupName === "AddSticker" &&
                                        <AddStickerPopup scrollRef={scrollRef} options={state.options} />
                                    }

                                    {state.popupName === "StickerDetails" &&
                                        <StickerDetailsPopup scrollRef={scrollRef} options={state.options} />
                                    }

                                    {state.popupName === "ProThemePreview" &&
                                        <ProThemePreviewPopup scrollRef={scrollRef} options={state.options} />
                                    }

                                    {state.popupName === "ProProfilePreview" &&
                                        <ProProfilePreviewPopup scrollRef={scrollRef} options={state.options} />
                                    }

                                    {state.popupName === "ProListPreview" &&
                                        <ProListPreviewPopup scrollRef={scrollRef} options={state.options} />
                                    }

                                    {state.popupName === "ProStickerPreview" &&
                                        <ProStickerPreviewPopup scrollRef={scrollRef} options={state.options} />
                                    }

                                    {state.popupName === "SentGiftInfo" &&
                                        <SentGiftInfoPopup scrollRef={scrollRef} options={state.options} />
                                    }

                                    {state.popupName === "AcceptGift" &&
                                        <AcceptGiftPopup scrollRef={scrollRef} options={state.options} />
                                    }

                                    {state.popupName === "NewVersion" &&
                                        <NewVersionPopup scrollRef={scrollRef} options={state.options} requestClose={state.requestClose} />
                                    }

                                    {state.popupName === "ShareReview" &&
                                        <ShareReviewPopup scrollRef={scrollRef} options={state.options} />
                                    }

                                    {state.popupName === "ShareThread" &&
                                        <ShareThreadPopup scrollRef={scrollRef} options={state.options} />
                                    }

                                    {state.popupName === "ShareList" &&
                                        <ShareListPopup scrollRef={scrollRef} options={state.options} />
                                    }

                                    {state.popupName === "ShareRating" &&
                                        <ShareRatingPopup scrollRef={scrollRef} options={state.options} />
                                    }

                                    {state.popupName === "ShareUser" &&
                                        <ShareUserPopup scrollRef={scrollRef} options={state.options} />
                                    }

                                    {state.popupName === "ShareAlbum" &&
                                        <ShareAlbumPopup scrollRef={scrollRef} options={state.options} />
                                    }

                                    {state.popupName === "ShareTrack" &&
                                        <ShareTrackPopup scrollRef={scrollRef} options={state.options} />
                                    }

                                    {state.popupName === "ShareArtist" &&
                                        <ShareArtistPopup scrollRef={scrollRef} options={state.options} />
                                    }

                                    {state.popupName === "ShareSuggestedAlbum" &&
                                        <ShareSuggestedAlbumPopup scrollRef={scrollRef} options={state.options} />
                                    }

                                    {state.popupName === "StreamingLinks" &&
                                        <StreamingLinksPopup scrollRef={scrollRef} options={state.options} />
                                    }

                                    {state.popupName === "CreateTrack" &&
                                        <CreateTrackPopup scrollRef={scrollRef} options={state.options} />
                                    }
                                </Suspense>
                            </div>

                            {(state.popupName === "Login" || state.popupName === "SignUp") && !isTablet &&
                                <div
                                className={styles.help}
                                onClick={() => {
                                    dispatch({
                                        type: "OPEN_POPUP",
                                        payload: {
                                            popupName: "Help",
                                            options: null
                                        }
                                    })
                                }}
                                >
                                    <Icon icon="help" size={20} />
                                    <h6 className="margin-top-4 black">Help</h6>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
            
        </PopupContext.Provider>
    )
}
export default PopupProvider;