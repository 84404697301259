import { types, status } from '../constants/action-types';
import { errorToString } from '../utils/error'
import { v4 } from 'uuid'
import moment from 'moment';

const defaultMe = {
	localUid: v4(),
	localId: Math.round(Math.random() * 1000000000),

	searchType: "album",
	theme: "dark",
	tempTheme: null,
	isDarkMode: true,
	showFilters: false,
	homeReviewsIsFriends: false,
	homeListsIsFriends: false,
	homeAlbumsIsPopular: true,

	volume: 1,

	ratingsCount: null,
	lastFetchedRatingsCount: null,

	lastMobilePopup: null,

	musicHistory: [],
    listsHistory: [],
    usersHistory: [],

	acceptedCookies: false,
	bottomAdVisible: false,

	hasNewActivity: false,
	lastActivity: null,
	groups: [],

	storeLocalAlbum: null,
	localSuggestedAlbum: null,
	storeLocalArtist: null,
	localSuggestedArtist: null,

	storeResubmitData: null,

	albumUrlSlugResult: {},
	albumDeezerIdResult: {},
	albumIdResult: {},

	artistUrlSlugResult: {},
	artistDeezerIdResult: {},
	artistIdResult: {},

	trackUrlSlugResult: {},
	trackDeezerIdResult: {},
	trackIdResult: {},

	listUrlSlugResult: {},
	reviewUrlSlugResult: {},
	threadUrlSlugResult: {},
};

const me = (state = defaultMe, action) => {
	const reset = action.meta && action.meta.params ? action.meta.params.reset : false
	const groupUid  = action.meta && action.meta.params ? action.meta.params.groupUid : false
	const myUid  = action.meta && action.meta.params ? action.meta.params.myUid : false

	switch (action.type) {
		case types.LOG_OUT:
			return {
				...state,
				hasNewActivity: false,
				lastActivity: null,
				groups: [],
				theme: (state.theme === "dark" || state.theme === "light") ? state.theme : "dark",
				localUid: state.localUid,
				localId: state.localId,
			}
			
        //Search Type
		case types.SET_SEARCH_TYPE:
			return {
				...state,
				searchType: action.payload
            }
            
        // Dark Mode
		case types.SET_THEME:
			return {
				...state,
				theme: action.payload
			}

		case types.SET_TEMP_THEME:
			return {
				...state,
				tempTheme: action.payload
			}
		
		//Filters
		case types.SET_SHOW_FILTERS:
			return {
				...state,
				showFilters: action.payload ? action.payload : false
			}
		
		//Home screen
		case types.SET_HOME_REVIEWS_IS_FRIENDS:
			return {
				...state,
				homeReviewsIsFriends: action.payload ? action.payload : false
			}
		case types.SET_HOME_LISTS_IS_FRIENDS:
			return {
				...state,
				homeListsIsFriends: action.payload ? action.payload : false
			}
		case types.SET_HOME_ALBUMS_IS_POPULAR:
			return {
				...state,
				homeAlbumsIsPopular: action.payload ? action.payload : false
			}
		
		case types.GET_RATINGS_COUNT:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
					}
				case status.SUCCESS:
					return {
						...state,
						ratingsCount: action.payload.count,
						lastFetchedRatingsCount: new Date()
					}
				case status.ERROR:
					return {
						...state,
					}
			}

		//Mobile popup tracking
		case types.SET_LAST_MOBILE_POPUP:
			return {
				...state,
				lastMobilePopup: new Date()
			}

		/* HISTORY */
        case types.ADD_TO_MUSIC_HISTORY:
            return {
                ...state,
                musicHistory: state.musicHistory ? [ action.payload, ...state.musicHistory.filter((content) => content.id !== action.payload.id).filter((_, i) => i < 24) ] : [ action.payload ]
            }
        case types.REMOVE_FROM_MUSIC_HISTORY:
            return {
                ...state,
                musicHistory: state.musicHistory.filter((content) => content.id !== action.payload.id)
            }

		case types.ADD_TO_USERS_HISTORY:
            return {
                ...state,
                usersHistory: state.usersHistory ? [ action.payload, ...state.usersHistory.filter((user) => user.uid !== action.payload.uid).filter((_, i) => i < 24) ] : [ action.payload ]
            }
        case types.REMOVE_FROM_USERS_HISTORY:
            return {
                ...state,
                usersHistory: state.usersHistory.filter((user) => user.uid !== action.payload)
            }

        case types.ADD_TO_LISTS_HISTORY:
            return {
                ...state,
                listsHistory: state.listsHistory ? [ action.payload, ...state.listsHistory.filter((list) => list.uid !== action.payload.uid).filter((_, i) => i < 12) ] : [ action.payload ]
            }
        case types.REMOVE_FROM_LISTS_HISTORY:
            return {
                ...state,
                listsHistory: state.listsHistory.filter((list) => list.uid !== action.payload)
            }
        case types.CLEAR_LISTS_HISTORY:
            return {
                ...state,
                listsHistory: []
            }

		//Cookies
		case types.SET_ACCEPTED_COOKIES:
			return {
				...state,
				acceptedCookies: true
			}

		//Ad
		case types.SET_BOTTOM_AD_VISIBLE:
			return {
				...state,
				bottomAdVisible: action.payload
			}
		
		// Groups
		case types.GET_GROUPS_READ_STATUS:
			switch (action.meta.status) {
				case status.BEGIN:
					return state
				case status.SUCCESS:
					return {
						...state,
						groups: reset ? 
							action.payload.results.map((group) => {
								const oldGroup = state.groups.find(item => item.uid === group.uid);
								
								return ({
									uid: group.uid,
									latest_message: group.latest_message,
									hasNewMessages: oldGroup ? (
										oldGroup.hasNewMessages ? true : (oldGroup.latest_message && group.latest_message && oldGroup.latest_message.uid !== group.latest_message.uid && group.latest_message.creator !== myUid)
									) : false
								})
							})
						: state.groups,
					}
				case status.ERROR:
					return state
			}
		
		case types.CLEAR_NEW_MESSAGES:
			return {
				...state,
				groups: state.groups.map((group) => {
					if (group.uid !== groupUid) return group;

					return ({
						...group,
						hasNewMessages: false
					})
				})
			}

		case types.GET_ACTIVITY:
			switch (action.meta.status) {
				case status.BEGIN:
					return state
				case status.SUCCESS:
					return {
						...state,
						hasNewActivity: (
							reset && 
							state.lastActivity && 
							action.payload.results[0] && 
							action.payload.results[0].uid !== state.lastActivity.uid
						) ? true : state.hasNewActivity,
						lastActivity: action.payload.results[0]
					}
				case status.ERROR:
					return state
			}

		case types.CLEAR_NEW_ACTIVITY:
			return {
				...state,
				hasNewActivity: false,
			}
		
		case types.CREATE_MESSAGE:
			switch (action.meta.status) {
				case status.BEGIN:
					return state
				case status.SUCCESS:
					return {
						...state,
						groups: state.groups.map((group) => {
							if (group.uid !== groupUid) return group;
	
							return ({
								...group,
								hasNewMessages: false,
								latest_message: action.payload
							})
						})
					}
				case status.ERROR:
					return state
			}
		
		case types.STORE_LOCAL_ALBUM:
			return {
				...state,
				storeLocalAlbum: action.payload
			}
		
		case types.SAVE_LOCAL_SUGGESTED_ALBUM:
			return {
				...state,
				localSuggestedAlbum: action.payload
			}

		case types.STORE_LOCAL_ARTIST:
			return {
				...state,
				storeLocalArtist: action.payload
			}

		case types.SAVE_LOCAL_SUGGESTED_ARTIST:
			return {
				...state,
				localSuggestedArtist: action.payload
			}

		case types.STORE_RESUBMIT_DATA:
			return {
				...state,
				storeResubmitData: action.payload
			}

		case types.SET_INIT_ALBUM:
			return {
				...state,
				albumUrlSlugResult: { 
					...state.albumUrlSlugResult,
					[action.payload.url_slug]: action.payload.id,
				},
				albumDeezerIdResult: { 
					...state.albumDeezerIdResult, 
					[action.payload.deezer_id]: action.payload.id,
				},
				albumIdResult: { 
					...state.albumIdResult, 
					[action.payload.id]: action.payload.id,
				},
			}

		case types.SET_INIT_ARTIST:
			return {
				...state,
				artistUrlSlugResult: { 
					...state.artistUrlSlugResult,
					[action.payload.url_slug]: action.payload.id,
				},
				artistDeezerIdResult: { 
					...state.artistDeezerIdResult, 
					[action.payload.deezer_id]: action.payload.id,
				},
				artistIdResult: { 
					...state.artistIdResult, 
					[action.payload.id]: action.payload.id,
				},
			}
		
		case types.SET_INIT_TRACK:
			return {
				...state,
				trackUrlSlugResult: { 
					...state.trackUrlSlugResult,
					[action.payload.url_slug]: action.payload.id,
				},
				trackDeezerIdResult: { 
					...state.trackDeezerIdResult, 
					[action.payload.deezer_id]: action.payload.id,
				},
				trackIdResult: { 
					...state.trackIdResult, 
					[action.payload.id]: action.payload.id,
				},
			}
		
		case types.SET_INIT_LIST:
			return {
				...state,
				listUrlSlugResult: { 
					...state.listUrlSlugResult,
					[action.payload.url_slug]: action.payload.uid,
				},
			}
		
		case types.SET_INIT_REVIEW:
			return {
				...state,
				reviewUrlSlugResult: { 
					...state.reviewUrlSlugResult,
					[action.payload.url_slug]: action.payload.uid,
				},
			}
		
		case types.SET_INIT_THREAD:
			return {
				...state,
				threadUrlSlugResult: { 
					...state.threadUrlSlugResult,
					[action.payload.url_slug]: action.payload.uid,
				},
			}
			
		case types.FETCH_ALBUM:
			switch (action.meta.status) {
				case status.BEGIN:
					return state
				case status.SUCCESS:
					return {
						...state,
						albumUrlSlugResult: { 
							...state.albumUrlSlugResult,
							...action.meta.params.urlSlug ? { [action.meta.params.urlSlug]: action.payload.id } : {}, 
							[action.payload.url_slug]: action.payload.id,
						},
						albumDeezerIdResult: { 
							...state.albumDeezerIdResult, 
							...action.meta.params.id && action.meta.params.fromDeezer ? { [action.meta.params.id]: action.payload.id } : {},
							[action.payload.deezer_id]: action.payload.id,
						},
						albumIdResult: { 
							...state.albumIdResult, 
							...action.meta.params.id && !action.meta.params.fromDeezer ? { [action.meta.params.id]: action.payload.id } : {},
							[action.payload.id]: action.payload.id,
						},
					}
				case status.ERROR:
					return state
			}

		case types.FETCH_ARTIST:
			switch (action.meta.status) {
				case status.BEGIN:
					return state
				case status.SUCCESS:
					return {
						...state,
						artistUrlSlugResult: { 
							...state.artistUrlSlugResult,
							...action.meta.params.urlSlug ? { [action.meta.params.urlSlug]: action.payload.id } : {}, 
							[action.payload.url_slug]: action.payload.id,
						},
						artistDeezerIdResult: { 
							...state.artistDeezerIdResult, 
							...action.meta.params.id && action.meta.params.fromDeezer ? { [action.meta.params.id]: action.payload.id } : {},
							[action.payload.deezer_id]: action.payload.id,
						},
						artistIdResult: { 
							...state.artistIdResult, 
							...action.meta.params.id && !action.meta.params.fromDeezer ? { [action.meta.params.id]: action.payload.id } : {},
							[action.payload.id]: action.payload.id,
						},
					}
				case status.ERROR:
					return state
			}
		
		case types.FETCH_TRACK:
			switch (action.meta.status) {
				case status.BEGIN:
					return state
				case status.SUCCESS:
					return {
						...state,
						trackUrlSlugResult: { 
							...state.trackUrlSlugResult,
							...action.meta.params.urlSlug ? { [action.meta.params.urlSlug]: action.payload.id } : {}, 
							[action.payload.url_slug]: action.payload.id,
						},
						trackDeezerIdResult: { 
							...state.trackDeezerIdResult, 
							...action.meta.params.id && action.meta.params.fromDeezer ? { [action.meta.params.id]: action.payload.id } : {},
							[action.payload.deezer_id]: action.payload.id,
						},
						trackIdResult: { 
							...state.trackIdResult, 
							...action.meta.params.id && !action.meta.params.fromDeezer ? { [action.meta.params.id]: action.payload.id } : {},
							[action.payload.id]: action.payload.id,
						},
					}
				case status.ERROR:
					return state
			}
		
		case types.FETCH_LIST:
			switch (action.meta.status) {
				case status.BEGIN:
					return state
				case status.SUCCESS:
					return {
						...state,
						listUrlSlugResult: { 
							...state.listUrlSlugResult,
							...action.meta.params.urlSlug ? { [action.meta.params.urlSlug]: action.payload.uid } : {}, 
							[action.payload.url_slug]: action.payload.uid,
						},
					}
				case status.ERROR:
					return state
			}
		
		case types.FETCH_REVIEW:
			switch (action.meta.status) {
				case status.BEGIN:
					return state
				case status.SUCCESS:
					return {
						...state,
						reviewUrlSlugResult: { 
							...state.reviewUrlSlugResult,
							...action.meta.params.urlSlug ? { [action.meta.params.urlSlug]: action.payload.uid } : {}, 
							[action.payload.url_slug]: action.payload.uid,
						},
					}
				case status.ERROR:
					return state
			}
		
		case types.FETCH_THREAD:
			switch (action.meta.status) {
				case status.BEGIN:
					return state
				case status.SUCCESS:
					return {
						...state,
						threadUrlSlugResult: { 
							...state.threadUrlSlugResult,
							...action.meta.params.urlSlug ? { [action.meta.params.urlSlug]: action.payload.uid } : {}, 
							[action.payload.url_slug]: action.payload.uid,
						},
					}
				case status.ERROR:
					return state
			}

		case types.SET_VOLUME:
			return {
				...state,
				volume: action.payload
			}
		
		case types.INITIAL_LOAD:
			return {
				...state,
				
				localUid: state.localUid || v4(),
				localId: state.localId || Math.round(Math.random() * 1000000000),
				
				volume: (state.volume === null || state.volume === undefined) ? 1 : state.volume,
				albumUrlSlugResult: !state.albumUrlSlugResult ? {} : state.albumUrlSlugResult,
				albumDeezerIdResult: !state.albumDeezerIdResult ? {} : state.albumDeezerIdResult,
				albumIdResult: !state.albumIdResult ? {} : state.albumIdResult,
				artistUrlSlugResult: !state.artistUrlSlugResult ? {} : state.artistUrlSlugResult,
				artistDeezerIdResult: !state.artistDeezerIdResult ? {} : state.artistDeezerIdResult,
				artistIdResult: !state.artistIdResult ? {} : state.artistIdResult,
				trackUrlSlugResult: !state.trackUrlSlugResult ? {} : state.trackUrlSlugResult,
				trackDeezerIdResult: !state.trackDeezerIdResult ? {} : state.trackDeezerIdResult,
				trackIdResult: !state.trackIdResult ? {} : state.trackIdResult,
				listUrlSlugResult: !state.listUrlSlugResult ? {} : state.listUrlSlugResult,
				reviewUrlSlugResult: !state.reviewUrlSlugResult ? {} : state.reviewUrlSlugResult,
				threadUrlSlugResult: !state.threadUrlSlugResult ? {} : state.threadUrlSlugResult,
			}

		default:
			return state;
	}
};

export default me;