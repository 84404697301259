import styles from './artistinformationsection.module.css'

import moment from 'moment'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { useTheme } from '../../../../hooks/theme'

import OneLineLoader from '../../../../loaders/OneLineLoader'

import { addEndingS } from '../../../../utils/format-text'
import { firstLetterCapital } from '../../../../utils/captialization'

import Icon from '../../../../components/Icon'
import Button from '../../../../components/Button'

export default function ArtistInformationSection({ artist }) {
    //General
    const { mode } = useTheme()

    const alsoKnownAs = useMemo(() => 
        [...artist.also_known_as, ...artist.also_known_as_reverse]
        .sort((a, b) => {
            if (a.also_known_as && !b.also_known_as) return -1;
            if (!a.also_known_as && b.also_known_as) return 1;
            if (a.artist && !b.artist) return -1;
            if (!a.artist && b.artist) return 1;
            return a.order - b.order;
        })
        .filter(i => i),
    [artist.also_known_as?.length, artist.also_known_as_reverse?.length])

    const memberOf = useMemo(() => 
        [...artist.member_of]
        .sort((a, b) => {
            if (a.member_of && !b.member_of) return -1;
            if (!a.member_of && b.member_of) return 1;
            return a.order - b.order;
        })
        .filter(i => i),
    [artist.member_of?.length])

    const bandMembers = useMemo(() => 
        [...artist.band_members]
        .sort((a, b) => {
            if (a.artist && !b.artist) return -1;
            if (!a.artist && b.artist) return 1;
            return a.order - b.order;
        })
        .filter(i => i),
    [artist.band_members?.length])

    const unknownData = [
        !artist.birth_year && "birth date", 
        !Boolean(artist.country) && "location of origin", 
        !Boolean(artist.website) && "website", 
        !Boolean(artist.twitter || artist.instagram || artist.facebook) && "social media handles"
    ].filter(i => i)

    const hasAnyData = (
        artist.birth_year ||
        artist.passing_year ||
        Boolean(artist.is_group && artist.amount_of_members && artist.amount_of_members > 0) ||
        Boolean(artist.country) ||
        alsoKnownAs?.length > 0 ||
        memberOf?.length > 0 ||
        bandMembers?.length > 0 ||
        Boolean(artist.website) ||
        Boolean(artist.twitter || artist.instagram || artist.facebook)
    ) || !artist.metadata_fetched;

    const socials = [
        Boolean(artist.twitter) && {
            label: "X",
            link: (artist.twitter.includes("http://") || artist.twitter.includes("https://")) ? artist.twitter.replace("twitter.com", "x.com") : `https://${artist.twitter.replace("twitter.com", "x.com")}`
        }, 
        Boolean(artist.instagram) && {
            label: "Instagram",
            link: (artist.instagram.includes("http://") || artist.instagram.includes("https://")) ? artist.instagram : `https://${artist.instagram}`
        }, 
        Boolean(artist.facebook) && {
            label: "Facebook",
            link: (artist.facebook.includes("http://") || artist.facebook.includes("https://")) ? artist.facebook : `https://${artist.facebook}`
        },
    ].filter(i => i)

    return (
        <div style={{ borderBottomLeftRadius: 8, borderBottomRightRadius: 8, overflow: 'hidden' }}>
            {hasAnyData &&
                <div>
                    {/* Born */}
                    <div className={`padding-around ${styles.container}`}>
                        <div className="grid">
                            <h5 className="black one-line" style={{ fontSize: 15 }}>{artist.is_group ? "Formed" : "Born"}</h5>
                        </div>
                        
                        {(artist.metadata_fetched || artist.birth_year) &&
                            <p className="margin-top-4 highDarkGrey" style={{ fontSize: 15 }}>
                                {artist.birth_year ? 
                                    moment(artist.birth_year).format(artist.birth_year_precision === "year" ? "YYYY" : artist.birth_year_precision === "month" ? "MMMM YYYY" : "MMMM DD, YYYY")
                                :
                                    <Link 
                                    className={styles.link}
                                    to={`/artist/${artist.id}/edit`}
                                    >
                                        {`When was this artist ${artist.is_group ? "formed" : "born"}?`}
                                    </Link>
                                }
                            </p>
                        }

                        {!artist.metadata_fetched && !artist.birth_year &&
                            <OneLineLoader width={60} height={20} y={7} className="margin-top-4" style={{ display: 'block' }} />
                        }
                    </div>
                    
                    {artist.passing_year && !artist.is_active && // Passed Away
                        <div className={`padding-around ${styles.container}`}>
                            <div className="grid">
                                <h5 className="black one-line" style={{ fontSize: 15 }}>{artist.is_group ? "Disbanded" : "Passed Away"}</h5>
                            </div>
                            
                            <p className="margin-top-4 highDarkGrey" style={{ fontSize: 15 }}>
                                {moment(artist.passing_year).format(artist.passing_year_precision === "year" ? "YYYY" : artist.passing_year_precision === "month" ? "MMMM YYYY" : "MMMM DD, YYYY")}
                            </p>
                        </div>
                    }

                    {artist.is_group && Boolean(artist.amount_of_members) &&  // Members
                        <div className={`padding-around ${styles.container}`}>
                            <div className="grid">
                                <h5 className="black one-line" style={{ fontSize: 15 }}>Members</h5>
                            </div>
                            
                            <p className="margin-top-4 highDarkGrey" style={{ fontSize: 15 }}>
                                {`${artist.amount_of_members} ${artist.amount_of_members === 1 ? "member" : `members`}`}
                            </p>
                        </div>
                    }
                    
                    {alsoKnownAs && alsoKnownAs.length > 0 &&
                        <div className={`padding-around ${styles.container}`}>
                            <div className="grid">
                                <h5 className="black one-line" style={{ fontSize: 15 }}>Also Known As</h5>
                            </div>
                            
                            <p className="margin-top-4 highDarkGrey" style={{ fontSize: 15 }}>
                                {alsoKnownAs.map((obj, index) => (
                                    <span key={`ArtistInformationSection-alsoKnownAs-${obj.uid}-${index}`}>
                                        {obj.placeholder_name && obj.placeholder_name !== "" &&
                                            obj.placeholder_name
                                        }

                                        {(obj.also_known_as || obj.artist) &&
                                            <Link 
                                            className={styles.link}
                                            to={obj.also_known_as?.url_slug || obj.artist?.url_slug}
                                            >
                                                {obj.also_known_as?.name || obj.artist?.name}
                                            </Link>
                                        }

                                        {index !== alsoKnownAs.length-1 && ", "}
                                    </span>
                                ))}
                            </p>
                        </div>
                    }

                    {memberOf && memberOf.length > 0 &&
                        <div className={`padding-around ${styles.container}`}>
                            <div className="grid">
                                <h5 className="black one-line" style={{ fontSize: 15 }}>Member of</h5>
                            </div>
                            
                            <p className="margin-top-4 highDarkGrey" style={{ fontSize: 15 }}>
                                {memberOf.map((obj, index) => (
                                    <span key={`ArtistInformationSection-memberOf-${obj.uid}-${index}`}>
                                        {obj.placeholder_name && obj.placeholder_name !== "" &&
                                            obj.placeholder_name
                                        }

                                        {obj.member_of &&
                                            <Link 
                                            className={styles.link}
                                            to={obj.member_of.url_slug}
                                            >
                                                {obj.member_of.name}
                                            </Link>
                                        }

                                        {!obj.is_active && " (former member)"}

                                        {index !== memberOf.length-1 && ", "}
                                    </span>
                                ))}
                            </p>
                        </div>
                    }
                    
                    {bandMembers && bandMembers.length > 0 &&
                        <div className={`padding-around ${styles.container}`}>
                            <div className="grid">
                                <h5 className="black one-line" style={{ fontSize: 15 }}>Band Members</h5>
                            </div>
                            
                            <p className="margin-top-4 highDarkGrey" style={{ fontSize: 15 }}>
                                {bandMembers.map((obj, index) => (
                                    <span key={`ArtistInformationSection-bandMembers-${obj.uid}-${index}`}>
                                        {obj.placeholder_name && obj.placeholder_name !== "" &&
                                            obj.placeholder_name
                                        }

                                        {obj.artist &&
                                            <Link 
                                            className={styles.link}
                                            to={obj.artist.url_slug}
                                            >
                                                {obj.artist.name}
                                          
                                            </Link>
                                        }

                                        {!obj.is_active && " (former member)"}
                                        
                                        {index !== bandMembers.length-1 && ", "}
                                    </span>
                                ))}
                            </p>
                        </div>
                    }

                    {/* Label 
                    <div className={`padding-around ${styles.container}`}>
                        <div className="grid">
                            <h5 className="black one-line" style={{ fontSize: 15 }}>Label</h5>
                        </div>
                        
                        {(artist.metadata_fetched || artist.label) &&
                            <p className="margin-top-4 highDarkGrey" style={{ fontSize: 15 }}>
                                {Boolean(artist.label) ? 
                                    artist.label 
                                : 
                                    <Link 
                                    className={styles.link}
                                    to={`/artist/${artist.id}/edit`}
                                    >
                                        {`Contribute by adding their label...`}
                                    </Link>
                                }
                            </p>
                        }

                        {!artist.metadata_fetched && !artist.label &&
                            <OneLineLoader width={170} height={20} y={7} className="margin-top-4" style={{ display: 'block' }} />
                        }
                    </div>
                    */}

                    {/* Origin */}
                    <div className={`padding-around ${styles.container}`}>
                        <div className="grid">
                            <h5 className="black one-line" style={{ fontSize: 15 }}>Origin</h5>
                        </div>
                        
                        {(artist.metadata_fetched || artist.country) &&
                            <p className="margin-top-4 highDarkGrey" style={{ fontSize: 15 }}>
                                {Boolean(artist.country) ? 
                                    artist.country
                                : 
                                    <Link 
                                    className={styles.link}
                                    to={`/artist/${artist.id}/edit`}
                                    >
                                        {"Contribute by adding their origin..."}
                                    </Link>
                                }
                            </p>
                        }

                        {!artist.metadata_fetched && !artist.country &&
                            <OneLineLoader width={140} height={20} y={7} className="margin-top-4" style={{ display: 'block' }} />
                        }
                    </div>

                    {/* Website */}
                    <div className={`padding-around ${styles.container}`}>
                        <div className="grid">
                            <h5 className="black one-line" style={{ fontSize: 15 }}>Website</h5>
                        </div>
                        
                        {(artist.metadata_fetched || artist.website) &&
                            <div className="grid">
                                <p className="margin-top-4 blue one-line" style={{ fontSize: 15 }}>
                                    {Boolean(artist.website) ?
                                        <a 
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={(artist.website.includes("http://") || artist.website.includes("https://")) ? artist.website : `http://${artist.website}`}
                                        className={styles.link}
                                        >
                                            {(artist.website.includes("http://") || artist.website.includes("https://")) ? artist.website : `http://${artist.website}`}
                                        </a>
                                    :  
                                        <Link 
                                        className={styles.link}
                                        to={`/artist/${artist.id}/edit`}
                                        >
                                            {"Do they have an official website?"}
                                        </Link>
                                    }
                                </p>
                            </div>
                        }

                        {!artist.metadata_fetched && !artist.website &&
                            <OneLineLoader width={140} height={20} y={7} className="margin-top-4" style={{ display: 'block' }} />
                        }
                    </div>
                    
                    {/* Socials */}
                    <div className={`padding-around ${styles.container}`}>
                        <div className="grid">
                            <h5 className="black one-line" style={{ fontSize: 15 }}>Socials</h5>
                        </div>
                        
                        {(artist.metadata_fetched || artist.twitter || artist.instagram || artist.facebook) &&
                            <div className="grid">
                                <p className="margin-top-4 highDarkGrey one-line" style={{ fontSize: 15 }}>
                                    {Boolean(artist.twitter || artist.instagram || artist.facebook) ?
                                        socials.map((social, index) => (
                                            <span>
                                                <a 
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={social.link}
                                                className={styles.link}
                                                >
                                                    {social.label}
                                                </a>
                                                {index !== socials.length-1 && ", "}
                                            </span>
                                        ))
                                    : 
                                        <Link 
                                        className={styles.link}
                                        to={`/artist/${artist.id}/edit`}
                                        >
                                            {"Add links to their socials..."}
                                        </Link>
                                    }
                                </p>
                            </div>
                        }

                        {!artist.metadata_fetched && !artist.twitter && !artist.instagram && !artist.facebook &&
                            <OneLineLoader width={230} height={20} y={7} className="margin-top-4" style={{ display: 'block' }} />
                        }
                    </div>
                </div>
            }
            
            {!hasAnyData &&
                <div className={`${styles.container} padding-left-32 padding-right-32 padding-top-24 padding-bottom-32 flex-column flex-center`}>
                    <Icon 
                    color={"transparent"}
                    icon={mode === "light" ? "auto-fix-high" : "person-insight-dark"} 
                    size={96} 
                    style={{ marginLeft: mode === "light" ? 0 : 2 }}  
                    />

                    <p className="highDarkGrey text-center margin-top-24">
                        {addEndingS(artist.name)} details have not been {unknownData.length === 6 ? "filled out" : "completed"} yet. Be the first to contribute to this artist's page with information.
                    </p>
                    
                    <p className="highDarkGrey text-center margin-top-16">
                        Examples: {firstLetterCapital(unknownData.join(", "))}.
                    </p>

                    <Link to={`/artist/${artist.id}/edit`}>
                        <Button
                        className="margin-top-24"
                        type="secondary"
                        text="Contribute"
                        height={40}
                        textStyle={{ fontSize: 14 }}
                        />
                    </Link>
                </div>
            }

        </div>
    )
}
