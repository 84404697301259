import { types, status } from '../constants/action-types';
import { errorToString } from '../utils/error'
import { calculateUpdatedRating, calculateNewRating } from '../utils/calculate-ratings';
import moment from 'moment';
import { checkNull, checkNullNumber } from '../utils/format-text';
import { handleNested, handleReducer } from '../utils/reducer-helpers';

const defaultArtists = {
	artists: {},
	lastFetchedArtistId: null,
	lastFetchedArtistIsRefetch: false,
	fetchArtistStatus: status.DEFAULT,

	allArtists: {
		data: [],
		count: null,
		next: null,
		status: status.DEFAULT,
	},

	lastCreatedArtistId: null,
	lastCreatedArtist: null,
    createArtistStatus: status.DEFAULT,

	checkArtistDatabaseResult: {
		status: status.DEFAULT,
		result: null,
		urlSlug: null,
		deezerId: null,
		id: null,
	},

	errorMessage: null,
};

const artists = (state = defaultArtists, action) => {
	let id = action.meta && action.meta.params ? action.meta.params.id : null
	let reset = action.meta && action.meta.params ? action.meta.params.reset : false

	switch (action.type) {
		case types.SET_INIT_ARTIST:
			return {
				...state,
				artists: {
					...state.artists,
					[action.payload.id]: {
						...state.artists[action.payload.id],
						...action.payload,
						rating_distribution: state.artists[action.payload.id] ? state.artists[action.payload.id].rating_distribution : [],
					}
				},
				lastFetchedArtistId: action.payload.id,
				lastFetchedArtistIsRefetch: false,
				fetchArtistStatus: status.SUCCESS,
			}

		//Get a specific artist
		case types.FETCH_ARTIST:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						fetchArtistStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						artists: {
							...state.artists,
							[action.payload.id]: {
								...state.artists[action.payload.id],
								...action.payload,
								rating_distribution: state.artists[action.payload.id] ? state.artists[action.payload.id].rating_distribution : [],
							}
						},
						lastFetchedArtistId: action.payload.id,
						lastFetchedArtistIsRefetch: action.meta.params.isRefetch,
						fetchArtistStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						fetchArtistStatus: status.ERROR,
					}
			}

		case types.CHECK_ARTIST_DATABASE_STATUS:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						checkArtistDatabaseResult: {
							...state.checkArtistDatabaseResult,
							status: status.BEGIN,
							urlSlug: action.meta.params.urlSlug,
							deezerId: action.meta.params.deezerId,
							id: action.meta.params.id,
						}
					}
				case status.SUCCESS:
					return {
						...state,
						checkArtistDatabaseResult: {
							...state.checkArtistDatabaseResult,
							status: status.SUCCESS,
							result: action.payload,
						}
					}
				case status.ERROR:
					return {
						...state,
						checkArtistDatabaseResult: {
							...state.checkArtistDatabaseResult,
							status: status.ERROR,
						},
						errorMessage: errorToString(action.payload),
					}
			}
		
		//Get or create a artist
		case types.CREATE_ARTIST:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						createArtistStatus: status.BEGIN,
						lastCreatedArtistId: id,
					}
				case status.SUCCESS:
					return {
						...state,
						artists: {
							...state.artists,
							[id]: {
								...state.artists[id],
								...action.payload,
								average_rating: action.payload.average_rating ? action.payload.average_rating : state.artists[id] ? state.artists[id].average_rating : null,
							}
						},
						lastCreatedArtist: action.payload,
						createArtistStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						createArtistStatus: status.ERROR,
					}
			}

		case types.CREATE_RATING:
			switch (action.meta.status) {
				case status.BEGIN:
					return state
				case status.SUCCESS:
					const cacheRating = action.meta.params && action.meta.params.cacheRating;
					const contentType = action.payload.content.type;
					const contentId = action.payload.content.id;
					if (cacheRating && contentType === "artist" && state.artists[contentId]) {
						return {
							...state,
							artists: {
								...state.artists,
								[contentId]: {
									...state.artists[contentId],
									ratings_count: checkNullNumber(state.artists[contentId].ratings_count) + 1,
									average_rating: calculateNewRating(
										state.artists[contentId].average_rating,
										state.artists[contentId].ratings_count,
										action.payload.rating
									),
									cacheTime: moment(),

									myRatings: 
										(state.artists[contentId] && state.artists[contentId].myRatings) ? 
											{
												...state.artists[contentId].myRatings,
												data: [
													action.payload,
													...state.artists[contentId].myRatings.data
												]
											} 
										: 
											null
								}
							},
						}
					}
					return state
				case status.ERROR:
					return state
			}
		
		case types.UPDATE_RATING:
			switch (action.meta.status) {
				case status.BEGIN:
					return state
				case status.SUCCESS:
					const cacheRating = action.meta.params && action.meta.params.cacheRating;
					const oldRating = action.meta.params && action.meta.params.oldRating;
					const contentId = action.payload.content.id;
					if (cacheRating && state.artists[contentId]) {
						return {
							...state,
							artists: {
								...state.artists,
								[contentId]: {
									...state.artists[contentId],
									average_rating: calculateUpdatedRating(
										state.artists[contentId].average_rating,
										state.artists[contentId].ratings_count,
										oldRating,
										action.payload.rating
									),
									cacheTime: moment(),

									myRatings: 
										(state.artists[contentId] && state.artists[contentId].myRatings) ? 
											{
												...state.artists[contentId].myRatings,
												data: state.artists[contentId].myRatings.data && state.artists[contentId].myRatings.data.map((rating) => {
													if (rating.uid === action.payload.uid) {
														return action.payload
													}

													return rating;
												})
											} 
										: 
											null
								}
							},
						}
					}
					return state
				case status.ERROR:
					return state
			}
		
		//Get artists rating distribution
		case types.GET_ARTIST_RATING_DISTRIBUTION:
			switch (action.meta.status) {
				case status.BEGIN:
					return state
				case status.SUCCESS:
					return {
						...state,
						artists: {
							...state.artists,
							[id]: {
								...state.artists[id],
								rating_distribution: action.payload,
							}
						}
					}
				case status.ERROR:
					return state
			}

		//Get artist latest contributions
		case types.GET_ARTIST_LATEST_CONTRIBUTIONS:
			switch (action.meta.status) {
				case status.BEGIN:
					return state
				case status.SUCCESS:
					return {
						...state,
						artists: {
							...state.artists,
							[id]: {
								...state.artists[id],
								latest_contributions: action.payload,
							}
						}
					}
				case status.ERROR:
					return state
			}

		case types.GET_ARTIST_SUGGESTED_CHANGES:
			return handleNested(state, action, 'suggestedChanges', 'artists', id)

		//Get any artists reviews and update the artists object
		case types.GET_ARTISTS_REVIEWS:
			return handleNested(state, action, 'reviews', 'artists', id)

		case types.GET_ARTISTS_HOME_REVIEWS:
			return handleNested(state, action, 'homeReviews', 'artists', id)

		case types.GET_ARTISTS_CONTENT_REVIEWS:
			return handleNested(state, action, 'contentReviews', 'artists', id)

		//Get any artists lists and update the artists object
		case types.GET_ARTISTS_LISTS:
			return handleNested(state, action, 'lists', 'artists', id)
			
		case types.GET_ARTISTS_HOME_LISTS:
			return handleNested(state, action, 'homeLists', 'artists', id)
			
		//Get my ratings for an artist and update the artists object
		case types.GET_MY_ARTIST_RATINGS:
			return handleNested(state, action, 'myRatings', 'artists', id)

		//Get my friends ratings for an artist and update the artists object
		case types.GET_ARTISTS_RATINGS:
			return handleNested(state, action, 'ratings', 'artists', id)

		//Get an artist's discography
		case types.GET_ARTIST_DISCOGRAPHY:
			return handleNested(state, action, 'discography', 'artists', id)
			
		//Get an artist's top tracks
		case types.GET_ARTIST_TOP_TRACKS:
			return handleNested(state, action, 'topTracks', 'artists', id)

		case types.GET_ALL_ARTISTS:
			return handleReducer(state, action, "allArtists")
		
		default:
			return state;
	}
};

export default artists;