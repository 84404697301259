import { types, status } from '../constants/action-types';
import { errorToString } from '../utils/error'
import { calculateUpdatedRating, calculateNewRating } from '../utils/calculate-ratings';
import moment from 'moment';
import { checkNull, checkNullNumber } from '../utils/format-text';
import { handleReducer } from '../utils/reducer-helpers';

const defaultContributions = {
	createAlbumSuggestionStatus: status.DEFAULT,
	lastCreatedSuggestedAlbumChange: null,

	createArtistSuggestionStatus: status.DEFAULT,
	lastCreatedSuggestedArtistChange: null,

	reportDuplicatedContentStatus: status.DEFAULT,
    lastDuplicatedContentReport: null,

	createSuggestedAlbumStatus: status.DEFAULT,
	lastCreatedSuggestedAlbum: null,
	
	createSuggestedArtistStatus: status.DEFAULT,
	lastCreatedSuggestedArtist: null,

	mySuggestedArtists: {
		data: [],
		count: null,
		next: null,
		status: status.DEFAULT,
	},
	suggestedChanges: {
		data: [],
		count: null,
		next: null,
		status: status.DEFAULT,
	},
	
	deleteSuggestionStatus: status.DEFAULT,
	lastDeletedSuggestionType: null,
	lastDeletedSuggestionUid: null,

	approveSuggestionStatus: status.DEFAULT,
	lastApprovedSuggestionId: null,
	lastApprovedSuggestionUid: null,
	lastApprovedSuggestionType: null,

	setApprovedStatusSuggestionStatus: status.DEFAULT,
	lastSetApprovedStatusSuggestionId: null,
	lastSetApprovedStatusSuggestionUid: null,
	lastSetApprovedStatusSuggestionType: null,
	
	declineSuggestionStatus: status.DEFAULT,
	lastDeclinedSuggestionId: null,
	lastDeclinedSuggestionUid: null,
	lastDeclinedSuggestionType: null,
	
	suggestedAlbums: {},
	suggestedArtists: {},
	suggestedAlbumChanges: {},
	suggestedArtistChanges: {},
	
	fetchSuggestedAlbumStatus: status.DEFAULT,
	lastFetchedSuggestedAlbum: null,
	
	fetchSuggestedArtistStatus: status.DEFAULT,
	lastFetchedSuggestedArtist: null,
	
	fetchSuggestedAlbumChangeStatus: status.DEFAULT,
	lastFetchedSuggestedAlbumChange: null,
	
	fetchSuggestedArtistChangeStatus: status.DEFAULT,
	lastFetchedSuggestedArtistChange: null,
	
	publicSuggestedChanges: {
		data: [],
		count: null,
		next: null,
		status: status.DEFAULT,
	},

	errorMessage: null,
};

const contributions = (state = defaultContributions, action) => {
	let id = action.meta && action.meta.params ? action.meta.params.id : null
	let reset = action.meta && action.meta.params ? action.meta.params.reset : false

	let pluralType = 
		action.meta?.params?.contentType === "suggested_album" ? 
			"suggestedAlbums" 
		: action.meta?.params?.contentType === "suggested_artist" ? 
			"suggestedArtists" 
		: action.meta?.params?.contentType === "suggested_album_change" ? 
			"suggestedAlbumChanges" 
		: action.meta?.params?.contentType === "suggested_artist_change" ? 
			"suggestedArtistChanges"
		:
			null
			
	switch (action.type) {
		//Suggest Changes
		case types.CREATE_ALBUM_SUGGESTION:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						createAlbumSuggestionStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						createAlbumSuggestionStatus: status.SUCCESS,
						lastCreatedSuggestedAlbumChange: action.payload,
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						createAlbumSuggestionStatus: status.ERROR,
						lastCreatedSuggestedAlbumChange: null,
					}
			}
		
		case types.CREATE_ARTIST_SUGGESTION:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						createArtistSuggestionStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						createArtistSuggestionStatus: status.SUCCESS,
						lastCreatedSuggestedArtistChange: action.payload,
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						createArtistSuggestionStatus: status.ERROR,
						lastCreatedSuggestedArtistChange: null,
					}
			}
		
		//Report Duplicated Content
		case types.REPORT_DUPLICATED_CONTENT:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						reportDuplicatedContentStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						reportDuplicatedContentStatus: status.SUCCESS,
                        lastDuplicatedContentReport: action.payload
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						reportDuplicatedContentStatus: status.ERROR,
                        lastDuplicatedContentReport: null
					}
			}
		
		//Suggested Content
		case types.CREATE_SUGGESTED_ALBUM:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						createSuggestedAlbumStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						createSuggestedAlbumStatus: status.SUCCESS,
                        lastCreatedSuggestedAlbum: action.payload
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						createSuggestedAlbumStatus: status.ERROR,
                        lastCreatedSuggestedAlbum: null
					}
			}
        
		case types.CREATE_SUGGESTED_ARTIST:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						createSuggestedArtistStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						createSuggestedArtistStatus: status.SUCCESS,
                        lastCreatedSuggestedArtist: action.payload
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						createSuggestedArtistStatus: status.ERROR,
                        lastCreatedSuggestedArtist: null
					}
			}
        
		//Fetch Suggested Album
		case types.FETCH_SUGGESTED_ALBUM:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
                        ...state,
                        fetchSuggestedAlbumStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
                        ...state,
                        suggestedAlbums: {
							...state.suggestedAlbums,
							[action.payload.id]: action.payload
						},
						fetchSuggestedAlbumStatus: status.SUCCESS,
						lastFetchedSuggestedAlbum: action.payload,
						errorMessage: null,
					}
				case status.ERROR:
					return {
                        ...state,
                        fetchSuggestedAlbumStatus: status.ERROR,
						lastFetchedSuggestedAlbum: null,
                        errorMessage: errorToString(action.payload),
					}
			}
		
		//Fetch Suggested Artist
		case types.FETCH_SUGGESTED_ARTIST:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
                        ...state,
                        fetchSuggestedArtistStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
                        ...state,
                        suggestedArtists: {
							...state.suggestedArtists,
							[action.payload.id]: action.payload
						},
						fetchSuggestedArtistStatus: status.SUCCESS,
						lastFetchedSuggestedArtist: action.payload,
						errorMessage: null,
					}
				case status.ERROR:
					return {
                        ...state,
                        fetchSuggestedArtistStatus: status.ERROR,
						lastFetchedSuggestedArtist: null,
                        errorMessage: errorToString(action.payload),
					}
			}
		
		//Fetch Suggested Album Change
		case types.FETCH_SUGGESTED_ALBUM_CHANGE:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
                        ...state,
                        fetchSuggestedAlbumChangeStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
                        ...state,
                        suggestedAlbumChanges: {
							...state.suggestedAlbumChanges,
							[action.payload.uid]: action.payload
						},
						fetchSuggestedAlbumChangeStatus: status.SUCCESS,
						lastFetchedSuggestedAlbumChange: action.payload,
						errorMessage: null,
					}
				case status.ERROR:
					return {
                        ...state,
                        fetchSuggestedAlbumChangeStatus: status.ERROR,
						lastFetchedSuggestedAlbumChange: null,
                        errorMessage: errorToString(action.payload),
					}
			}

		//Fetch Suggested Artist Change
		case types.FETCH_SUGGESTED_ARTIST_CHANGE:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
                        ...state,
                        fetchSuggestedArtistChangeStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
                        ...state,
                        suggestedArtistChanges: {
							...state.suggestedArtistChanges,
							[action.payload.uid]: action.payload
						},
						fetchSuggestedArtistChangeStatus: status.SUCCESS,
						lastFetchedSuggestedArtistChange: action.payload,
						errorMessage: null,
					}
				case status.ERROR:
					return {
                        ...state,
                        fetchSuggestedArtistChangeStatus: status.ERROR,
						lastFetchedSuggestedArtistChange: null,
                        errorMessage: errorToString(action.payload),
					}
			}

		//Like contributions
		case types.LIKE_CONTENT:
			if (action.meta.params.contentType !== "suggested_album" 
				&& action.meta.params.contentType !== "suggested_artist" 
				&& action.meta.params.contentType !== "suggested_album_change" 
				&& action.meta.params.contentType !== "suggested_artist_change") return state
			if (!action.meta.params.detailed) return state

			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						[pluralType]: {
							...state[pluralType],
							[action.meta.params.suggestedContentId]: {
								...state[pluralType][action.meta.params.suggestedContentId],
								like_count: state[pluralType][action.meta.params.suggestedContentId].like_count + 1
							}
						},
					}
				case status.SUCCESS:
                    return state
				case status.ERROR:
					return {
						...state,
						[pluralType]: {
							...state[pluralType],
							[action.meta.params.suggestedContentId]: {
								...state[pluralType][action.meta.params.suggestedContentId],
								like_count: state[pluralType][action.meta.params.suggestedContentId].like_count - 1
							}
						},
					}
			}

		case types.DELETE_LIKE:
			if (action.meta.params.contentType !== "suggested_album" 
				&& action.meta.params.contentType !== "suggested_artist" 
				&& action.meta.params.contentType !== "suggested_album_change" 
				&& action.meta.params.contentType !== "suggested_artist_change") return state
			if (!action.meta.params.detailed) return state

			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						[pluralType]: {
							...state[pluralType],
							[action.meta.params.suggestedContentId]: {
								...state[pluralType][action.meta.params.suggestedContentId],
								like_count: state[pluralType][action.meta.params.suggestedContentId].like_count - 1
							}
						},
					}
				case status.SUCCESS:
                    return state
				case status.ERROR:
					return {
						...state,
						[pluralType]: {
							...state[pluralType],
							[action.meta.params.suggestedContentId]: {
								...state[pluralType][action.meta.params.suggestedContentId],
								like_count: state[pluralType][action.meta.params.suggestedContentId].like_count + 1
							}
						},
					}
			}

		//Get Suggested Artists
		case types.GET_MY_SUGGESTED_ARTISTS:
			return handleReducer(state, action, 'mySuggestedArtists')
        
		//Get Suggested Changes
		case types.GET_MY_SUGGESTED_CHANGES:
			return handleReducer(state, action, 'suggestedChanges')
        
		//Get Suggested Changes
		case types.GET_PUBLIC_SUGGESTED_CHANGES:
			return handleReducer(state, action, 'publicSuggestedChanges')

		//Delete Suggestion
		case types.DELETE_SUGGESTION:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						deleteSuggestionStatus: status.BEGIN,
						lastDeletedSuggestionType: action.meta.params.type,
						lastDeletedSuggestionUid: action.meta.params.suggestedContentId,
					}
				case status.SUCCESS:
					return {
						...state,
						deleteSuggestionStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						deleteSuggestionStatus: status.ERROR,
						lastDeletedSuggestionType: null,
						lastDeletedSuggestionUid: null,
					}
			}
		
		
		case types.APPROVE_SUGGESTION:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						approveSuggestionStatus: status.BEGIN,
						lastApprovedSuggestionId: action.meta.params.id,
						lastApprovedSuggestionUid: action.meta.params.uid,
						lastApprovedSuggestionType: action.meta.params.type,
					}
				case status.SUCCESS:
					return {
						...state,
						approveSuggestionStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						approveSuggestionStatus: status.ERROR,
						lastApprovedSuggestionId: null,
						lastApprovedSuggestionUid: null,
						lastApprovedSuggestionType: null,
					}
			}
		
		case types.SET_APPROVED_STATUS_SUGGESTION:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						setApprovedStatusSuggestionStatus: status.BEGIN,
						lastSetApprovedStatusSuggestionId: action.meta.params.id,
						lastSetApprovedStatusSuggestionUid: action.meta.params.uid,
						lastSetApprovedStatusSuggestionType: action.meta.params.type,
					}
				case status.SUCCESS:
					return {
						...state,
						setApprovedStatusSuggestionStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						setApprovedStatusSuggestionStatus: status.ERROR,
						lastSetApprovedStatusSuggestionId: null,
						lastSetApprovedStatusSuggestionUid: null,
						lastSetApprovedStatusSuggestionType: null,
					}
			}

		case types.DECLINE_SUGGESTION:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						declineSuggestionStatus: status.BEGIN,
						lastDeclinedSuggestionId: action.meta.params.id,
						lastDeclinedSuggestionUid: action.meta.params.uid,
						lastDeclinedSuggestionType: action.meta.params.type,
					}
				case status.SUCCESS:
					return {
						...state,
						declineSuggestionStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						declineSuggestionStatus: status.ERROR,
						lastDeclinedSuggestionId: null,
						lastDeclinedSuggestionUid: null,
						lastDeclinedSuggestionType: null,
					}
			}

		default:
			return state;
	}
};

export default contributions;