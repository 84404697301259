import { types, status } from '../constants/action-types';
import { errorToString } from '../utils/error'
import { calculateNewRating, calculateUpdatedRating } from '../utils/calculate-ratings';
import moment from 'moment';
import { checkNull, checkNullNumber } from '../utils/format-text';
import { handleNested, handleReducer } from '../utils/reducer-helpers'

const defaultAlbums = {
	albums: {},
	lastFetchedAlbumId: null,
	lastFetchedAlbumIsRefetch: false,
	fetchAlbumStatus: status.DEFAULT,


	allAlbums: {
		data: [],
		count: null,
		next: null,
		status: status.DEFAULT,
	},
	popularAlbums: {
		data: [],
		count: null,
		next: null,
		status: status.DEFAULT,
	},

	lastCreatedAlbumId: null,
	lastCreatedAlbum: null,
    createAlbumStatus: status.DEFAULT,

	checkAlbumDatabaseResult: {
		status: status.DEFAULT,
		result: null,
		urlSlug: null,
		deezerId: null,
		id: null,
	},

	errorMessage: null,
};

const albums = (state = defaultAlbums, action) => {
	let id = action.meta && action.meta.params ? action.meta.params.id : null
	let reset = action.meta && action.meta.params ? action.meta.params.reset : false

	switch (action.type) {
		case types.SET_INIT_ALBUM:
			return {
				...state,
				albums: {
					...state.albums,
					[action.payload.id]: {
						...state.albums[action.payload.id],
						...action.payload,
						rating_distribution: state.albums[action.payload.id] ? state.albums[action.payload.id].rating_distribution : [],
					}
				},
				lastFetchedAlbumId: action.payload.id,
				lastFetchedAlbumIsRefetch: false,
				fetchAlbumStatus: status.SUCCESS,
			}

		case types.FETCH_ALBUM:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						fetchAlbumStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						albums: {
							...state.albums,
							[action.payload.id]: {
								...state.albums[action.payload.id],
								...action.payload,
								rating_distribution: state.albums[action.payload.id] ? state.albums[action.payload.id].rating_distribution : [],
							}
						},
						lastFetchedAlbumId: action.payload.id,
						lastFetchedAlbumIsRefetch: action.meta.params.isRefetch,
						fetchAlbumStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						fetchAlbumStatus: status.ERROR,
					}
			}

		case types.CHECK_ALBUM_DATABASE_STATUS:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						checkAlbumDatabaseResult: {
							...state.checkAlbumDatabaseResult,
							status: status.BEGIN,
							urlSlug: action.meta.params.urlSlug,
							deezerId: action.meta.params.deezerId,
							id: action.meta.params.id,
						}
					}
				case status.SUCCESS:
					return {
						...state,
						checkAlbumDatabaseResult: {
							...state.checkAlbumDatabaseResult,
							status: status.SUCCESS,
							result: action.payload,
						}
					}
				case status.ERROR:
					return {
						...state,
						checkAlbumDatabaseResult: {
							...state.checkAlbumDatabaseResult,
							status: status.ERROR,
						},
						errorMessage: errorToString(action.payload),
					}
			}

		//Get or create a album
		case types.CREATE_ALBUM:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						createAlbumStatus: status.BEGIN,
						lastCreatedAlbumId: id,
					}
				case status.SUCCESS:
					return {
						...state,
						albums: {
							...state.albums,
							[id]: {
								...state.albums[id],
								...action.payload,
								average_rating: action.payload.average_rating ? action.payload.average_rating : state.albums[id] ? state.albums[id].average_rating : null,
							}
						},
						lastCreatedAlbum: action.payload,
						createAlbumStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						createAlbumStatus: status.ERROR,
					}
			}

		case types.CREATE_RATING:
			switch (action.meta.status) {
				case status.BEGIN:
					return state
				case status.SUCCESS:
					const cacheRating = action.meta.params && action.meta.params.cacheRating;
					const contentType = action.payload.content.type;
					const contentId = action.payload.content.id;
					if (cacheRating && contentType === "album" && state.albums[contentId]) {
						return {
							...state,
							albums: {
								...state.albums,
								[contentId]: {
									...state.albums[contentId],
									ratings_count: checkNullNumber(state.albums[contentId].ratings_count) + 1,
									average_rating: calculateNewRating(
										state.albums[contentId].average_rating,
										state.albums[contentId].ratings_count,
										action.payload.rating
									),
									cacheTime: moment(),

									myRatings: 
										(state.albums[contentId] && state.albums[contentId].myRatings) ? 
											{
												...state.albums[contentId].myRatings,
												data: [
													action.payload,
													...state.albums[contentId].myRatings.data
												]
											} 
										: 
											null
								}
							},
						}
					}
					return state
				case status.ERROR:
					return state
			}
		
		case types.UPDATE_RATING:
			switch (action.meta.status) {
				case status.BEGIN:
					return state
				case status.SUCCESS:
					const cacheRating = action.meta.params && action.meta.params.cacheRating;
					const oldRating = action.meta.params && action.meta.params.oldRating;
					const contentId = action.payload.content.id;
					if (cacheRating && state.albums[contentId]) {
						return {
							...state,
							albums: {
								...state.albums,
								[contentId]: {
									...state.albums[contentId],
									average_rating: calculateUpdatedRating(
										state.albums[contentId].average_rating,
										state.albums[contentId].ratings_count,
										oldRating,
										action.payload.rating
									),
									cacheTime: moment(),

									myRatings: 
										(state.albums[contentId] && state.albums[contentId].myRatings) ? 
											{
												...state.albums[contentId].myRatings,
												data: state.albums[contentId].myRatings.data && state.albums[contentId].myRatings.data.map((rating) => {
													if (rating.uid === action.payload.uid) {
														return action.payload
													}

													return rating;
												})
											} 
										: 
											null
								}
							},
						}
					}
					return state
				case status.ERROR:
					return state
			}
		
		//Get album rating distribution
		case types.GET_ALBUM_RATING_DISTRIBUTION:
			switch (action.meta.status) {
				case status.BEGIN:
					return state
				case status.SUCCESS:
					return {
						...state,
						albums: {
							...state.albums,
							[id]: {
								...state.albums[id],
								rating_distribution: action.payload,
							}
						}
					}
				case status.ERROR:
					return state
			}

		//Get album latest contributions
		case types.GET_ALBUM_LATEST_CONTRIBUTIONS:
			switch (action.meta.status) {
				case status.BEGIN:
					return state
				case status.SUCCESS:
					return {
						...state,
						albums: {
							...state.albums,
							[id]: {
								...state.albums[id],
								latest_contributions: action.payload,
							}
						}
					}
				case status.ERROR:
					return state
			}
		
		//Get album duplicates
		case types.GET_ALBUM_BONUS_TRACKS:
			switch (action.meta.status) {
				case status.BEGIN:
					return state
				case status.SUCCESS:
					return {
						...state,
						albums: {
							...state.albums,
							[id]: {
								...state.albums[id],
								bonus_tracks: action.payload,
							}
						}
					}
				case status.ERROR:
					return state
			}
		
		case types.GET_ALBUM_SUGGESTED_CHANGES:
			return handleNested(state, action, 'suggestedChanges', 'albums', id)

		//Get any albums reviews and update the albums object
		case types.GET_ALBUMS_REVIEWS:
			return handleNested(state, action, 'reviews', 'albums', id)
		
		case types.GET_ALBUMS_HOME_REVIEWS:
			return handleNested(state, action, 'homeReviews', 'albums', id)
		
		case types.GET_ALBUMS_CONTENT_REVIEWS:
			return handleNested(state, action, 'contentReviews', 'albums', id)
			
		//Get any albums lists and update the albums object
		case types.GET_ALBUMS_LISTS:
			return handleNested(state, action, 'lists', 'albums', id)

		case types.GET_ALBUMS_HOME_LISTS:
			return handleNested(state, action, 'homeLists', 'albums', id)

		//Get my ratings for an album and update the albums object
		case types.GET_MY_ALBUM_RATINGS:
			return handleNested(state, action, 'myRatings', 'albums', id)

		//Get my friends ratings for an album and update the albums object
		case types.GET_ALBUMS_RATINGS:
			return handleNested(state, action, 'ratings', 'albums', id)

		case types.GET_ALL_ALBUMS:
			return handleReducer(state, action, 'allAlbums', id)

		case types.GET_POPULAR_ALBUMS:
			return handleReducer(state, action, 'popularAlbums', id)
		
		default:
			return state;
	}
};

export default albums;