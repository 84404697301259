import styles from './trackitem.module.css'

import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

import { oneDecimal, secondsToFormattedDuration } from '../../utils/format-text'
import { checkDeezerId } from '../../utils/deezer-utils'

import { usePopup } from '../../hooks/popup/popup'

import OneLineLoader from '../../loaders/OneLineLoader'
import RoundLoader from '../../loaders/RoundLoader'

import Icon from '../Icon'
import Tooltip from '../Tooltip';
import PlayTracksButton from '../PlayTracksButton'
import ContentOptionsTooltip from '../ContentOptionsTooltip'

function TrackItem({
    track,
    album,
    artist,
    number,
    highlight,
    playTracks,
    hasFeatures
}) {
    //General
    const { openPopup } = usePopup()
    const dispatch = useDispatch()

    //Responsive
    const isMobile = useMediaQuery({ maxWidth: 630 })

    //Auth
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn)

    //Render
    return (
        <div
        className={`${styles.container} ${highlight ? styles.highlight : ""} link-wrapper content-options-wrapper`}
        >
            <Link 
            to={checkDeezerId(track) ? `/track/${track.id}?dz` : track.url_slug} 
            className="link-overlay" 
            >
                <span className='link-overlay-title'>{track.title}</span>
            </Link>

            <div className="col-1 flex-row link-inner">
                <div className={styles.itemContainer}>
                    <p className="grey">{number}</p>
                </div>

                <div className={`grid col-8 margin-right-64`}>
                    <p className="col-1 one-line bold black" style={{ lineHeight: '20px' }}>
                        {track.title}
                    </p>

                    <div className="grid col-4 margin-top-4" style={{ marginBottom: 2 }}>
                        <p className="col-1 one-line highDarkGrey">
                            {track.track_artists
                            .map(({ artist }, index) => (
                                <span key={`TrackItem-${track.id}-ArtistList-${artist.id}-${index}`}>
                                    <Link to={`/artist/${artist.id}`} className={styles.artistLink}>
                                        {artist.name}
                                    </Link>
        
                                    {index < track.track_artists.length - 1 && ", "}
                                </span>
                            ))}
                        </p>
                    </div>
                </div>

                {!isMobile &&
                    <div className={`grid col-1`}>
                        {(track.metadata_fetched || track.duration) &&
                            <p className={`col-1 one-line highDarkGrey ${!track.duration ? styles.hidden : ""}`}>{secondsToFormattedDuration(track.duration)}</p>
                        }
                        
                        {!track.metadata_fetched && !track.duration &&
                            <OneLineLoader width={38} height={16.5} y={5.25} />
                        }
                    </div>
                }

                {!isMobile &&
                    <div className="link-clickable margin-right-8 margin-left-8">
                        <ContentOptionsTooltip content={{ ...track, album, artist }} appendId={`trackitem-`} absoluteDesign={false} />
                    </div>
                }

                {track.preview && playTracks &&
                    <PlayTracksButton 
                    playTracks={playTracks}
                    trackId={track.id}
                    />
                }

                {!(track.preview && playTracks) && 
                    <div style={{ width: 38 }} className="flex-center">
                        {!track.metadata_fetched &&
                            <RoundLoader size={19} />
                        }
                    </div>
                }

                <div 
                onClick={() => {
                    if (!isLoggedIn) {
                        openPopup("SignUp")
                        return;
                    }

                    openPopup("CreateReview", { content: { ...track, album, artist }, cacheRating: false })
                }} 
                id={`track-${track.id}-stars`} 
                className={`flex-row cursor margin-left-16 margin-right-16 ${!isMobile && "link-clickable"}`}
                >
                    <Icon icon="star" size={16} color={(track && track.average_rating) ? "var(--yellow)" : "var(--highDarkGrey)"} className={"margin-right-8"} />

                    <div className={styles.ratingContainer}>
                        <p className="black">{(track && track.average_rating) ? oneDecimal(track.average_rating/2) : 0} <span className="grey">/ 5</span></p>
                    </div>
                </div>

                <Tooltip id={`track-${track.id}-stars`} text="Rate this track" offset={-6} />
            </div>
        </div>
    )
}

export default React.memo(TrackItem)