import uuid from 'uuid'
import React, { Component, useState, useEffect, useRef } from 'react'
import { useTheme } from '../../hooks/theme';
import { Link } from 'react-router-dom';

const regex = /[@][\w\u00C0-\u017F\u0370-\u03FF\u0400-\u04FF._+-]+/g;

function getAllIndexes(arr, val) {
    var indexes = [], i = -1;
    while ((i = arr.indexOf(val, i+1)) != -1){
        indexes.push(i);
    }
    return indexes;
}

function onlyUnique(value, index, self) { 
    return self.indexOf(value) === index;
}

export default function ParseUserText({
    text="",
    style={},
    userTextStyle={},
    className,
    userTextClassName,
    extraTextElement,
    appendId
}) {
    const { colors } = useTheme()

    const users = text.match(regex) ? text.match(regex) : []

    let newIdea = []
    
    if(users.length > 0) {
        users.filter(onlyUnique).some((user) => {
            const indexes = getAllIndexes(text, user)
            indexes.forEach(index => {
                if (index >= 0) {
                    newIdea = [ 
                        ...newIdea,
                        {
                            text: newIdea[newIdea.length-1] ? text.substring(newIdea[newIdea.length-1].index + newIdea[newIdea.length-1].length, index) : text.substring(0, index),
                            index: newIdea[newIdea.length-1] ? newIdea[newIdea.length-1].index + newIdea[newIdea.length-1].length : 0,
                            length: newIdea[newIdea.length-1] ? (index-1)-(newIdea[newIdea.length-1].index + newIdea[newIdea.length-1].length) : index,
                            type: "text"
                        }, 
                        {
                            text: text.substr(index, user.length),
                            index,
                            length: user.length,
                            type: "user"
                        },
                    ]
                }
            })
        })
        if(newIdea[newIdea.length-1]) {
            newIdea = [ 
                ...newIdea,
                {
                    text: text.substring(newIdea[newIdea.length-1].index + newIdea[newIdea.length-1].length, text.length),
                    index: newIdea[newIdea.length-1].index + newIdea[newIdea.length-1].length,
                    length: (text.length)-(newIdea[newIdea.length-1].index + newIdea[newIdea.length-1].length),
                    type: "text"
                },
            ]
        }
    } else {
        newIdea = [ 
            {
                text
            } 
        ]
    }

    return (
        <p style={style}  className={className}>
            {newIdea.map((obj, i) => (
                obj.type !== "user"
                ? 
                    obj.text
                : (
                    <Link 
                    key={`ParseUserText-link-${appendId}-${i}`}
                    to={`/${obj.text.substr(1, obj.text.length-1)}`} 
                    style={userTextStyle} 
                    className={`${userTextClassName} cursor`}
                    >
                        {obj.text}
                    </Link>
                )
            ))}
            {extraTextElement && extraTextElement}
        </p>
    )
}