import styles from './ratingsgraph.module.css'

import React, { useState } from 'react'
import { useTheme } from '../../hooks/theme'
import Icon from '../Icon'
import interpolation from '../../utils/interpolation'
import { checkNull } from '../../utils/format-text'

export default function RatingsGraph({ ratings=[], className }) {
    
    return (
        <div className={`${styles.container} ${className}`}>
            <div className="flex-row">
                {ratings.map((number, index) => (
                    <div 
                    className={styles.item}
                    key={`userProfileRating-${index}`}
                    >
                        <div
                        className={styles.bar}
                        style={{
                            height: interpolation(0, Math.max.apply(Math, ratings), 3, 40, number), 
                        }} 
                        />

                        <div
                        className={styles.textCnt}
                        >
                            {index === 0 &&
                                <div className={`flex-row ${styles.rating}`}>
                                    <h6 className={`${styles.ratingText}`}>0.5</h6>

                                    <Icon icon="star" size={14} color={"var(--yellow)"} />
                                </div>
                            }

                            {index === 9 &&
                                <div className={`flex-row ${styles.rating}`}>
                                    <h6 className={styles.ratingText}>5</h6>

                                    <Icon icon="star" size={14} color={"var(--yellow)"} />
                                </div>
                            }

                            <h6 className={styles.number}>{checkNull(number)}</h6>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
