import styles from './user.module.css'

import React, { useEffect, useState } from 'react'


import { Link, useLocation, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'

import { useTheme } from '../../hooks/theme'
import { fetchUser, getUsersLists, getUsersRatings, getUsersReviews, getWants, getUserUid, blockUser, unblockUser, unfollowUser, followUser, getFavoriteAlbums, getUsersSuggestedUsers, getUserFollowStatus } from '../../actions/users-actions'
import { checkNull, addEndingS, numberWithCommas } from '../../utils/format-text'
import { types, status } from '../../constants/action-types'
import { filterAndFill } from '../../utils/format-array'

import Icon from '../../components/Icon'
import Button from '../../components/Button'
import Stats from '../../components/Stats'
import Heading from '../../components/Heading'
import BigBackendItem from '../../components/BigBackendItem'
import ReviewItem from '../../components/ReviewItem'
import ListItem from '../../components/ListItem'
import RatingsGraph from '../../components/RatingsGraph'
import SmallBackendItem from '../../components/SmallBackendItem'
import BoldListButton from '../../components/BoldListButton'
import VerifiedIcon from '../../components/VerifiedIcon'
import ProIcon from '../../components/ProIcon'
import FullBackground from '../../components/FullBackground'
import StickyScrolling from '../../components/StickyScrolling'
import NotFound from '../NotFound'
import { usePopup } from '../../hooks/popup/popup'
import ProAd from '../../routes/Home/components/ProAd'
import OptionsMenu from '../../components/OptionsMenu'
import { useAlert } from '../../hooks/alert/alert'
import MenuBar from '../../components/MenuBar'
import ShareElement from '../../components/ShareElement'
import MiniFooter from '../../components/MiniFooter'
import { useMediaQuery } from 'react-responsive'
import { appStoreUrl, googlePlayUrl, ogImageUrl, twitterImageUrl } from '../../config'
import { useStatusHandler } from '../../hooks/status-handler'
import Tooltip from '../../components/Tooltip'
import BigUserItem from '../../components/BigUserItem'
import SendMessageBtn from './components/SendMessageBtn'
import DefaultHelmet from '../../shared/DefaultHelmet'
import ColorOverwrite from '../../shared/ColorOverwrite/ColorOverwrite'
import UserProfilePicture from '../../components/UserProfilePicture'
import { themeEnums } from '../../constants/enums'
import StructuredData from '../../shared/StructuredData'
import { createUserProfilePageSchema, createUserPersonSchema, createBreadcrumbSchema } from '../../data/structured-data'
import { useTrack } from '../../hooks/tracking'

export default function User(props) {
    //General
    const { colors, mode } = useTheme()
    const { openPopup } = usePopup()
    const { username } = useParams()
    const { openAlert } = useAlert()
    const dispatch = useDispatch()
    const track = useTrack()
    
    //Responsive
    const isMidScreen = useMediaQuery({ maxWidth: 1100 })
    const isSmallScreen = useMediaQuery({ maxWidth: 960 })
    const isTablet = useMediaQuery({ maxWidth: 768 })
    const isMobile = useMediaQuery({ maxWidth: 630 })
    const isMinimum = useMediaQuery({ maxWidth: 490 })

    //Show a reset password alert from params
    const location = useLocation()
    const statsParam = new URLSearchParams(location.search).get("stats")
    const deletedRatingParam = new URLSearchParams(location.search).get("deleted-rating")
    const deletedReviewParam = new URLSearchParams(location.search).get("deleted-review")
    const deletedListParam = new URLSearchParams(location.search).get("deleted-list")
    const redirectDownloadParam = new URLSearchParams(location.search).get("dl")
    useEffect(() => {
        if (statsParam === "error") {
            setTimeout(() => {
                openAlert({
                    style: "red",
                    p: "In-depth statistics are only available for Pro users.",
                    timer: 5000
                })
            }, 1200)
        }
        if (deletedRatingParam === "success") {
            openAlert({
                style: "green",
                p: "Successfully deleted rating."
            })
        }
        if (deletedReviewParam === "success") {
            openAlert({
                style: "green",
                p: "Successfully deleted review."
            })
        }
        if (deletedListParam === "success") {
            openAlert({
                style: "green",
                p: "Successfully deleted list."
            })
        }

        if (redirectDownloadParam === "t") {
            const onIOS = window.navigator.userAgent.includes("iPhone") || window.navigator.userAgent.includes("iPad");
            const onANDROID = window.navigator.userAgent.includes("Android");

            if (onIOS) {
                document.location.href = appStoreUrl;
            } else if (onANDROID) {
                document.location.href = googlePlayUrl;
            }
        }
    }, [])

    //Auth
    const isModerator = useSelector(state => state.me.is_moderator);
    const isUserModerator = useSelector(state => state.me.is_user_moderator);
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const myUid = useSelector(state => state.auth.uid);

    //Data
    const user = useSelector(state => state.users.users[username])
    const recentActivity = useSelector(
        state => state.users.users[username] && state.users.users[username].recentActivity
        ? state.users.users[username].recentActivity
        : null
    )
    const pinnedReviews = useSelector(
        state => state.users.users[username] && state.users.users[username].pinnedReviews
        ? state.users.users[username].pinnedReviews
        : null
    )
    const pinnedLists = useSelector(
        state => state.users.users[username] && state.users.users[username].pinnedLists
        ? state.users.users[username].pinnedLists
        : null
    )
    const wantList = useSelector(
        state => state.users.users[username] && state.users.users[username].wants
        ? state.users.users[username].wants
        : null
    )
    const favoriteAlbums = useSelector(
        state => state.users.users[username] && state.users.users[username].favoriteAlbums
        ? state.users.users[username].favoriteAlbums
        : null
    )

    const fetchUserStatus = useSelector(state => state.users.fetchUserStatus)
    const errorMessage = useSelector(state => state.users.errorMessage)
    const updatePinnedContentStatus = useSelector(state => state.me.updatePinnedContentStatus)

    const suggestedUsers = useSelector(
        state => state.users.users[username] && state.users.users[username].suggestedUsers
        ? state.users.users[username].suggestedUsers
        : null
    )

    //followObj
    const lastUnfollowUserUid = useSelector(state => state.users.lastUnfollowUserUid)
    const unfollowUserStatus = useSelector(state => state.users.unfollowUserStatus)
    const myFollows = useSelector(state => state.me.follows)
    const cachedFollows = useSelector(state => state.me.cachedFollows)
    const follows = [ ...myFollows, ...cachedFollows ]
    const followIndex = follows.findIndex(follow => follow.followed_user === user?.uid)
    const followObj = followIndex !== -1 ? follows[followIndex] : null

    const lastUnblockUserUid = useSelector(state => state.users.lastUnblockUserUid)
    const unblockUserStatus = useSelector(state => state.users.unblockUserStatus)

    //blockObj
    const myBlocks = useSelector(state => state.me.blocks)
    const cachedBlocks = useSelector(state => state.me.cachedBlocks)
    const blocks = [ ...myBlocks, ...cachedBlocks ]
    const blockIndex = blocks.findIndex(block => block.blocked_user === user?.uid)
    const blockObj = blockIndex !== -1 ? blocks[blockIndex] : null

    //blockedBy
    const blockedByObj = useSelector(state => 
        state.me.blockedBy && state.me.blockedBy.find(block => block.creator === user?.uid)
    )

    //Variable
    const isMe = user?.uid === myUid;

    //State
    const [showSuggestedUsers, setShowSuggestedUsers] = useState(false)
    const [initWait, setInitWait] = useState(true)

    //On new username
    useEffect(() => {
        //Set init wait to hide error
        setInitWait(true)
        setTimeout(() => {
            setInitWait(false)
        }, 75)

        //Get data
        dispatch(
            fetchUser(username)
        )
    }, [ username ])
    
    useEffect(() => {
        if (user && user.uid) {
            dispatch( 
                getUsersRatings({
                    username,
                    uid: user.uid, 
                    type: types.GET_USERS_RECENT_ACTIVITY,
                    order_by: "-listened_at", 
                }) 
            )
            dispatch( 
                getUsersLists({
                    username,
                    uid: user.uid,
                    type: types.GET_USERS_PINNED_LISTS,
                    pinned: true
                }) 
            )
            dispatch( 
                getUsersReviews({
                    username,
                    uid: user.uid,
                    type: types.GET_USERS_PINNED_REVIEWS,
                    pinned: true
                }) 
            )
            dispatch( 
                getWants({
                    username,
                    uid: user.uid,
                    order_by: '-created_at'
                }) 
            )
            dispatch( 
                getFavoriteAlbums(username, user.uid) 
            )

            dispatch(
                getUserFollowStatus(username, user.uid)
            )
        }
    }, [ user?.uid, blockObj, blockedByObj ])

    //Handle status for suggestedUsers
    useStatusHandler({
        effectStatus: suggestedUsers && suggestedUsers.status,
        successCallback: () => {
            if (suggestedUsers && suggestedUsers.data && suggestedUsers.data.length > 0) {
                setShowSuggestedUsers(true)
            } else {
                openAlert({
                    p: "No suggested users found at this moment.",
                    style: "red",
                    timer: 3000
                })
            }
        },
        errorCallback: () => {
            openAlert({
                title: "Could not load suggest users. Error: " + errorMessage,
                style: "red",
                timer: 4000
            })
        }
    })

    //Keep pinned updated after updating pins
    useStatusHandler({
        effectStatus: updatePinnedContentStatus,
        successCallback: () => {
            if (isMe) {
                dispatch( 
                    getUsersLists({
                        username,
                        uid: user?.uid,
                        type: types.GET_USERS_PINNED_LISTS,
                        pinned: true
                    }) 
                )
                dispatch( 
                    getUsersReviews({
                        username,
                        uid: user?.uid,
                        type: types.GET_USERS_PINNED_REVIEWS,
                        pinned: true
                    }) 
                )
            }
        }
    })

    //Render loading or 404
    if(!user || (user && !user.uid)) {
        if(!initWait && fetchUserStatus === status.ERROR) {
            return (
                <NotFound />
            )
        } else {
            return (
                <div className="full-height">
                    <div className="full-center">
                        <Icon className="margin-auto" icon="loading" color={colors.darkLightGrey} size={32} />
                    </div>
                </div>
            )
        }
    } 

    // Preparing an array for the pinned material
    const pinned = 
        (pinnedReviews && pinnedLists) ? 
            [
                pinnedReviews.data[0] ? pinnedReviews.data[0] : null,
                pinnedLists.data[0] ? pinnedLists.data[0] : null,
                pinnedReviews.data[1] ? pinnedReviews.data[1] : null,
                pinnedLists.data[1] ? pinnedLists.data[1] : null,
                pinnedReviews.data[2] ? pinnedReviews.data[2] : null,
                pinnedLists.data[2] ? pinnedLists.data[2] : null,
                pinnedReviews.data[3] ? pinnedReviews.data[3] : null,
                pinnedLists.data[3] ? pinnedLists.data[3] : null,
                pinnedReviews.data[4] ? pinnedReviews.data[4] : null,
                pinnedLists.data[4] ? pinnedLists.data[4] : null,
            ]
            .filter(item => item)
            .filter((_, i) => i < 4)
            .sort((a,b) => moment(a.created_at).isAfter(b.created_at) ? -1 : 1)
        : []

    // Preparing an array for the more section
    const moreItems = [
        {
            label: "Reviews",
            amount: checkNull(user.counts.review_set),
            navigation: user.counts.review_set ? `reviews` : null,
        },
        {
            label: "History",
            amount: checkNull(user.counts.rating_set),
            navigation: user.counts.rating_set ? `history` : null,
        },
        {
            label: "Lists",
            amount: checkNull(user.counts.list_set),
            navigation: (user.counts.list_set || isMe) ? `lists` : null,
            forceAllow: isMe
        },
        {
            label: "Listen Later",
            amount: checkNull(user.counts.want_list),
            navigation: (user.counts.want_list || isMe) ? `wantlist` : null,
            forceAllow: isMe
        },
        {
            label: "Albums",
            amount: checkNull(user.counts.rated_albums),
            navigation: user.counts.rated_albums ? `albums` : null,
        },
        {
            label: "Tracks",
            amount: checkNull(user.counts.rated_tracks),
            navigation: user.counts.rated_tracks ? `tracks` : null,
        },
        {
            label: "Artists",
            amount: checkNull(user.counts.rated_artists),
            navigation: user.counts.rated_artists ? `artists` : null,
        },
        {
            label: "Contributions",
            amount: checkNull(user.counts.contributions),
            navigation: (user.counts.contributions || isMe) ? `contributions` : null,
            forceAllow: isMe
        },
        {
            label: "Followers",
            amount: checkNull(user.counts.followed_by),
            navigation: user.counts.followed_by ? `followers` : null,
        },
        {
            label: "Following",
            amount: checkNull(user.counts.follows),
            navigation: user.counts.follows ? `following` : null,
        },
    ]

    return (
        <div>
            {favoriteAlbums && favoriteAlbums.data && (favoriteAlbums.data.length > 0 || favoriteAlbums.status === status.SUCCESS) &&
                <DefaultHelmet
                title={`${user.name !== "" ? `${user.name} (@${user.username})` : `@${user.username}`} profile - Musicboard`}
                description={
                    user.biography !== "" ?
                        `${user.name !== "" ? user.name : user.username} uses Musicboard to share music reviews, ratings and lists. ${numberWithCommas(user.counts.rated_albums || 0)} albums rated. ${numberWithCommas(user.counts.followed_by || 0)} followers. ${favoriteAlbums?.data?.length > 0 ? `Favorite albums: ${favoriteAlbums.data.map((conn) => conn.album.title).join(", ")}.` : ""} Biography: ${user.biography.substr(0, 200)}${user.biography.length >= 200 ? '...' : ''}`
                    :
                        `${user.name !== "" ? user.name : user.username} uses Musicboard to share music reviews, ratings and lists. ${numberWithCommas(user.counts.rated_albums || 0)} albums rated. ${numberWithCommas(user.counts.followed_by || 0)} followers. ${favoriteAlbums?.data?.length > 0 ? `Favorite albums: ${favoriteAlbums.data.map((conn) => conn.album.title).join(", ")}.` : ""} View their entire music profile on Musicboard.`
                }
                canonical={`https://musicboard.app/${user.username}`}
                ogType="profile"
                ogImage={user.profile_picture_large || user.profile_picture || ogImageUrl}
                ogImageAlt={user.name !== "" ? user.name : user.username}
                ogImageWidth={user.profile_picture ? 500 :  2400}
                ogImageHeight={user.profile_picture ? 500 :  1440}
                twitterImage={user.profile_picture_large || user.profile_picture || twitterImageUrl}
                twitterImageWidth={user.profile_picture ? 500 :  1428}
                twitterImageHeight={user.profile_picture ? 500 :  824}
                labelOne={{ label: "Ratings", data: `${user.counts.rating_set} albums rated` }}
                labelTwo={{ label: "Followers", data: `${user.counts.followed_by} followers` }}
                deepLink={`musicboard://user/${user.uid}`}
                headingOne={user.username}
                headingTwo={`${addEndingS(user.username)} profile on Musicboard`}
                />
            }

            <StructuredData
            key={`User-${user.uid}-ProfilePage-Data`}
            id={`User-${user.uid}-ProfilePage-Data`}
            data={createUserProfilePageSchema(user)}
            />

            <StructuredData
            key={`User-${user.uid}-Person-Data`}
            id={`User-${user.uid}-Person-Data`}
            data={createUserPersonSchema(user)}
            />

            <StructuredData
            key={`User-${user.uid}-Breadcrumb-Data`}
            id={`User-${user.uid}-Breadcrumb-Data`}
            data={createBreadcrumbSchema([
                { "name": "Musicboard", "item": "https://musicboard.app/" },
                { "name": "Users", "item": "https://musicboard.app/users/" },
				{ "name": user.username, "item": `https://musicboard.app/${user.username}/` }
			])}
            />

            {user.is_pro && user.tier === 1 && user.color_theme !== null && user.color_theme !== undefined &&
                <ColorOverwrite
                colorTheme={themeEnums[user.color_theme]}
                styleName={"user-color-overwrite"}
                setBackground
                />
            }

            <div className='user-color-overwrite'>
                <div style={{ backgroundColor: 'var(--lightestGrey)' }}>
                    <MenuBar
                    tabs={[
                        {
                            text: "Home",
                            redirect: `/${username}`
                        },
                        {
                            text: "Collection",
                            redirect: `/${username}/reviews`,
                        },
                        user.is_pro && user.tier === 1 && {
                            text: "Statistics",
                            redirect: `/${username}/stats`,
                        },
                    ]}
                    >
                        <div className="padding-bottom-32" style={{ position: 'relative', zIndex: 1 }}>
                            {user.is_pro && (user.tier === 1 || !user?.background?.background_original?.includes(".gif")) && !user.is_banned && user.background &&
                                <FullBackground
                                whiteGradient
                                image={user.background.background_original?.includes(".gif") ? user.background.background_original : user.background.background_large}
                                placeholder={user.background.background_small}
                                marginHeight={350}
                                altText={`User background for ${user.name !== "" ? user.name : user.username}`}
                                />
                            }
                        
                            <div className="page-container padding-top-64">
                                <div className="section-handler">
                                    <div className="section-3 section-margin">
                                        <div className={isSmallScreen ? "flex-column flex-center" : "flex-row align-top"}>
                                            <UserProfilePicture
                                            size={120}
                                            profilePicture={user.profile_picture?.includes(".gif") ? user.profile_picture : user.profile_picture_large || user.profile_picture}
                                            profileFrame={user.is_pro && user.tier === 1 ? user.profile_frame : null}
                                            altText={user.name !== "" ? user.name : user.username}
                                            />

                                            <div className={isSmallScreen ? "flex-column flex-center margin-top-24" : "margin-left-16"}>
                                                {(!isSmallScreen || user.name !== "") &&
                                                    <h5 className="grey" style={{ opacity: user.name !== "" ? 1 : 0 }}>{user.username}</h5>
                                                }
                                                
                                                <div className={`flex-row ${isTablet ? "margin-top-4" : ""}`} style={{flexWrap: 'wrap'}}>
                                                    <h4 className="black text-2xl" style={{ fontSize: isTablet ? 32 : 40, lineHeight: isTablet ? "40px" : "50px", textAlign: isSmallScreen ? "center" : "left" }}>{user.name !== "" ? user.name : user.username}</h4>
                                                    
                                                    {(user.is_pro || user.is_moderator || user.contributor_tier) &&
                                                        <ProIcon 
                                                        size="large" 
                                                        className="margin-left-12 margin-top-8" 
                                                        style={{ marginBottom: 1 }} 
                                                        is_pro={user.is_pro}
                                                        is_moderator={user.is_moderator}
                                                        contributor_tier={user.contributor_tier}
                                                        />
                                                    }

                                                    {user.is_verified &&
                                                        <VerifiedIcon className="margin-left-12 margin-top-8" style={{ marginBottom: 1 }} />
                                                    }
                                                </div>

                                                {user.biography !== "" &&
                                                    <p style={{ fontSize: 16 }} className={`grey ${isTablet ? "margin-top-8 text-center" : isSmallScreen ? "margin-top-8 text-center" : "margin-top-4"}`}>{user.biography}</p>
                                                }

                                                {!isMe && user.follow_status && user.follow_status.followed_by &&
                                                    <p style={{ fontSize: 16, marginTop: 10 }} className={`grey ${isTablet ? "text-center" : isSmallScreen ? "text-center" : ""}`}>
                                                        {user.follow_status.follows ? `You and ${user.username} follow each other!` : `${user.username} follows you. Follow them back!`}
                                                    </p>
                                                }

                                                <div className={`margin-top-12 flex-row`}>
                                                    {user.website !== "" &&
                                                        <a className={`flex-row blue`} target="_blank" rel="noopener noreferrer" href={user.website}>
                                                            <Icon icon="public" size={19} color="var(--blue)" />

                                                            <div className='grid margin-left-4'>
                                                                <p style={{ fontSize: 16, maxWidth: 160, marginBottom: 1 }} className={`one-line underline inline-block ${isSmallScreen ? "text-center" : ""}`}>
                                                                    {user.website.replace("https://", "").replace("http://", "").replace("www.", "")}
                                                                </p>
                                                            </div>
                                                        </a>
                                                    }
                                                    
                                                    {user.twitter !== "" &&
                                                        <a className={`${user.website !== "" && "margin-left-16"} flex-row blue`} target="_blank" rel="noopener noreferrer" href={`https://x.com/${user.twitter}`}>
                                                            <Icon icon="x" size={17} color="var(--blue)" />

                                                            <div className='grid margin-left-4'>
                                                                <p style={{ fontSize: 16, maxWidth: 160, marginBottom: 1 }} className={`one-line underline inline-block ${isSmallScreen ? "text-center" : ""}`}>
                                                                    @{user.twitter}
                                                                </p>
                                                            </div>
                                                        </a>
                                                    }
                                                    
                                                    {user.twitter === "" && isMe &&
                                                        <Link className={`${user.website !== "" && "margin-left-16"} flex-row blue`} rel="noopener noreferrer" to={`/settings`}>
                                                            <Icon icon="x" size={17} color="var(--blue)" />

                                                            <div className='grid margin-left-4'>
                                                                <p style={{ fontSize: 16, marginBottom: 1 }} className={`one-line underline inline-block ${isSmallScreen ? "text-center" : ""}`}>
                                                                    Add X Link...
                                                                </p>
                                                            </div>
                                                        </Link>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="section-2 ">
                                        <Stats
                                        className={isSmallScreen ? "margin-top-32" : ""}
                                        statistics={[
                                            {
                                                number: checkNull(user.counts.review_set),
                                                description: "Reviews",
                                                redirect: `/${username}/reviews`
                                            },
                                            {
                                                number: checkNull(user.counts.rating_set),
                                                description: "Listened",
                                                redirect: `/${username}/history`
                                            },
                                            {
                                                number: checkNull(user.counts.follows),
                                                description: "Following",
                                                redirect: `/${username}/following`
                                            },
                                            {
                                                number: checkNull(user.counts.followed_by),
                                                description: "Followers",
                                                redirect: `/${username}/followers`
                                            },
                                        ]}
                                        />
                                        
                                        {isMe &&
                                            <Link to={"/settings"}>
                                                <Button
                                                className="margin-top-16"
                                                type="secondary"
                                                textStyle={{ color: "var(--black)" }}
                                                text="Edit profile"
                                                noShadow
                                                />
                                            </Link>
                                        }

                                        {!isMe && !blockObj && !blockedByObj &&
                                            <div className='flex-row margin-top-16'>
                                                <Button
                                                className="col-1"
                                                text={followObj ? "Following" : "Follow"}
                                                type={followObj ? "secondary" : "primary"}
                                                loading={unfollowUserStatus === status.BEGIN && lastUnfollowUserUid === user.uid}
                                                textStyle={followObj ? { color: "var(--black)" } : {}}
                                                onClicked={() => {
                                                    if (!isLoggedIn) {
                                                        openPopup("SignUp")
                                                        return;
                                                    }

                                                    if (followObj) {
                                                        dispatch(
                                                            unfollowUser(followObj.uid, user.uid)
                                                        )
                                                    } else {
                                                        track(
                                                            "Follow user", 
                                                            { 
                                                                "user_uid": user.uid,
                                                                "referrer": "User screen",
                                                            }
                                                        );

                                                        dispatch(
                                                            followUser(user.uid)
                                                        )
                                                    }

                                                }}
                                                />
                                                
                                                {(
                                                    !user.direct_messages_privacy || 
                                                    user.direct_messages_privacy === 0 || 
                                                    (user.direct_messages_privacy === 1 && user.follow_status && user.follow_status.followed_by)
                                                ) &&
                                                    <div
                                                    id={`User-messages-tip`} 
                                                    className='margin-left-8'
                                                    >
                                                        <SendMessageBtn user={user} />
                                                    </div>
                                                }
                                                
                                                <div
                                                id={`User-suggestions-tip`} 
                                                className='margin-left-8'
                                                >
                                                    <Button
                                                    squareButton
                                                    showIcon={true}
                                                    iconName={showSuggestedUsers ? "expand_less" : "supervised_user_circle"}
                                                    iconSize={showSuggestedUsers ? 32 : 24}
                                                    iconColor={"var(--black)"}
                                                    type={"secondary"}
                                                    disabled={suggestedUsers && suggestedUsers.status === status.BEGIN}
                                                    loading={suggestedUsers && suggestedUsers.status === status.BEGIN}
                                                    onClicked={() => {
                                                        if (!isLoggedIn) {
                                                            openPopup("SignUp")
                                                            return;
                                                        }
                                                        
                                                        if (showSuggestedUsers) {
                                                            setShowSuggestedUsers(false)
                                                        } else {
                                                            if (suggestedUsers && suggestedUsers.data && suggestedUsers.data.length > 0) {
                                                                setShowSuggestedUsers(true)
                                                            } else {
                                                                if (!suggestedUsers || suggestedUsers.status !== status.BEGIN) {
                                                                    dispatch(
                                                                        getUsersSuggestedUsers(username, user.uid, user.favorite_genres)
                                                                    )
                                                                }
                                                            }
                                                        }
                                                    }}
                                                    />
                                                </div>

                                                <Tooltip
                                                place="bottom"
                                                reverseColor
                                                id="User-messages-tip"
                                                text="Send message"
                                                offset={12}
                                                />

                                                <Tooltip
                                                place="bottom"
                                                reverseColor
                                                id="User-suggestions-tip"
                                                text="More users"
                                                offset={12}
                                                />
                                            </div>
                                        }

                                        {!isMe && isLoggedIn && blockObj &&
                                            <Button
                                            className="margin-top-16"
                                            text="Unblock user"
                                            loading={unblockUserStatus === status.BEGIN && lastUnblockUserUid === user.uid}
                                            onClicked={() => {
                                                dispatch(
                                                    unblockUser(blockObj.uid, user.uid)
                                                )
                                            }}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>

                            {suggestedUsers && suggestedUsers.data && suggestedUsers.data.length > 0 && showSuggestedUsers &&
                                <div className="page-container">
                                    <div className={`${styles.suggestedUsersContent} margin-top-32`}>
                                        <div className={`grid-view margin-24 ${isMinimum ? "three" : isMobile ? "four" : isSmallScreen ? "five" : isMidScreen ? "six" : "eight"}`}>
                                            {suggestedUsers.data.filter((_,i) => i < (isMinimum ? 3 : isMobile ? 4 : isSmallScreen ? 5 : isMidScreen ? 6 : 8)).map((user, index) => (
                                                <BigUserItem key={`User-suggestedUsers-user-${user.uid}`} target="_blank" user={user} />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </MenuBar>

                    <div className="page-container">
                        {!blockObj && !blockedByObj && !user.is_banned && //Render content if user isn't blocked
                            <div className="section-handler">
                                {/* LEFT SIDE */}
                                <div className="section-2 section-margin">

                                    {favoriteAlbums && favoriteAlbums.data && favoriteAlbums.data.length > 0 &&
                                        <Heading
                                        text="Favorite albums"
                                        className="margin-top-32"
                                        />
                                    }

                                    {favoriteAlbums && favoriteAlbums.data && favoriteAlbums.data.length > 0 &&
                                        <div className={`grid-view ${isMinimum ? "two" : "four"} section-block padding-around`}>
                                            {favoriteAlbums && filterAndFill(favoriteAlbums.data, 4).map((item, index) => (
                                                <div key={`user-favoritealbum-${item ? item.uid : index}`}>
                                                    {item &&
                                                        <BigBackendItem
                                                        content={item.album}
                                                        showArtistName
                                                        />
                                                    }

                                                    {!item && isMe && !isMinimum &&
                                                        <Link to="/settings" className={styles.addFavoriteAlbum}>
                                                            <Icon icon="add" className={styles.favoriteAlbumIcon} />
                                                        </Link>
                                                    }
                                                </div>
                                            ))}
                                        </div>
                                    }

                                    <Heading
                                    text="Recent activity"
                                    redirect={`/${username}/history`}
                                    className="margin-top-32"
                                    />

                                    {recentActivity && recentActivity.data && recentActivity.data.length > 0 &&
                                        <div className={`grid-view ${isMobile ? "three" : isTablet ? "four" : isSmallScreen ? "five" : "four"} section-block padding-around`}>
                                            {recentActivity && filterAndFill(recentActivity.data, isMobile ? 3 : isTablet ? 4 : isSmallScreen ? 5 : 4).map((item, index) => (
                                                <div key={`user-recentactivity-${item ? item.uid : index}`}>
                                                    {item && item.content &&
                                                        <BigBackendItem
                                                        content={item.content}
                                                        rating={item.rating}
                                                        ratingObj={item}
                                                        />
                                                    }
                                                </div>
                                            ))}
                                        </div>
                                    }

                                    {recentActivity && recentActivity.data && recentActivity.data.length <= 0 &&
                                        <div className="empty-text flex-column flex-center section-block">
                                            <p>{isMe ? "You have" : `${user.username} has`} no recent activity.</p>

                                            {isMe &&
                                                <div 
                                                onClick={() => openPopup("SearchContent")} 
                                                className={`${styles.clickableP} margin-top-8 flex-center cursor`}
                                                >
                                                    <Icon size={16} className="margin-right-8" icon="star" />
                                                    <p>Start off by rating some of your favorite music.</p>
                                                </div>
                                            }
                                        </div>
                                    }

                                    <Heading
                                    text="Pinned"
                                    redirect={`/${username}/pinned`}
                                    className="margin-top-32"
                                    />
                                    <div className="section-block">
                                        {pinned.map((item, index) => (
                                            <div key={`user-pinned-${item.uid}`}>
                                                {item.type === "review" ?
                                                    <ReviewItem
                                                    review={item}
                                                    hideUser
                                                    isFirst={index === 0}
                                                    isLast={index === pinned.length-1}
                                                    />
                                                :
                                                    <ListItem
                                                    list={item}
                                                    hideUser
                                                    isFirst={index === 0}
                                                    isLast={index === pinned.length-1}
                                                    />
                                                }
                                            </div>
                                        ))}
                                        {pinned.length <= 0 &&
                                            <div className="empty-text flex-column flex-center">
                                                <p>{isMe ? "You have" : `${user.username} has`} no pinned content.</p>
                                                {isMe &&
                                                    <div 
                                                    onClick={() => openPopup("PinContent")} 
                                                    className={`${styles.clickableP} margin-top-8 flex-center cursor`}
                                                    >
                                                        <Icon size={16} className="margin-right-8" icon="push-pin" />
                                                        <p>Pin a review.</p>
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                                
                                {/* RIGHT SIDE SIDE */}
                                <div className="section-1 ">
                                    <StickyScrolling enabled={!isSmallScreen}>
                                        {isMe && !user.is_pro &&
                                            <div className="padding-top-32">
                                                <ProAd addMargin={false} />
                                            </div>
                                        }

                                        <div className="padding-top-32">
                                            <OptionsMenu
                                            options={[
                                                user.is_pro && user.tier === 1 && {
                                                    text: `${isMe ? "Your" : addEndingS(user.name !== "" ? user.name : user.username)} stats in music`,
                                                    redirect: `/${user.username}/stats`
                                                },
                                                isLoggedIn && {
                                                    text: "Share on Musicboard",
                                                    onPress: () => openPopup("SendContent", { type: "user", content: user }),
                                                },
                                                {
                                                    onPress: () => openPopup("ShareUser", { user: user }),
                                                    element: (
                                                        <ShareElement 
                                                        type="user" 
                                                        title={user.name !== "" ? `${user.name} (${user.username})` : user.username} 
                                                        image={user.profile_picture} 
                                                        />
                                                    )
                                                }
                                            ]}
                                            />
                                        </div>

                                        <Heading
                                        text="Ratings"
                                        redirect={`/${username}/history`}
                                        className="padding-top-32"
                                        />

                                        <div className="section-block padding-around">
                                            <RatingsGraph ratings={user.rating_counts} />
                                        </div>

                                        {(isMe || (wantList && wantList.count > 0)) &&
                                            <Heading
                                            text="Listen Later"
                                            redirect={`/${username}/wantlist`}
                                            className="margin-top-32"
                                            />
                                        }
                                        
                                        {(isMe || (wantList && wantList.count > 0)) &&
                                            <div className="section-block padding-around-8">
                                                {(wantList && wantList.count > 0) &&
                                                    <div>
                                                        {wantList.data.filter((_, i) => i < 6).map((item, index) => (
                                                            <SmallBackendItem
                                                            key={`user-want-${item.uid}`}
                                                            content={item.content}
                                                            showArtistName
                                                            />
                                                        ))}
                                                    </div>
                                                }

                                                {(isMe && wantList && wantList.count <= 0) &&
                                                    <div className="empty-text flex-column flex-center">
                                                        <p>Found new music you want to check out in the future?</p>

                                                        {isMe &&
                                                            <Link 
                                                            to={`/${username}/wantlist`}
                                                            className={`${styles.clickableP} margin-top-8 flex-center cursor`}
                                                            >
                                                                <Icon size={16} className="margin-right-8" icon="music-note" />
                                                                <p>Add it to your Listen Later.</p>
                                                            </Link>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        }

                                        <Heading
                                        text="More"
                                        className="margin-top-32"
                                        />

                                        <div className="section-block padding-around-8">
                                            {moreItems.map((item, index) => (
                                                <BoldListButton
                                                key={`user-listbutton-${item.label}`}
                                                text={item.label}
                                                redirect={item.navigation && `/${username}/${item.navigation}`}
                                                subText={item.amount}
                                                disabled={item.amount == 0 && !item.forceAllow}
                                                />
                                            ))}
                                        </div>
                                        
                                        {!isMe &&
                                            <Heading
                                            text="Settings"
                                            className="margin-top-32"
                                            />
                                        }
                                        
                                        {!isMe &&
                                            <div className="section-block padding-around-8">
                                                <BoldListButton
                                                text={`Report ${user.username}`}
                                                onPress={() => {
                                                    if (!isLoggedIn) {
                                                        openPopup("Login")
                                                        return;
                                                    }

                                                    openPopup("ReportContent", { content: user, type: "user" })
                                                }}
                                                />
                                                
                                                <BoldListButton
                                                text={`Block ${user.username}`}
                                                onPress={() => {
                                                    if (!isLoggedIn) {
                                                        openPopup("Login")
                                                        return;
                                                    }

                                                    dispatch(
                                                        blockUser(user.uid)
                                                    )
                                                }}
                                                />
                                            </div>
                                        }

                                        {(isModerator || isUserModerator) &&
                                            <a 
                                            href={`https://api.musicboard.app/admin/users/user/${user.id}`}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            >
                                                <Button
                                                className='margin-top-24'
                                                type="secondary"
                                                text="Open in Admin"
                                                height={41}
                                                textStyle={{ fontSize: 14 }}
                                                />
                                            </a>
                                        }

                                        <MiniFooter 
                                        className={"margin-top-24"} 
                                        idText={(isModerator || isUserModerator) ? `User ID: ${user.id}\nUID: ${user.uid}` : null}
                                        />
                                    </StickyScrolling>
                                </div>
                            </div>
                        }
                        
                        {blockObj && //Render text if blocked
                            <div className='flex-column flex-center empty-text'>
                                <h4 className="margin-top-64 text-xl black">You blocked @{user.username}</h4>

                                <p className="margin-top-32 highDarkGrey">You have blocked @{user.username}. Unblock this user to follow them or view their content.</p>
                            </div>
                        }

                        {blockedByObj && //Render text if blocked
                            <div className='flex-column flex-center empty-text'>
                                <h4 className="margin-top-64 text-xl black">You're blocked</h4>

                                <p className="margin-top-32 highDarkGrey">You are blocked from following @{user.username} and viewing @{user.username} content.</p>
                            </div>
                        }

                        {user.is_banned && //Render text if banned
                            <div className='flex-column flex-center empty-text'>
                                <h4 className="margin-top-64 text-xl black">Account suspended</h4>

                                <p className="margin-top-32 highDarkGrey">@{user.username} is suspended from Musicboard for violation of our <Link to="/guidelines" style={{ textDecoration: 'underline' }}>Community Guidelines</Link>.</p>
                            </div>
                        }
                    </div>

                    <div style={{ height: 64 }} />
                </div>
            </div>
        </div>
    )
}
