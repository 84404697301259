import { types, status } from '../constants/action-types';
import { errorToString } from '../utils/error'
import { calculateNewRating, calculateUpdatedRating } from '../utils/calculate-ratings';
import moment from 'moment';
import { checkNull, checkNullNumber } from '../utils/format-text';
import { handleNested, handleReducer } from '../utils/reducer-helpers';

const defaultTracks = {
	tracks: {},
	lastFetchedTrackId: null,
	lastFetchedTrackIsRefetch: false,
	fetchTrackStatus: status.DEFAULT,
	fetchMultipleTracksStatus: status.DEFAULT,
	
	allTracks: {
		data: [],
		count: null,
		next: null,
		status: status.DEFAULT,
	},
	
    lastCreatedTrackId: null,
    lastCreatedTrack: null,
    createTrackStatus: status.DEFAULT,

	checkTrackDatabaseResult: {
		status: status.DEFAULT,
		result: null,
		urlSlug: null,
		deezerId: null,
		id: null,
	},

	errorMessage: null,
};

const tracks = (state = defaultTracks, action) => {
	let id = action.meta && action.meta.params ? action.meta.params.id : null
	let reset = action.meta && action.meta.params ? action.meta.params.reset : false

	switch (action.type) {
		case types.SET_INIT_TRACK:
			return {
				...state,
				tracks: {
					...state.tracks,
					[action.payload.id]: {
						...state.tracks[action.payload.id],
						...action.payload,
						rating_distribution: state.tracks[action.payload.id] ? state.tracks[action.payload.id].rating_distribution : [],
					}
				},
				lastFetchedTrackId: action.payload.id,
				lastFetchedTrackIsRefetch: false,
				fetchTrackStatus: status.SUCCESS,
			}

		//Get a specific track
		case types.FETCH_TRACK:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						fetchTrackStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						tracks: {
							...state.tracks,
							[action.payload.id]: {
								...state.tracks[action.payload.id],
								...action.payload,
								rating_distribution: state.tracks[action.payload.id] ? state.tracks[action.payload.id].rating_distribution : [],
							}
						},
						lastFetchedTrackId: action.payload.id,
						lastFetchedTrackIsRefetch: action.meta.params.isRefetch,
						fetchTrackStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						fetchTrackStatus: status.ERROR,
					}
			}

		case types.CHECK_TRACK_DATABASE_STATUS:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						checkTrackDatabaseResult: {
							...state.checkTrackDatabaseResult,
							status: status.BEGIN,
							urlSlug: action.meta.params.urlSlug,
							deezerId: action.meta.params.deezerId,
							id: action.meta.params.id,
						}
					}
				case status.SUCCESS:
					return {
						...state,
						checkTrackDatabaseResult: {
							...state.checkTrackDatabaseResult,
							status: status.SUCCESS,
							result: action.payload,
						}
					}
				case status.ERROR:
					return {
						...state,
						checkTrackDatabaseResult: {
							...state.checkTrackDatabaseResult,
							status: status.ERROR,
						},
						errorMessage: errorToString(action.payload),
					}
			}

		//Get all the tracks for a specific album or for an artist's top tracks for Deezer
		case types.FETCH_MULTIPLE_TRACKS:
			let isDeezerIds = action.meta && action.meta.params ? action.meta.params.isDeezerIds : false
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						fetchMultipleTracksStatus: status.BEGIN,
					}
				case status.SUCCESS:
					const tracks = {}
					action.payload.results.forEach((track) => {
						tracks[(isDeezerIds && track.deezer_id) ? track.deezer_id : track.id] = track
					})
					return {
						...state,
						tracks: {
							...state.tracks,
							...tracks,
						},
						fetchMultipleTracksStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						fetchMultipleTracksStatus: status.ERROR,
					}
			}
		
		//Get all the tracks for an artist's top tracks from the API
		case types.GET_ARTIST_TOP_TRACKS:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						fetchMultipleTracksStatus: status.BEGIN,
					}
				case status.SUCCESS:
					const tracks = {}
					action.payload.results.map((track) => {
						tracks[track.id] = track
					})
					return {
						...state,
						tracks: {
							...state.tracks,
							...tracks,
						},
						fetchMultipleTracksStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						fetchMultipleTracksStatus: status.ERROR,
					}
			}
		
		//Get or create a track
		case types.CREATE_TRACK:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						createTrackStatus: status.BEGIN,
						lastCreatedTrackId: id,
					}
				case status.SUCCESS:
					return {
						...state,
						tracks: {
							...state.tracks,
							[id]: {
								...state.tracks[id],
								...action.payload,
								average_rating: action.payload.average_rating ? action.payload.average_rating : state.tracks[id] ? state.tracks[id].average_rating : null,
							}
						},
						lastCreatedTrack: action.payload,
						createTrackStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						createTrackStatus: status.ERROR,
					}
			}

		case types.CREATE_RATING:
			switch (action.meta.status) {
				case status.BEGIN:
					return state
				case status.SUCCESS:
					const cacheRating = action.meta.params && action.meta.params.cacheRating;
					const contentType = action.payload.content.type;
					const contentId = action.payload.content.deezer_id ? action.payload.content.deezer_id : action.payload.content.id;
					if (cacheRating && contentType === "track" && state.tracks[contentId]) {
						return {
							...state,
							tracks: {
								...state.tracks,
								[contentId]: {
									...state.tracks[contentId],
									ratings_count: checkNullNumber(state.tracks[contentId].ratings_count) + 1,
									average_rating: calculateNewRating(
										state.tracks[contentId].average_rating,
										state.tracks[contentId].ratings_count,
										action.payload.rating
									),
									cacheTime: moment(),

									myRatings: 
										(state.tracks[contentId] && state.tracks[contentId].myRatings) ? 
											{
												...state.tracks[contentId].myRatings,
												data: [
													action.payload,
													...state.tracks[contentId].myRatings.data
												]
											} 
										: 
											null
								}
							},
						}
					}
					return state
				case status.ERROR:
					return state
			}
		
		case types.UPDATE_RATING:
			switch (action.meta.status) {
				case status.BEGIN:
					return state
				case status.SUCCESS:
					const cacheRating = action.meta.params && action.meta.params.cacheRating;
					const oldRating = action.meta.params && action.meta.params.oldRating;
					const contentId = action.payload.content.deezer_id ? action.payload.content.deezer_id : action.payload.content.id;
					if (cacheRating && state.tracks[contentId]) {
						return {
							...state,
							tracks: {
								...state.tracks,
								[contentId]: {
									...state.tracks[contentId],
									average_rating: calculateUpdatedRating(
										state.tracks[contentId].average_rating,
										state.tracks[contentId].ratings_count,
										oldRating,
										action.payload.rating
									),
									cacheTime: moment(),

									myRatings: 
										(state.tracks[contentId] && state.tracks[contentId].myRatings) ? 
											{
												...state.tracks[contentId].myRatings,
												data: state.tracks[contentId].myRatings.data && state.tracks[contentId].myRatings.data.map((rating) => {
													if (rating.uid === action.payload.uid) {
														return action.payload
													}

													return rating;
												})
											} 
										: 
											null
								}
							},
						}
					}
					return state
				case status.ERROR:
					return state
			}

		//Get tracks rating distribution
		case types.GET_TRACK_RATING_DISTRIBUTION:
			switch (action.meta.status) {
				case status.BEGIN:
					return state
				case status.SUCCESS:
					return {
						...state,
						tracks: {
							...state.tracks,
							[id]: {
								...state.tracks[id],
								rating_distribution: action.payload,
							}
						}
					}
				case status.ERROR:
					return state
			}

		case types.GET_TRACK_SUGGESTED_CHANGES:
			return handleNested(state, action, 'suggestedChanges', 'tracks', id)

		//Get any tracks reviews and update the tracks object
		case types.GET_TRACKS_REVIEWS:
			return handleNested(state, action, 'reviews', 'tracks', id)

		case types.GET_TRACKS_HOME_REVIEWS:
			return handleNested(state, action, 'homeReviews', 'tracks', id)

		case types.GET_TRACKS_CONTENT_REVIEWS:
			return handleNested(state, action, 'contentReviews', 'tracks', id)

		//Get my ratings for a track and update the tracks object
		case types.GET_MY_TRACK_RATINGS:
			return handleNested(state, action, 'myRatings', 'tracks', id)

		//Get my lists for a track and update the tracks object
		case types.GET_TRACKS_LISTS:
			return handleNested(state, action, 'lists', 'tracks', id)

		case types.GET_TRACKS_HOME_LISTS:
			return handleNested(state, action, 'homeLists', 'tracks', id)

		//Get my friends ratings for a track and update the tracks object
		case types.GET_TRACKS_RATINGS:
			return handleNested(state, action, 'ratings', 'tracks', id)

		case types.GET_ALL_TRACKS:
			return handleReducer(state, action, 'allTracks')

		default:
			return state;
	}
};

export default tracks;