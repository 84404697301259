import { types, status } from '../constants/action-types';
import { errorToString } from '../utils/error'
import { handleReducer } from '../utils/reducer-helpers';

const defaultThreads = {
	threads: {},
	fetchThreadStatus: status.DEFAULT,
	lastFetchedThreadUid: null,

	allThreads: {
		data: [],
		count: null,
		next: null,
		status: status.DEFAULT,
	},

	myPollAnswers: [],
	cachedPollAnswers: [],

	lastAnswerThreadUid: null,
	lastAnswerPollOptionUid: null,
    answerPollStatus: status.DEFAULT,

	deletePollAnswerUid: null,
    deletePollAnswerStatus: status.DEFAULT,

	errorMessage: null,
};

const threads = (state = defaultThreads, action) => {
	let uid = action.meta && action.meta.params ? action.meta.params.uid : null
	let threadObj = action.meta && action.meta.params ? action.meta.params.threadObj : null

	switch (action.type) {
		case types.SET_INIT_THREAD:
			return {
				...state,
				threads: {
					...state.threads,
					[action.payload.uid]: action.payload
				},
				fetchThreadStatus: status.SUCCESS,
				lastFetchedThreadUid: action.payload.uid,
				errorMessage: null,
			}

		case types.FETCH_THREAD:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
                        ...state,
						threads: {
							...state.threads,
							[uid]: {
								...state.threads[uid],
								...threadObj ? threadObj : state.threads[uid] ? state.threads[uid] : {}
							}
						},
                        fetchThreadStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
                        ...state,
						threads: {
							...state.threads,
							[action.payload.uid]: {
								...state.threads[action.payload.uid],
								...action.payload
							}
						},
						fetchThreadStatus: status.SUCCESS,
						lastFetchedThreadUid: action.payload.uid,
						errorMessage: null,
					}
				case status.ERROR:
					return {
                        ...state,
						threads: {
							...state.threads,
							[uid]: null
						},
                        fetchThreadStatus: status.ERROR,
						lastFetchedThreadUid: null,
                        errorMessage: errorToString(action.payload),
					}
			}

		case types.GET_THREADS:
			return handleReducer(state, action, 'allThreads')

		// Like content
		case types.LIKE_CONTENT:
			if (action.meta.params.contentType !== "thread") return state
			if (!action.meta.params.detailed) return state

			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						threads: {
							...state.threads,
							[action.meta.params.uid]: {
								...state.threads[action.meta.params.uid],
								like_count: state.threads[action.meta.params.uid].like_count + 1
							}
						},
					}
				case status.SUCCESS:
                    return state
				case status.ERROR:
					return {
						...state,
						threads: {
							...state.threads,
							[action.meta.params.uid]: {
								...state.threads[action.meta.params.uid],
								like_count: state.threads[action.meta.params.uid].like_count - 1
							}
						},
					}
			}

		case types.DELETE_LIKE:
			if (action.meta.params.contentType !== "thread") return state
			if (!action.meta.params.detailed) return state

			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						threads: {
							...state.threads,
							[action.meta.params.uid]: {
								...state.threads[action.meta.params.uid],
								like_count: state.threads[action.meta.params.uid].like_count - 1
							}
						},
					}
				case status.SUCCESS:
                    return state
				case status.ERROR:
					return {
						...state,
						threads: {
							...state.threads,
							[action.meta.params.uid]: {
								...state.threads[action.meta.params.uid],
								like_count: state.threads[action.meta.params.uid].like_count + 1
							}
						},
					}
			}

		/* Poll answers */
		case types.FETCH_MY_POLL_ANSWERS:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
					}
				case status.SUCCESS:
					return {
						...state,
						myPollAnswers: action.payload,
						cachedPollAnswers: state.cachedPollAnswers.filter(cached => action.payload.findIndex(answer => answer.uid === cached.uid) === -1)
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
					}
			}

		case types.ANSWER_POLL:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						lastAnswerThreadUid: action.meta.params.threadUid,
						lastAnswerPollOptionUid: action.meta.params.pollOptionUid,
						answerPollStatus: status.BEGIN,
						myPollAnswers: state.myPollAnswers.filter(answer => answer.thread !== action.meta.params.threadUid),
						cachedPollAnswers: [ 
							...state.cachedPollAnswers.filter(answer => answer.thread !== action.meta.params.threadUid), 
							{
								thread: action.meta.params.threadUid,
								poll_option: action.meta.params.pollOptionUid,
							}
						],
						threads: {
							...state.threads,
							[action.meta.params.threadUid]: {
								...state.threads[action.meta.params.threadUid],
								poll_options: state.threads[action.meta.params.threadUid]?.poll_options ? state.threads[action.meta.params.threadUid].poll_options.map(option => {
									if (option.uid === action.meta.params.pollOptionUid) {
										return {
											...option,
											answer_count: option.answer_count + 1
										}
									}
									else if (option.uid === action.meta.params.removedPollOptionUid) {
										return {
											...option,
											answer_count: Math.max(option.answer_count - 1, 0)
										}
									}
									return option
								}) : null
							}
						},
						allThreads: {
                            ...state.allThreads,
                            data: state.allThreads.data.map(item => {
                                if (item.type === "thread" && item.uid === action.meta.params.threadUid) {
                                    return {
                                        ...item,
                                        poll_options: item.poll_options.map(option => {
                                            if (option.uid === action.meta.params.pollOptionUid) {
                                                return {
                                                    ...option,
                                                    answer_count: option.answer_count + 1
                                                }
                                            }
                                            else if (option.uid === action.meta.params.removedPollOptionUid) {
                                                return {
                                                    ...option,
                                                    answer_count: Math.max(option.answer_count - 1, 0)
                                                }
                                            }
                                            return option
                                        })
                                    }
                                }
                                return item
                            })
                        }
					}
				case status.SUCCESS:
					return {
						...state,
						answerPollStatus: status.SUCCESS,
						cachedPollAnswers: [ 
							...state.cachedPollAnswers.filter(answer => answer.thread !== action.meta.params.threadUid), 
							action.payload 
						]
					}
				case status.ERROR:
					return {
						...state,
						lastAnswerThreadUid: null,
						lastAnswerPollOptionUid: null,
						answerPollStatus: status.ERROR,
						cachedPollAnswers: state.cachedPollAnswers.filter(answer => answer.thread !== action.meta.params.threadUid && answer.poll_option !== action.meta.params.pollOptionUid)
					}
			}
		
		case types.DELETE_POLL_ANSWER:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						deletePollAnswerUid: action.meta.params.pollAnswerUid,
						deletePollAnswerStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						deletePollAnswerStatus: status.SUCCESS,
						myPollAnswers: state.myPollAnswers.filter(answer => answer.uid !== action.meta.params.pollAnswerUid),
						cachedPollAnswers: state.cachedPollAnswers.filter(answer => answer.uid !== action.meta.params.pollAnswerUid),
						threads: {
							...state.threads,
							[action.meta.params.threadUid]: {
								...state.threads[action.meta.params.threadUid],
								poll_options: state.threads[action.meta.params.threadUid]?.poll_options ? state.threads[action.meta.params.threadUid].poll_options.map(option => {
									if (option.uid === action.meta.params.removedPollOptionUid) {
										return {
											...option,
											answer_count: Math.max(option.answer_count - 1, 0)
										}
									}
									return option
								}) : null
							}
						},
						allThreads: {
                            ...state.allThreads,
                            data: state.allThreads.data.map(item => {
                                if (item.type === "thread" && item.uid === action.meta.params.threadUid) {
                                    return {
                                        ...item,
                                        poll_options: item.poll_options.map(option => {
                                            if (option.uid === action.meta.params.removedPollOptionUid) {
                                                return {
                                                    ...option,
                                                    answer_count: Math.max(option.answer_count - 1, 0)
                                                }
                                            }
                                            return option
                                        })
                                    }
                                }
                                return item
                            })
                        }
					}
				case status.ERROR:
					switch (action.meta.statusCode) {
						case 404:
							return {
								...state,
								deletePollAnswerStatus: status.SUCCESS,
								myPollAnswers: state.myPollAnswers.filter(answer => answer.uid !== action.meta.params.pollAnswerUid),
								cachedPollAnswers: state.cachedPollAnswers.filter(answer => answer.uid !== action.meta.params.pollAnswerUid),
								threads: {
									...state.threads,
									[action.meta.params.threadUid]: {
										...state.threads[action.meta.params.threadUid],
										poll_options: state.threads[action.meta.params.threadUid]?.poll_options ? state.threads[action.meta.params.threadUid].poll_options.map(option => {
											if (option.uid === action.meta.params.removedPollOptionUid) {
												return {
													...option,
													answer_count: Math.max(option.answer_count - 1, 0)
												}
											}
											return option
										}) : null
									}
								},
								allThreads: {
									...state.allThreads,
									data: state.allThreads.data.map(item => {
										if (item.type === "thread" && item.uid === action.meta.params.threadUid) {
											return {
												...item,
												poll_options: item.poll_options.map(option => {
													if (option.uid === action.meta.params.removedPollOptionUid) {
														return {
															...option,
															answer_count: Math.max(option.answer_count - 1, 0)
														}
													}
													return option
												})
											}
										}
										return item
									})
								}
							}
						default:
							return {
								...state,
								deletePollAnswerUid: action.meta.params.pollAnswerUid,
								deletePollAnswerStatus: status.ERROR,
							}
					}
			}
	
		default:
			return state;
	}
};

export default threads;
