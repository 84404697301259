import React from "react"
import ContentLoader from "react-content-loader"

export default function RoundLoader(props) {
    return(
        <ContentLoader 
        speed={2}
        width={props.size || 38}
        height={props.size || 38}
        backgroundColor="var(--lightGrey)"
        foregroundColor="var(--darkLightGrey)"
        {...props}
        >
            <circle cx={props.size / 2 || "19"} cy={props.size / 2 || "19"} r={props.size / 2 || "19"} />
        </ContentLoader>
    )
}