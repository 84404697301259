
export const checkShowPromo = (userId, userSplit, userSplitCount) => {
    if (userId) {
        if (userSplit === "all") {
            return true
        }
        else if (userSplit === "half") {
            return userId % 2 === userSplitCount
        }
        else if (userSplit === "quarter") {
            return userId % 4 === userSplitCount
        }
        else if (userSplit === "eighth") {
            return userId % 8 === userSplitCount
        }
        else if (userSplit === "sixteenth") {
            return userId % 16 === userSplitCount
        }
        else if (userSplit === "thirtysecond") {
            return userId % 32 === userSplitCount
        }
    }
    return true
}
