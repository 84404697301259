import React from 'react'
import styles from './icon.module.css'

const customIcons = [
    'loading',
    'apple',
    'android',
    'facebook',
    'facebook-alt',
    'twitter',
    'x',
    'x-filled',
    'artist',
    'news',
    'dice',
    'thumb',
    'list-number',
    'auto-fix-high',
    'person-insight-dark',
    'contribute',
    'lightbulb-alt',
    'microphone',
    'microphone-old',
    'guitar',
    'banjo',
    'trumpet',
    'saxophone',
    'gramophone',
    'keyboard',
    'masks',
    'drum',
    'boombox',
    'maracas',
    'glasses',
    'alternative',
    'remove-light',
    'check-circle-light',
    'rocket',
    'gift',
    'fa-ban',
    'fa-tools',
    'fa-image-landscape',
    'fa-badge',
    'fa-align-left',
    'fa-paintbrush',
    'fa-gif',
    'fa-icons',
    'fa-chart-simple',
    'fa-palette',
    'fa-ellipsis',
    'comments-disabled',
    'logout',
    'apple-login',
    'google-login',
    'spotify',
    'thumb-up-border',
]

export default function Icon({
    className="",
    icon="",
    size=24,
    color,
    style={},
    disableLoadingAnimation,
    onClick=()=>{}
}) {
    const iconName = icon.split("-").join("_")

    if (customIcons.indexOf(icon) !== -1) {
        return (
            <div
            className={`${styles.icon} ${styles[icon]} ${icon === "loading" ? styles.loading : ""} ${disableLoadingAnimation ? styles.disableLoadingAnimation : ""} ${className}`}
            style={{
                width: size, 
                height: size,
                minWidth: size,
                minHeight: size,
                ...color ? { backgroundColor: color } : {},
                ...style,
            }}
            onClick={(e) => onClick(e)}
            />
        )
    }

    return (
        <i 
        translate="no"
        className={`notranslate ${styles.materialIcons} ${icon === "loading" ? styles.loading : ""}  ${className} `} 
        style={{
            fontSize: size,
            ...color ? { color } : {},
            ...style
        }}
        onClick={(e) => onClick(e)}
        >
            {iconName}
        </i>
    )
}
