import styles from './albuminformationsection.module.css'

import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import moment from 'moment'

import OneLineLoader from '../../../../loaders/OneLineLoader'

import { secondsToFormattedTime } from '../../../../utils/format-time'
import { displayRecordType } from '../../../../utils/record-type-utils'

export default function AlbumInformationSection({ album }) {

    //Data
    const supportedLanguages = useSelector(state => state.auth.healthCheck.supported_languages)

    //Prepare for render
    const hasAnyData = (
        album.release_date ||
        Boolean(album.duration) ||
        album.languages?.length > 0 ||
        Boolean(album.label) ||
        album.explicit_lyrics ||
        album.genres?.length > 0 ||
        album.styles?.length > 0
    ) || !album.metadata_fetched;

    return (
        <div style={{ borderBottomLeftRadius: 8, borderBottomRightRadius: 8, overflow: 'hidden' }}>
            {hasAnyData &&
                <div>
                    {/* Release Date */}
                    <div className={`padding-around ${styles.container}`}>
                        <div className="grid">
                            <h5 className="black one-line" style={{ fontSize: 15 }}>Release Date</h5>
                        </div>
                        
                        {(album.metadata_fetched || album.release_date) &&
                            <p className="margin-top-4 highDarkGrey" style={{ fontSize: 15 }}>
                                {Boolean(album.release_date) ? 
                                    moment(album.release_date).format(album.release_date_precision === "year" ? "YYYY" : album.release_date_precision === "month" ? "MMMM YYYY" : "MMMM DD, YYYY")
                                : 
                                    <Link 
                                    className={styles.link}
                                    to={`/album/${album.id}/edit`}
                                    >
                                        {`Add a release date to this album...`}
                                    </Link>
                                }
                            </p>
                        }
                        
                        {!album.metadata_fetched && !album.release_date &&
                            <OneLineLoader width={130} height={20} y={7} className="margin-top-4" style={{ display: 'block' }} />
                        }
                    </div>
                    
                    {/* Duration */}
                    <div className={`padding-around ${styles.container}`}>
                        <div className="grid">
                            <h5 className="black one-line" style={{ fontSize: 15 }}>Duration</h5>
                        </div>
                        
                        {(album.metadata_fetched || album.duration) &&
                            <p className="margin-top-4 highDarkGrey" style={{ fontSize: 15 }}>
                                {Boolean(album.duration) && !album.duration.includes(":") ? 
                                    secondsToFormattedTime(album.duration)
                                : 
                                    <Link 
                                    className={styles.link}
                                    to={`/album/${album.id}/edit`}
                                    >
                                        {`How long is this release?`}
                                    </Link>
                                }
                            </p>
                        }

                        {!album.metadata_fetched && !album.duration &&
                            <OneLineLoader width={170} height={20} y={7} className="margin-top-4" style={{ display: 'block' }} />
                        }
                    </div>

                    {/* Country */}
                    {/*
                    <div className={`padding-around ${styles.container}`}>
                        <div className="grid">
                            <h5 className="black one-line" style={{ fontSize: 15 }}>Country</h5>
                        </div>
                        
                        {(album.metadata_fetched || album.country) &&
                            <p className="margin-top-4 highDarkGrey" style={{ fontSize: 15 }}>
                                {Boolean(album.country) ? 
                                    album.country
                                : 
                                    <Link 
                                    className={styles.link}
                                    to={`/album/${album.id}/edit`}
                                    >
                                        {`Contribute by adding a country...`}
                                    </Link>
                                }
                            </p>
                        }

                        {!album.metadata_fetched && !album.country &&
                            <OneLineLoader width={76} height={20} y={7} className="margin-top-4" style={{ display: 'block' }} />
                        }
                    </div>
                    */}

                    {album.languages?.length > 0 ? // Languages
                        <div className={`padding-around ${styles.container}`}>
                            <div className="grid">
                                <h5 className="black one-line" style={{ fontSize: 15 }}>Languages</h5>
                            </div>
                            
                            <p className="margin-top-4 highDarkGrey" style={{ fontSize: 15 }}>
                                {album.languages
                                .map((lang) => 
                                    supportedLanguages.find((l) => l.code === lang) ? supportedLanguages.find((l) => l.code === lang).lang : ""
                                )
                                .filter(i => i !== "")
                                .join(", ")}
                            </p>
                        </div>
                    : null}

                    {Boolean(album.label) && // Label
                        <div className={`padding-around ${styles.container}`}>
                            <div className="grid">
                                <h5 className="black one-line" style={{ fontSize: 15 }}>Label</h5>
                            </div>
                            
                            <p className="margin-top-4 highDarkGrey" style={{ fontSize: 15 }}>
                                {album.label}
                            </p>
                        </div>
                    }

                    {/*Explicit Lyrics */}
                    <div className={`padding-around ${styles.container}`}>
                        <div className="grid">
                            <h5 className="black one-line" style={{ fontSize: 15 }}>Explicit Lyrics</h5>
                        </div>
                        
                        {(album.metadata_fetched || album.explicit_lyrics !== null) &&
                            <p className="margin-top-4 highDarkGrey" style={{ fontSize: 15 }}>
                                {album.explicit_lyrics ? "Yes" : "No"}
                            </p>
                        }

                        {!album.metadata_fetched && album.explicit_lyrics === null &&
                            <OneLineLoader width={32} height={20} y={7} className="margin-top-4" style={{ display: 'block' }} />
                        }
                    </div>

                    {/* Genres */}
                    <div className={`padding-around ${styles.container}`}>
                        <div className="grid">
                            <h5 className="black one-line" style={{ fontSize: 15 }}>Genres</h5>
                        </div>
                        
                        {(album.metadata_fetched || album.genres?.length > 0) &&
                            <p className="margin-top-4 highDarkGrey" style={{ fontSize: 15 }}>
                                {album.genres?.length > 0 ? 
                                    album.genres.map((genre, index) => (
                                        <span>
                                            <Link 
                                            to={`/albums?genre=${genre.id}`}
                                            className={styles.link}
                                            >
                                                {genre.name}
                                            </Link>
                                            {index !== album.genres.length-1 && ", "}
                                        </span>
                                    ))
                                : 
                                    <Link 
                                    className={styles.link}
                                    to={`/album/${album.id}/edit`}
                                    >
                                        {`Add genres to this ${displayRecordType(album.record_type, true)}...`}
                                    </Link>
                                }
                            </p>
                        }

                        {!album.metadata_fetched && !album.genres?.length > 0 &&
                            <OneLineLoader width={170} height={20} y={7} className="margin-top-4" style={{ display: 'block' }} />
                        }
                    </div>

                    {/* Styles */}
                    <div className={`padding-around ${styles.container}`}>
                        <div className="grid">
                            <h5 className="black one-line" style={{ fontSize: 15 }}>Styles</h5>
                        </div>
                        
                        {(album.metadata_fetched || album.styles?.length > 0) &&
                            <p className="margin-top-4 highDarkGrey" style={{ fontSize: 15 }}>
                                {album.styles?.length > 0 ? 
                                    album.styles.map((style, index) => (
                                        <span>
                                            <Link 
                                            to={`/albums?style=${style.id}`}
                                            className={styles.link}
                                            >
                                                {style.name}
                                            </Link>
                                            {index !== album.styles.length-1 && ", "}
                                        </span>
                                    ))
                                : 
                                    <Link 
                                    className={styles.link}
                                    to={`/album/${album.id}/edit`}
                                    >
                                        {`Add styles to this ${displayRecordType(album.record_type, true)}...`}
                                    </Link>
                                }
                            </p>
                        }

                        {!album.metadata_fetched && !album.styles?.length > 0 &&
                            <OneLineLoader width={230} height={20} y={7} className="margin-top-4" style={{ display: 'block' }} />
                        }
                    </div>
                </div>
            }
        </div>
    )
}
