import moment from 'moment';
import { types, status } from '../constants/action-types';
import { errorToString } from '../utils/error'

const defaultChat = {
    groups: {
		data: [],
		count: null,
		next: null,
		status: status.DEFAULT,
	},

	allGroups: {},
	fetchGroupStatus: status.DEFAULT,

	groupIsTyping: {},
	
	lastAddGroupMemberUserUid: null,
	addGroupMemberStatus: status.DEFAULT,
	kickGroupMemberStatus: status.DEFAULT,
	leaveGroupStatus: status.DEFAULT,
	
	lastGetGroupWithUsersGroup: null,
	getGroupWithUsersStatus: status.DEFAULT,

	lastCreatedGroup: null,
	createGroupStatus: status.DEFAULT,
	
	updateGroupStatus: status.DEFAULT,
	createMessageStatus: status.DEFAULT,
	updateMessageStatus: status.DEFAULT,
	deleteMessageStatus: status.DEFAULT,

	groupSettings: [],

	errorMessage: null,
};

const chat = (state = defaultChat, action) => {
	const groupUid = action.meta && action.meta.params ? action.meta.params.groupUid : null;
	const groupObj = action.meta && action.meta.params ? action.meta.params.groupObj : null;
	const messageUid = action.meta && action.meta.params ? action.meta.params.messageUid : null;
	const userUid = action.meta && action.meta.params ? action.meta.params.userUid : null;
	const message = action.meta && action.meta.params ? action.meta.params.message : null;
	const tempUid = action.meta && action.meta.params ? action.meta.params.tempUid : null;
	const tempCreator = action.meta && action.meta.params ? action.meta.params.tempCreator : null;
	const is_reply = action.meta && action.meta.params ? action.meta.params.is_reply : null;
	const parent_read = action.meta && action.meta.params ? action.meta.params.parent_read : null;
	
	const groupSettingsUid = action.meta && action.meta.params ? action.meta.params.groupSettingsUid : null;
	const is_muted = action.meta && action.meta.params ? action.meta.params.is_muted : null;
	const accepted_eighteen_plus = action.meta && action.meta.params ? action.meta.params.accepted_eighteen_plus : null;
	const body = action.meta && action.meta.params ? action.meta.params.body : null;
	const originalBody = action.meta && action.meta.params ? action.meta.params.originalBody : null;

	const speedup = action.meta && action.meta.params ? action.meta.params.speedup : false
	const limit = action.meta && action.meta.params ? action.meta.params.limit : false

	switch (action.type) {
		case types.GET_GROUPS:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						groups: {
							...state.groups,
							next: null,
							reset: action.meta && action.meta.params ? action.meta.params.reset : false,
							status: status.BEGIN,
						},
					}
				case status.SUCCESS:
					return {
						...state,
						groups: {
							data: action.meta.params && action.meta.params.reset ? 
								[ ...state.groups.data.filter((group) => group.isNew && !group.latest_message), ...action.payload.results.filter((group) => Boolean(group.users_read.length > 1)) ]
							: 
								[ ...state.groups.data, ...action.payload.results.filter((group) => Boolean(group.users_read.length > 1)) ],
							next: speedup ? (action.payload.results.length < limit) ? null : action.payload.next : action.payload.next,
							status: status.SUCCESS,
						},
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
					}
			}

		case types.FETCH_GROUP:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						allGroups: {
							...state.allGroups,
							[groupUid]: {
								...state.allGroups[groupUid],
								...groupObj ? groupObj : state.allGroups[groupUid] ? state.allGroups[groupUid] : {}
							}
						},
						fetchGroupStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						allGroups: {
							...state.allGroups,
							[groupUid]: {
								...state.allGroups[groupUid],
								...action.payload
							}
						},
						fetchGroupStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						fetchGroupStatus: status.ERROR,
					}
			}
		
		case types.GET_MESSAGES:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						allGroups: {
							...state.allGroups,
							[groupUid]: {
								...state.allGroups[groupUid],
								messages: {
									...state.allGroups[groupUid] && state.allGroups[groupUid].messages,
									next: null,
									reset: action.meta && action.meta.params ? action.meta.params.reset : false,
									status: status.BEGIN,
								}
							}
						},
					}
				case status.SUCCESS:
					return {
						...state,
						allGroups: {
							...state.allGroups,
							[groupUid]: {
								...state.allGroups[groupUid],
								messages: {
									data: action.meta.params && action.meta.params.reset ? action.payload.results : [ ...state.allGroups[groupUid].messages.data, ...action.payload.results ],
									next: speedup ? (action.payload.results.length < limit) ? null : action.payload.next : action.payload.next,
									status: status.SUCCESS,
								}
							}
						},
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						allGroups: {
							...state.allGroups,
							[groupUid]: {
								...state.allGroups[groupUid],
								messages: {
									data: [],
									count: null,
									next: null,
									status: status.ERROR,
								}
							}
						},
					}
			}
		
		case types.REFETCH_MESSAGES:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						allGroups: {
							...state.allGroups,
							[groupUid]: {
								...state.allGroups[groupUid],
								refetchMessagesStatus: status.BEGIN,
							}
						},
					}
				case status.SUCCESS:
					const lastMessage = state.allGroups[groupUid].messages.data[0]
					const newMessages = (
						action.payload.results
						//Created after the latest message in our state
						.filter((m1) => !lastMessage || moment(m1.created_at).isAfter(lastMessage.created_at))
						//Is not is our array
						.filter((m1) => state.allGroups[groupUid].messages.data.findIndex((m2) => m1.uid === m2.uid) === -1)
						.map((m1) => ({
							...m1,
							isAnimated: true,
						}))
					);

					//Update groupIsTyping and set all of the creators of the new messages to null
					const groupIsTyping = {
						...state.groupIsTyping,
						[groupUid]: {
							...state.groupIsTyping[groupUid],
							...newMessages.map((m) => ({
								[m.creator.uid]: null
							}))
						}
					}

					return {
						...state,
						allGroups: {
							...state.allGroups,
							[groupUid]: {
								...state.allGroups[groupUid],
								refetchMessagesStatus: status.SUCCESS,
								hasNewMessages: state.allGroups[groupUid].hasNewMessages ? true : newMessages.length > 0,
								newMessagesCount: state.allGroups[groupUid].newMessagesCount > 0 ? state.allGroups[groupUid].newMessagesCount+newMessages.length : newMessages.length,
								messages: {
									...state.allGroups[groupUid].messages,
									data: [ ...newMessages, ...state.allGroups[groupUid].messages.data ],
								}
							}
						},
						groups: {
							...state.groups,
							data: newMessages.length <= 0 ? state.groups.data : state.groups.data.map((group) => {
								if (group.uid !== groupUid) return group;

								return ({
									...group,
									hasNewMessages: false,
									latest_message: newMessages[0]
								})
							})
						},
						groupIsTyping: groupIsTyping
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						allGroups: {
							...state.allGroups,
							[groupUid]: {
								...state.allGroups[groupUid],
								refetchMessagesStatus: status.ERROR,
							}
						},
					}
			}
		
		case types.CLEAR_NEW_MESSAGES:
			return {
				...state,
				allGroups: {
					...state.allGroups,
					[groupUid]: {
						...state.allGroups[groupUid],
						refetchMessagesStatus: status.DEFAULT,
						hasNewMessages: false,
						newMessagesCount: 0,
					}
				},
				groups: {
					...state.groups,
					data: state.groups.data.map((group) => {
						if (group.uid !== groupUid) return group;

						return ({
							...group,
							hasNewMessages: false
						})
					})
				}
			}
		
		case types.CREATE_MESSAGE:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						createMessageStatus: status.BEGIN,
						allGroups: message && tempUid ? {
							...state.allGroups,
							[groupUid]: !state.allGroups[groupUid] ? {} : {
								...state.allGroups[groupUid],
								messages: {
									...state.allGroups[groupUid] && state.allGroups[groupUid].messages,
									data: [
										{
											message: message,
											uid: tempUid,
											created_at: new Date().toISOString(),
											creator: tempCreator,
											is_reply: is_reply,
											parent_read: parent_read,
											isAnimated: true,
										}, 
										...state.allGroups[groupUid] && state.allGroups[groupUid].messages ? state.allGroups[groupUid].messages.data : []
									],
								}
							}
						} : state.allGroups
					}
				case status.SUCCESS:
					return {
						...state,
						createMessageStatus: status.SUCCESS,
						allGroups: {
							...state.allGroups,
							[groupUid]: !state.allGroups[groupUid] ? {} : {
								...state.allGroups[groupUid],
								messages: {
									...state.allGroups[groupUid] && state.allGroups[groupUid].messages,
									data: [
										action.payload, 
										...state.allGroups[groupUid] && state.allGroups[groupUid].messages && state.allGroups[groupUid].messages.data ? 
											message && tempUid ?
												state.allGroups[groupUid].messages.data.filter((m) => m.uid !== tempUid)
											:
												state.allGroups[groupUid].messages.data
										: []
									],
								}
							}
						},
						groups: {
							...state.groups,
							data: state.groups.data.map((group) => {
								if (group.uid !== groupUid) return group;
		
								return ({
									...group,
									hasNewMessages: false,
									latest_message: action.payload
								})
							})
						}
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						createMessageStatus: status.ERROR,
						allGroups: message && tempUid ? {
							...state.allGroups,
							[groupUid]: !state.allGroups[groupUid] ? {} : {
								...state.allGroups[groupUid],
								messages: {
									...state.allGroups[groupUid] && state.allGroups[groupUid].messages,
									data: state.allGroups[groupUid] && state.allGroups[groupUid].messages && state.allGroups[groupUid].messages.data ? 
										state.allGroups[groupUid].messages.data.filter((m) => m.uid !== tempUid)
									:
										[],
								}
							}
						} : state.allGroups
					}
			}

		case types.UPDATE_MESSAGE:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						updateMessageStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						updateMessageStatus: status.SUCCESS,
						allGroups: {
							...state.allGroups,
							[groupUid]: {
								...state.allGroups[groupUid],
								messages: {
									...state.allGroups[groupUid] && state.allGroups[groupUid].messages,
									data: state.allGroups[groupUid].messages.data.map((message) => {
										if (message.uid !== messageUid) {
											return message
										}

										return ({
											...message,
											message: action.payload.message
										})
									}),
								}
							}
						},
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						updateMessageStatus: status.ERROR,
					}
			}

		case types.DELETE_MESSAGE:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						deleteMessageStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						deleteMessageStatus: status.SUCCESS,
						allGroups: {
							...state.allGroups,
							[groupUid]: {
								...state.allGroups[groupUid],
								messages: {
									...state.allGroups[groupUid] && state.allGroups[groupUid].messages,
									data: state.allGroups[groupUid].messages.data.filter(message => message.uid !== messageUid),
								}
							}
						},
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						deleteMessageStatus: status.ERROR,
					}
			}

		case types.ADD_GROUP_MEMBER:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						addGroupMemberStatus: status.BEGIN,
						lastAddGroupMemberUserUid: userUid
					}
				case status.SUCCESS:
					return {
						...state,
						addGroupMemberStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						addGroupMemberStatus: status.ERROR,
					}
			}
		
		case types.KICK_GROUP_MEMBER:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						kickGroupMemberStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						kickGroupMemberStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						kickGroupMemberStatus: status.ERROR,
					}
			}
            
		case types.LEAVE_GROUP:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						leaveGroupStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						leaveGroupStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						leaveGroupStatus: status.ERROR,
					}
			}
            
		case types.UPDATE_GROUP:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						updateGroupStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						updateGroupStatus: status.SUCCESS,
						allGroups: {
							...state.allGroups,
							[groupUid]: {
								...state.allGroups[groupUid],
								...action.payload
							}
						},
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						updateGroupStatus: status.ERROR,
					}
			}

		case types.UPDATE_GROUP_INSTANT:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						updateGroupStatus: status.BEGIN,
						allGroups: {
							...state.allGroups,
							[groupUid]: {
								...state.allGroups[groupUid],
								...body
							}
						},
					}
				case status.SUCCESS:
					return {
						...state,
						updateGroupStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						updateGroupStatus: status.ERROR,
						allGroups: {
							...state.allGroups,
							[groupUid]: {
								...state.allGroups[groupUid],
								...originalBody
							}
						},
					}
			}
		
		case types.GET_GROUP_WITH_USERS:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						getGroupWithUsersStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						getGroupWithUsersStatus: status.SUCCESS,
						lastGetGroupWithUsersGroup: action.payload,
						groups: {
							...state.groups,
							data: [
								{
									...action.payload,
									isNew: true,
								},
								...state.groups.data.filter((group) => group.uid !== action.payload.uid)
							]
						}
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						getGroupWithUsersStatus: status.ERROR,
					}
			}
		
		case types.CREATE_GROUP:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						createGroupStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						createGroupStatus: status.SUCCESS,
						lastCreatedGroup: action.payload,
						groups: {
							...state.groups,
							data: [
								{
									...action.payload,
									isNew: true,
								},
								...state.groups.data
							]
						}
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						createGroupStatus: status.ERROR,
					}
			}

		case types.GET_IS_TYPING:
			switch (action.meta.status) {
				case status.BEGIN:
					return state
				case status.SUCCESS:
					return {
						...state,
						groupIsTyping: {
							...state.groupIsTyping,
							[action.payload.uid]: action.payload.is_typing
						}
					}
				case status.ERROR:
					return state
			}

		/* GROUP_SETTINGS */
		case types.FETCH_MY_GROUP_SETTINGS:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
					}
				case status.SUCCESS:
					return {
						...state,
						groupSettings: action.payload,
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
					}
			}
			
		case types.CREATE_GROUP_SETTINGS:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						setMutedStatus: status.BEGIN,
						groupSettings: [
							...state.groupSettings,
							{
								...body,
								uid: tempUid,
								group: groupUid,
								is_muted: is_muted,
								accepted_eighteen_plus: accepted_eighteen_plus,
							}
						]
					}
				case status.SUCCESS:
					return {
						...state,
						setMutedStatus: status.SUCCESS,
						groupSettings: [
							...state.groupSettings.filter((gs) => gs.uid !== tempUid),
							action.payload
						]
					}
				case status.ERROR:
					return {
						...state,
						setMutedStatus: status.ERROR,
						groupSettings: state.groupSettings.filter((gs) => gs.uid !== tempUid),
						errorMessage: errorToString(action.payload),
					}
			}

		case types.UPDATE_GROUP_SETTINGS:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						setMutedStatus: status.BEGIN,
						groupSettings: state.groupSettings.map((gs) => {
							if (gs.uid !== groupSettingsUid) return gs;

							return ({
								...gs,
								...body
							})
						})
					}
				case status.SUCCESS:
					return {
						...state,
						setMutedStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						setMutedStatus: status.ERROR,
						groupSettings: state.groupSettings.map((gs) => {
							if (gs.uid !== groupSettingsUid) return gs;

							return ({
								...gs,
								...originalBody
							})
						}),
						errorMessage: errorToString(action.payload),
					}
			}
		
		case types.LOG_OUT:
			return {
				...state,
				...defaultChat
			}
            
		default:
			return state;
	}
};

export default chat;