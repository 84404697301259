import { status, types } from '../constants/action-types'
import { post, put, get, patch, patchRaw } from '../utils/fetch'
import { url, deezerHostDomain, deezerHostUrl } from '../config'
import moment from 'moment';
import { getNormal } from '../utils/fetch-normal';
import { recordTypes } from '../constants/record-type-constants';

// Artist
export const fetchArtist = (id, fromDeezer, isRefetch=false) => 
    get(types.FETCH_ARTIST, `${url}/v2/${fromDeezer ? "dz_artists" : "artists"}/${id}/`, { id, fromDeezer, isRefetch });

export const fetchArtistFromSlug = (nameSlug) => 
    get(types.FETCH_ARTIST, `${url}/v2/artists/from_url_slug/?url_slug=/artist/${nameSlug}/`, { urlSlug: `/artist/${nameSlug}/` });

export const checkArtistDatabaseStatus = (urlSlug, deezerId, id) => 
    get(types.CHECK_ARTIST_DATABASE_STATUS, `${url}/v2/artists/check_status/${urlSlug ? `?url_slug=${urlSlug}` : deezerId ? `?deezer_id=${deezerId}` : `?id=${id}`}`, { urlSlug, deezerId, id });

export const getArtistRatingDistribution = (id) =>
    get(types.GET_ARTIST_RATING_DISTRIBUTION, `${url}/v2/artists/${id}/rating_distribution/`, { id });

export const getArtistLatestContributions = (id) => 
    get(types.GET_ARTIST_LATEST_CONTRIBUTIONS, `${url}/v2/artists/${id}/latest_contributions/`, { id });

export const getArtistReviews = ({
    id=null,
    order_by="-created_at",
    pinned="",
    title="",
    has_reports="",
    most_liked=false,
    scored=false,
    limit=16,
    offset=0,
    fromDeezer=false,
    type=types.GET_ARTISTS_REVIEWS,
}) => 
    get(type, `${url}/v2/${scored ? "most_liked_reviews/scored" : most_liked ? "most_liked_reviews" : "reviews"}/?content_type=artist&${fromDeezer ? "deezer_id" : "content_id"}=${id}&order_by=${order_by}&pinned=${pinned}&title=${title}&has_reports=${has_reports}&limit=${limit}&offset=${offset}&speedup=true`, { id, reset: true, speedup: true, limit: limit });

export const getArtistLists = ({
    id=null,
    order_by="-created_at",
    limit=12,
    offset=0,
    type=types.GET_ARTISTS_LISTS,
}) => 
    get(type, `${url}/v2/lists/content_lists/?content_ct=artist&content_id=${id}&order_by=${order_by}&limit=${limit}&offset=${offset}&speedup=true&limit_content=10`, { id, reset: true, speedup: true, limit: limit });

export const getArtistRatings = ({
    id=null,
    order_by="-created_at",
    following=false,
    limit=24,
    offset=0,
    fromDeezer=false,
}) => 
    get(types.GET_ARTISTS_RATINGS, `${url}/v2/ratings/${following ? "following/" : ""}?content_type=artist&${fromDeezer ? "deezer_id" : "content_id"}=${id}&order_by=${order_by}&limit=${limit}&offset=${offset}&speedup=true`, { id, reset: true, speedup: true, limit: limit });

export const getArtistTopTracks = (artistId) => 
    get(types.GET_ARTIST_TOP_TRACKS, `${url}/v2/tracks/?artist__id=${artistId}&order_by=-monthly_count&speedup=true&limit=5`, { id: artistId, reset: true });
    
// Album
export const fetchAlbum = (id, fromDeezer, isEdit, isRefetch=false) => 
    get(types.FETCH_ALBUM, `${url}/v2/${fromDeezer ? "dz_albums" : "albums"}/${id}/${isEdit ? "edit/" : ""}`, { id, fromDeezer, isEdit, isRefetch });

export const fetchAlbumFromSlug = (nameSlug, artistSlug) => 
    get(types.FETCH_ALBUM, `${url}/v2/albums/from_url_slug/?url_slug=/album/${nameSlug}/${artistSlug}/`, { urlSlug: `/album/${nameSlug}/${artistSlug}/` });

export const checkAlbumDatabaseStatus = (urlSlug, deezerId, id) => 
    get(types.CHECK_ALBUM_DATABASE_STATUS, `${url}/v2/albums/check_status/${urlSlug ? `?url_slug=${urlSlug}` : deezerId ? `?deezer_id=${deezerId}` : `?id=${id}`}`, { urlSlug, deezerId, id });

export const getAlbumRatingDistribution = (id) =>
    get(types.GET_ALBUM_RATING_DISTRIBUTION, `${url}/v2/albums/${id}/rating_distribution/`, { id });

export const getAlbumLatestContributions = (id) => 
    get(types.GET_ALBUM_LATEST_CONTRIBUTIONS, `${url}/v2/albums/${id}/latest_contributions/`, { id });

export const getAlbumBonusTracks = (id) => 
    get(types.GET_ALBUM_BONUS_TRACKS, `${url}/v2/albums/${id}/bonus_tracks/`, { id });

export const getAlbumReviews = ({
    id=null,
    order_by="-created_at",
    pinned="",
    title="",
    has_reports="",
    most_liked=false,
    scored=false,
    limit=16,
    offset=0,
    fromDeezer=false,
    type=types.GET_ALBUMS_REVIEWS,
}) => 
    get(type, `${url}/v2/${scored ? "most_liked_reviews/scored" : most_liked ? "most_liked_reviews" : "reviews"}/?content_type=album&${fromDeezer ? "deezer_id" : "content_id"}=${id}&order_by=${order_by}&pinned=${pinned}&title=${title}&has_reports=${has_reports}&limit=${limit}&offset=${offset}&speedup=true`, { id, reset: true, speedup: true, limit: limit });

export const getAlbumLists = ({
    id=null,
    order_by="-like_count",
    limit=12,
    offset=0,
    type=types.GET_ALBUMS_LISTS,
}) => 
    get(type, `${url}/v2/lists/content_lists/?content_ct=album&content_id=${id}&order_by=${order_by}&limit=${limit}&offset=${offset}&speedup=true&limit_content=10`, { id, reset: true, speedup: true, limit: limit });

export const getAlbumRatings = ({
    id=null,
    order_by="-created_at",
    following=false,
    limit=24,
    offset=0,
    fromDeezer=false,
}) => 
    get(types.GET_ALBUMS_RATINGS, `${url}/v2/ratings/${following ? "following/" : ""}?content_type=album&${fromDeezer ? "deezer_id" : "content_id"}=${id}&order_by=${order_by}&limit=${limit}&offset=${offset}&speedup=true`, { id, reset: true, speedup: true, limit: limit });

// Track
export const fetchTrack = (id, fromDeezer, isRefetch=false) => 
    get(types.FETCH_TRACK, `${url}/v2/${fromDeezer ? "dz_tracks" : "tracks"}/${id}/`, { id, fromDeezer, isRefetch });

export const fetchTrackFromSlug = (titleSlug, albumSlug, artistSlug) => 
    get(types.FETCH_TRACK, `${url}/v2/tracks/from_url_slug/?url_slug=/track/${titleSlug}/${albumSlug}/${artistSlug}/`, { urlSlug: `/track/${titleSlug}/${albumSlug}/${artistSlug}/` });

export const checkTrackDatabaseStatus = (urlSlug, deezerId, id) => 
    get(types.CHECK_TRACK_DATABASE_STATUS, `${url}/v2/tracks/check_status/${urlSlug ? `?url_slug=${urlSlug}` : deezerId ? `?deezer_id=${deezerId}` : `?id=${id}`}`, { urlSlug, deezerId, id });

export const getTrackRatingDistribution = (id) =>
    get(types.GET_TRACK_RATING_DISTRIBUTION, `${url}/v2/tracks/${id}/rating_distribution/`, { id });

export const fetchTracksForAlbum = (albumId, isDeezerIds) => 
    get(types.FETCH_MULTIPLE_TRACKS, `${url}/v2/tracks/?album__id=${albumId}&limit=200&speedup=true`, { albumId: albumId, isDeezerIds, speedup: true, limit: 200 });

export const fetchTracksForArtist = (ids="", isDeezerIds) => 
    get(types.FETCH_MULTIPLE_TRACKS, `${url}/v2/tracks/?${isDeezerIds ? "deezer_ids" : "ids"}=${ids}&limit=200&speedup=true`, { ids: ids, isDeezerIds, speedup: true, limit: 200 });

export const getTrackReviews = ({
    id=null,
    order_by="-created_at",
    pinned="",
    title="",
    has_reports="",
    most_liked=false,
    scored=false,
    limit=16,
    offset=0,
    fromDeezer=false,
    type=types.GET_TRACKS_REVIEWS,
}) => 
    get(type, `${url}/v2/${scored ? "most_liked_reviews/scored" : most_liked ? "most_liked_reviews" : "reviews"}/?content_type=track&${fromDeezer ? "deezer_id" : "content_id"}=${id}&order_by=${order_by}&pinned=${pinned}&title=${title}&has_reports=${has_reports}&limit=${limit}&offset=${offset}&speedup=true`, { id, reset: true, speedup: true, limit: limit });

export const getTrackRatings = ({
    id=null,
    order_by="-created_at",
    following=false,
    limit=24,
    offset=0,
    fromDeezer=false,
}) => 
    get(types.GET_TRACKS_RATINGS, `${url}/v2/ratings/${following ? "following/" : ""}?content_type=track&${fromDeezer ? "deezer_id" : "content_id"}=${id}&order_by=${order_by}&limit=${limit}&offset=${offset}&speedup=true`, { id, reset: true, speedup: true, limit: limit });

export const getTrackLists = ({
    id=null,
    order_by="-like_count",
    limit=12,
    offset=0,
    type=types.GET_TRACKS_LISTS,
}) => 
    get(type, `${url}/v2/lists/content_lists/?content_ct=track&content_id=${id}&order_by=${order_by}&limit=${limit}&offset=${offset}&speedup=true&limit_content=10`, { id, reset: true, speedup: true, limit: limit });
    
// Create content
export const createArtist = (id, fromDeezer) => post(types.CREATE_ARTIST, `${url}/v2/${fromDeezer ? "dz_artists" : "artists"}/`, { id }, { id, reset: true });

export const createAlbum = (id, fromDeezer) => post(types.CREATE_ALBUM, `${url}/v2/${fromDeezer ? "dz_albums" : "albums"}/`, { id }, { id, reset: true });

export const createTrack = (id, fromDeezer) => post(types.CREATE_TRACK, `${url}/v2/${fromDeezer ? "dz_tracks" : "tracks"}/`, { id }, { id, reset: true });

// User rating
export const getMyContentRating = (content_id=null, content_type=null, fromDeezer) => (dispatch, getState) => 
    dispatch(
        get(
            content_type === "artist" ? types.GET_MY_ARTIST_RATINGS : content_type === "album" ? types.GET_MY_ALBUM_RATINGS : types.GET_MY_TRACK_RATINGS, 
            `${url}/v2/ratings/mine/?creator=${getState().auth.uid}&${fromDeezer ? "deezer_id" :"content_id"}=${content_id}&content_type=${content_type}&order_by=-created_at&speedup=true`,
            { id: content_id, type: content_type, speedup: true, limit: 24, reset: true }
        )
    )

// Listing content
export const getTopContent = ({
    contentType=null,
    label="",
    genres__id="",
    album__genres__id="",
    styles__id="",
    album__styles__id="",
    release_date__year="",
    release_date__lte="",
    release_date__gte="",
    release_date="",
    record_type="",
    explicit_lyrics="",
    limit=24,
    offset=0,
}) => 
    get(
        contentType === "artist" ? types.GET_ALL_ARTISTS : contentType === "album" ? types.GET_ALL_ALBUMS : types.GET_ALL_TRACKS, 
        `${url}/v2/${contentType}s/top/?limit=${limit}&offset=${offset}&label=${label}&genres__id=${genres__id}&album__genres__id=${album__genres__id}&styles__id=${styles__id}&album__styles__id=${album__styles__id}&release_date__year=${release_date__year}&release_date__lte=${release_date__lte}&release_date__gte=${release_date__gte}&release_date=${release_date}&record_type=${record_type}&explicit_lyrics=${explicit_lyrics}&speedup=true`,
        { reset: true, speedup: true, limit: limit }
    )

export const getPopularContent = ({
    contentType=null,
    label="",
    genreIds=[],
    styleIds=[],
    release_date__year="",
    release_date__lte="",
    release_date__gte="",
    release_date="",
    record_type="",
    explicit_lyrics="",
    limit=24,
    offset=0,
    weekly=false,
    newReleases=false,
    type,
}) => 
    get(
        type ? type : contentType === "artist" ? types.GET_ALL_ARTISTS : contentType === "album" ? types.GET_ALL_ALBUMS : types.GET_ALL_TRACKS, 
        `${url}/v2/${contentType}s/${newReleases ? "new_releases" : weekly ? "popular_weekly" : "popular"}/?limit=${limit}&offset=${offset}&label=${label}${genreIds.map((id) => `&multi_genres=${id}`).join("")}${styleIds.map((id) => `&multi_styles=${id}`).join("")}&release_date__year=${release_date__year}&release_date__lte=${release_date__lte}&release_date__gte=${release_date__gte}&release_date=${release_date}&record_type=${record_type}&explicit_lyrics=${explicit_lyrics}&speedup=true`,
        { reset: true, speedup: true, limit: limit }
    )

// Custom fetching for an artist's discography
export const getArtistDiscography = (artistId, deezerId) => (dispatch, getState) => {
    // Send BEGIN to reducers
    dispatch({ type: types.GET_ARTIST_DISCOGRAPHY, meta: { status: status.BEGIN, params: { reset: true, id: artistId } } });

    // Make requests both to Deezer and our API
    Promise.all([
        getNormal(`${url}/v2/albums/?limit=1000&artist__id=${artistId}&speedup=true`),
        getNormal(`${url}/v2/artists/${artistId}/deleted_releases/`),
        //getNormal(`${url}/v2/albums/contributed/?limit=1000&artist__id=${artistId}&speedup=true`), 
        ...deezerId ? [getNormal(`${deezerHostUrl}/artist/${deezerId}/albums/?limit=1000`, 15000, null, null, null, true)] : []
    ])
    .then((responses) => {

        // Get individual responses
        const apiResponse = responses[0]
        const deletedReleasesResponse = responses[1]
        const deezerResponse = deezerId ? responses[2] : {data:[]}

        console.log("apiResponse", JSON.stringify(apiResponse))
        console.log("deezerResponse", JSON.stringify(deezerResponse))

        const backend_list = apiResponse.results
        const deleted_releases = deletedReleasesResponse
        const deezer_list = deezerResponse.data

        // - Remove all deezer list albums that are duplicate in backend list and that are not in the deleted releases list.
        const filtered_deezer_list = 
            deezer_list
            .filter(deezer_album => {
                if (backend_list.find(backend_album => backend_album.deezer_id === deezer_album.id)?.duplicate_of_id) {
                    return null;
                }
                if (deleted_releases.find(deleted_release => deleted_release.deezer_id === deezer_album.id)) {
                    return null;
                }
                return deezer_album;
            })
            .filter((i) => i !== null)

        // - Add in the backend items without deezer_id and not duplicate of.

        const backend_list_without_deezer_id = 
            backend_list
            .filter(backend_album => !backend_album.deezer_id)

        // - Add in the backend items that has deezer_id and that are not in the deezer list and not duplicate.

        const backend_list_not_in_deezer_list = 
            backend_list
            .filter(backend_album => backend_album.deezer_id)
            .filter(backend_album => !backend_album.duplicate_of_id)
            .filter(backend_album => !filtered_deezer_list.find(deezer_album => deezer_album.id === backend_album.deezer_id));

        // - Replace every single item with backend data, if possible.

        const merged_list = [...backend_list_without_deezer_id, ...backend_list_not_in_deezer_list, ...filtered_deezer_list];

        // - Add deezer_id prop to items that don't have it, before filtering unique.

        const items_with_deezer_id = merged_list.map(item => {
            if (!item.hasOwnProperty('deezer_id')) {
                return { ...item, deezer_id: item.id, is_deezer_list: true };
            }
            return item;
        });

        console.log("items_with_deezer_id", items_with_deezer_id);

        // - Filter the merged_list to only have unique.

        const unique_deezer_ids = items_with_deezer_id.filter((item, index, self) => 
            !item.deezer_id || index === self.findIndex(t => t.deezer_id === item.deezer_id)
        );

        console.log("unique_deezer_ids", unique_deezer_ids);

        // - Remove the deezer_id prop from items that have is_deezer_list = True

        const items_reversed = unique_deezer_ids.map(item => {
            if (item.is_deezer_list) {
                const { deezer_id, is_deezer_list, ...rest } = item;
                return { ...rest };
            }
            return item;
        });

        console.log("items_reversed", items_reversed);

        // - Replace every single item with backend data, if possible.

        const replaced_list = items_reversed.map(item => {
            if (item.hasOwnProperty('deezer_id')) {
                const backend_item = backend_list.find(backend_album => backend_album.id === item.id);
                if (backend_item) {
                    return backend_item;
                }
            }
            if (!item.hasOwnProperty('deezer_id')) {
                const backend_item = backend_list.find(backend_album => backend_album.deezer_id === item.id);
                if (backend_item) {
                    return backend_item;
                }
            }
            return item;
        });

        console.log("replaced_list", replaced_list);

        // - Order by record type first, then by release date second.
        // - The earlier in the recordTypes array, the higher the priority.
        // - Then the later release date, the higher the priority.

        /*
        const ordered_list = replaced_list.sort((a, b) => new Date(b.release_date) - new Date(a.release_date))
        */
        const ordered_list = replaced_list.sort((a, b) => {
            // First sort by record type priority
            const recordTypeIndexA = recordTypes.indexOf(a.record_type);
            const recordTypeIndexB = recordTypes.indexOf(b.record_type);
            
            if (recordTypeIndexA !== recordTypeIndexB) {
                return recordTypeIndexA - recordTypeIndexB;
            }
            
            // Then sort by release date (most recent first)
            return new Date(b.release_date) - new Date(a.release_date);
        });

        console.log("ordered_list", ordered_list);

        // Finished payload
        const payload = {
            results: ordered_list,
            next: null,
        }

        // Send SUCCESS to reducers
        dispatch({ type: types.GET_ARTIST_DISCOGRAPHY, meta: { status: status.SUCCESS, params: { reset: true, id: artistId } }, payload });
        
    })
    .catch((err) => {
        // Send ERROR to reducers
        dispatch({ type: types.GET_ARTIST_DISCOGRAPHY, meta: { status: status.ERROR, params: { reset: true, id: artistId } }, payload: err });
        console.warn(err)

    });
}