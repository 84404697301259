import { status, types } from '../constants/action-types'
import { get } from '../utils/fetch'
import { getNormal } from '../utils/fetch-normal'
import { corsUrl, url, apiUrl } from '../config'

//Featured
export const getFeaturedReviews = (order_by="priority") => 
    get(types.GET_FEATURED_REVIEWS, `${url}/v2/featured_reviews/`, { reset: true });

export const getFeaturedLists = (order_by="priority") => 
    get(types.GET_FEATURED_LISTS, `${url}/v2/featured_lists/`, { reset: true });

export const getDiscover = (order_by="priority") => 
    get(types.GET_DISCOVER, `${url}/v2/discover/`, { reset: true });

export const getPromos = () => 
    get(types.GET_PROMOS, `${url}/v2/promos/`, { reset: true });

//Friends
export const getFriendsReviews = ({
    order_by="-created_at",
    content_type="",
    genres__id="",
    styles__id="",
    release_date__year="",
    release_date__lte="",
    release_date__gte="",
    record_type="",
    offset=0,
}) => 
    get(types.GET_FRIENDS_REVIEWS, `${url}/v2/reviews/following/?order_by=${order_by}&content_type=${content_type}&genres__id=${genres__id}&styles__id=${styles__id}&release_date__year=${release_date__year}&release_date__lte=${release_date__lte}&release_date__gte=${release_date__gte}&record_type=${record_type}&speedup=true&limit=16&offset=${offset}`, { reset: true, speedup: true, limit: 16 });

export const getFriendsLists = (order_by="-created_at", offset=0) => 
    get(types.GET_FRIENDS_LISTS, `${url}/v2/lists/following/?limit_content=10&limit=12&offset=${offset}&order_by=${order_by}&speedup=true`, { reset: true, speedup: true, limit: 12 });

export const getFriendsRatings = ({
    order_by="-created_at",
    content_type="",
    limit=24,
    offset=0,
    genres__id="",
    styles__id="",
    release_date__year="",
    release_date__lte="",
    release_date__gte="",
    record_type="",
}) => 
    get(types.GET_FRIENDS_RATINGS, `${url}/v2/ratings/following/?order_by=${order_by}&content_type=${content_type}&limit=${limit}&offset=${offset}&genres__id=${genres__id}&styles__id=${styles__id}&release_date__year=${release_date__year}&release_date__lte=${release_date__lte}&release_date__gte=${release_date__gte}&record_type=${record_type}&speedup=true`, { reset: true, speedup: true, limit: 24 });

//Sections
export const getFeaturedSections = (order_by="priority") => 
    get(types.GET_FEATURED_SECTIONS, `${url}/v2/featured_lists/section/`, { reset: true });

//Albums
export const getNewReleases = () => 
    get(types.GET_NEW_RELEASES, `${url}/v2/albums/new_releases/?speedup=true`, { reset: true, speedup: true, limit: 24 });

//Timeline
export const getTimelineReviews = (offset=0) => (dispatch, getState) => {
    if (offset > 0) {
        dispatch(
            get(types.GET_TIMELINE_REVIEWS, `${url}/v2/most_liked_reviews/timeline/?limit=16&speedup=true&offset=${offset}`, { reset: true })
        )
    } else {
        dispatch({ type: types.GET_TIMELINE_REVIEWS, meta: { status: status.BEGIN, params: { reset: true } } });

        Promise.all([
            getNormal(`${url}/v2/most_liked_reviews/timeline/?limit=16&speedup=true&offset=${offset}`), 
            getNormal(`${url}/v2/featured_reviews/?speedup=true`),
            getNormal(`${url}/v2/featured_profiles/?speedup=true`),
            getNormal(`${url}/v2/threads/?speedup=true`),
        ])
        .then((responses) => {

            const reviewResponse = responses[0]
            const featuredReviewsResponse = responses[1]
            const featuredProfilesResponse = responses[2]
            const threadsResponse = responses[3]
            
            const results = (
                reviewResponse.results
                //Filter out if it exists in featuredReviewsResponse
                .filter((review) => !featuredReviewsResponse.results.find((featuredReview) => featuredReview.review.uid === review.uid))
                //Filter out if it exists in featuredProfilesResponse
                .filter((review) => !featuredProfilesResponse.results.find((featuredProfile) => featuredProfile.review.uid === review.uid))
            )

            featuredReviewsResponse.results.forEach(item => {
                results.splice(item.timeline_position, 0, { ...item.review, featured: true })
            })

            featuredProfilesResponse.results.forEach(item => {
                results.splice(item.timeline_position, 0, { ...item.review, creator: item.user, featuredProfile: true })
            })

            threadsResponse.results.forEach(item => {
                results.splice(item.timeline_position, 0, item)
            })

            const payload = {
                next: reviewResponse.next,
                results: results,
            }

            dispatch({ type: types.GET_TIMELINE_REVIEWS, meta: { status: status.SUCCESS, params: { reset: true } }, payload });

        })
        .catch((err) => {
            dispatch({ type: types.GET_TIMELINE_REVIEWS, meta: { status: status.ERROR, params: { reset: true } }, payload: err });
        });
    }
}

export const getTimelineLists = (offset=0) => (dispatch, getState) => {
    if (offset > 0) {
        dispatch(
            get(types.GET_TIMELINE_LISTS, `${url}/v2/most_liked_lists/timeline/?limit=16&offset=${offset}&speedup=true&limit_content=10`, { reset: true })
        )
    } else {
        dispatch({ type: types.GET_TIMELINE_LISTS, meta: { status: status.BEGIN, params: { reset: true } } });

        Promise.all([
            getNormal(`${url}/v2/most_liked_lists/timeline/?limit=16&offset=${offset}&speedup=true&limit_content=10`), 
            getNormal(`${url}/v2/featured_lists/?speedup=true`)
        ])
        .then((responses) => {

            const reviewResponse = responses[0]
            const featuredListsResponse = responses[1]
            
            const results = (
                reviewResponse.results
                //Filter out if it exists in featuredListsResponse
                .filter((review) => !featuredListsResponse.results.find((featuredList) => featuredList.list.uid === review.uid))
            )

            featuredListsResponse.results.forEach(item => {
                results.splice(item.timeline_position, 0, { ...item.list, featured: true })
            })

            const payload = {
                next: reviewResponse.next,
                results: results,
            }

            dispatch({ type: types.GET_TIMELINE_LISTS, meta: { status: status.SUCCESS, params: { reset: true } }, payload });

        })
        .catch((err) => {
            dispatch({ type: types.GET_TIMELINE_LISTS, meta: { status: status.ERROR, params: { reset: true } }, payload: err });
        });
    }
}

export const paginationForTimeline = (url, forLists) => (dispatch, getState) => {
    dispatch({ type: forLists ? types.GET_TIMELINE_LISTS : types.GET_TIMELINE_REVIEWS, meta: { status: status.BEGIN, params: {} } });

    getNormal(
        `${process.env.REACT_APP_STAGE === "development" ? `${corsUrl}/` : ""}${url.includes(":8000") ? url : url.replace("http://", "https://")}`
    )
    .then((response) => {

        const state = getState()

        const currentTimeline = forLists ? [ ...state.home.timelineLists.data ] : [ ...state.home.timelineReviews.data ]

        const filteredResults = response.results.filter((new_) => currentTimeline.findIndex((old_) => new_.uid === old_.uid) === -1)

        const payload = {
            ...response,
            results: filteredResults,
        }

        dispatch({ type: forLists ? types.GET_TIMELINE_LISTS : types.GET_TIMELINE_REVIEWS, meta: { status: status.SUCCESS, params: {} }, payload });

    })
    .catch((err) => {
        dispatch({ type: forLists ? types.GET_TIMELINE_LISTS : types.GET_TIMELINE_REVIEWS, meta: { status: status.ERROR, params: {} }, payload: err });

    });
}

//Users
export const getPopularUsers = (offset=0) => 
    get(types.GET_POPULAR_USERS, `${url}/v2/users/top/?speedup=true&offset=${offset}`, { reset: true });

export const getActiveUsers = (offset=0) => 
    get(types.GET_ACTIVE_USERS, `${url}/v2/most_active_users/?speedup=true&offset=${offset}`, { reset: true });

export const getSuggestedUsers = (limit=24, offset=0) => (dispatch, getState) => {
    dispatch({ type: types.GET_SUGGESTED_USERS, meta: { status: status.BEGIN, params: { reset: true, speedup: true, limit: limit } } });

    if (offset === 0) {
        Promise.all([
            getNormal(`${url}/v2/users/?username=musicboard&speedup=true`), 
            getNormal(`${url}/v2/users/suggested/?speedup=true&limit=${limit}&offset=${offset}`)
        ])
        .then((responses) => {
    
            const musicboardResponse = responses[0]
            const suggestedResponse = responses[1]
            
            const payload = {
                next: suggestedResponse.next,
                results: [
                    ...musicboardResponse.results.filter((_, i) => i < 1),
                    ...suggestedResponse.results,
                ],
            }
    
            dispatch({ type: types.GET_SUGGESTED_USERS, meta: { status: status.SUCCESS, params: { reset: true, speedup: true, limit: limit } }, payload });
    
        })
        .catch((err) => {
            dispatch({ type: types.GET_SUGGESTED_USERS, meta: { status: status.ERROR, params: { reset: true, speedup: true, limit: limit } }, payload: err });
    
        });
    } else {
        getNormal(`${url}/v2/users/suggested/?speedup=true&limit=${limit}&offset=${offset}`)
        .then((payload) => {
            dispatch({ type: types.GET_SUGGESTED_USERS, meta: { status: status.SUCCESS, params: { reset: true, speedup: true, limit: limit } }, payload });
        })
        .catch((err) => {
            dispatch({ type: types.GET_SUGGESTED_USERS, meta: { status: status.ERROR, params: { reset: true, speedup: true, limit: limit } }, payload: err });
        });
    }
}

export const getSuggestedUsersFromGenres = (genreIds) => (dispatch, getState) => {
    dispatch({ type: types.GET_SUGGESTED_USERS, meta: { status: status.BEGIN, params: { reset: true } } });

    Promise.all([
        getNormal(`${url}/v2/users/?username=musicboard&speedup=true`), 
        getNormal(`${url}/v2/users/suggested/?speedup=true&genres=${genreIds.join(",")}&limit=36`)
    ])
    .then((responses) => {

        const musicboardResponse = responses[0]
        const suggestedResponse = responses[1]

        //Only get unique users.
        const nonDuplicateResults = []
        const map = new Map();
        for (const user of suggestedResponse.results) {
            if(!map.has(user.uid)){
                map.set(user.uid, true);
                nonDuplicateResults.push(user);
            }
        }

        const randomOrderResults = (
            nonDuplicateResults
            .map(value => ({ value, sort: Math.random() }))
            .sort((a, b) => a.sort - b.sort)
            .map(({ value }) => value)
        )
        
        const payload = {
            next: `${url}/v2/users/suggested/?limit=24&offset=36&speedup=true`,
            results: [
                ...musicboardResponse.results.filter((_, i) => i < 1),
                ...randomOrderResults,
            ],
        }

        dispatch({ type: types.GET_SUGGESTED_USERS, meta: { status: status.SUCCESS, params: { reset: true } }, payload });

    })
    .catch((err) => {
        dispatch({ type: types.GET_SUGGESTED_USERS, meta: { status: status.ERROR, params: { reset: true } }, payload: err });

    });
}
