import React, { Suspense, lazy } from 'react'

import { Route, Switch, useRouteMatch } from "react-router-dom";

import Artist from '../../../../routes/Artist';

const UpdateArtist = lazy(() => import('../../../../routes/UpdateArtist'));
const CreateArtist = lazy(() => import('../../../../routes/CreateArtist'));
const ArtistContributions = lazy(() => import('../../../../routes/ArtistContributions'));

export default function ArtistNavigator(props) {
    const match = useRouteMatch()
    return (
        <Switch>
            <Route exact path={`${match.url}/new`} component={CreateArtist} />

            <Route exact path={`${match.url}/:id/edit`} component={UpdateArtist} />
            <Route exact path={`${match.url}/:id/contributions`} component={ArtistContributions} />

            <Route path={`${match.url}/:id`} component={Artist} />
        </Switch>
    )
}
