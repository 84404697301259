import { types, status } from '../constants/action-types';
import { errorToString } from '../utils/error'
import store from '../store'
import { handleNested, handleReducer } from '../utils/reducer-helpers';

/*
function getMe() {
	const me = store.getState().me;
	const uid = store.getState().auth.uid;

	return ({
		...me,
		uid,
	})
}
*/

const defaultUsers = {
	users: {},
	fetchUserStatus: status.DEFAULT,

	allUsers: {
		data: [],
		count: null,
		next: null,
		status: status.DEFAULT,
	},
	
	blockedUsers: {
		data: [],
		count: null,
		next: null,
		status: status.DEFAULT,
	},

	recommendedUsers: {
		data: [],
		count: null,
		next: null,
		status: status.DEFAULT,
	},

	lastUnfollowUserUid: null,
	followUserStatus: status.DEFAULT,
	followMultipleUsersStatus: status.DEFAULT,
	unfollowUserStatus: status.DEFAULT,

	lastBlockUserUid: null,
	lastUnblockUserUid: null,
	blockUserStatus: status.DEFAULT,
	unblockUserStatus: status.DEFAULT,

	getUserUidStatus: status.DEFAULT,
	userUids: {},

	getStatisticsStatus: status.DEFAULT,

	createFavoriteAlbumStatus: status.DEFAULT,
	lastCreatedFavoriteAlbumId: null,
	deleteFavoriteAlbumStatus: status.DEFAULT,
	lastDeletedFavoriteAlbumUid: null,
	updateFavoriteAlbumsStatus: status.DEFAULT,

	getRandomWantUserUid: null,
	getRandomWantResult: null,
	getRandomWantStatus: status.DEFAULT,

	errorMessage: null,
};

const users = (state = defaultUsers, action) => {
	let uid = action.meta && action.meta.params ? action.meta.params.uid : null
	let username = action.meta && action.meta.params ? action.meta.params.username : null
	let reset = action.meta && action.meta.params ? action.meta.params.reset : false

	switch (action.type) {
		case types.SET_INIT_USER:
			return {
				...state,
				users: {
					...state.users,
					[action.payload.username]: {
						...state.users[action.payload.username],
						...action.payload
					}
				},
				fetchUserStatus: status.SUCCESS,
			}

		//Get a specific user
		case types.FETCH_USER:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						fetchUserStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						users: {
							...state.users,
							[username]: {
								...state.users[username],
								...action.payload
							}
						},
						fetchUserStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						fetchUserStatus: status.ERROR,
					}
			}

		//When following a user
		case types.FOLLOW_USER:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						followUserStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						followUserStatus: status.SUCCESS,
					}
				case status.ERROR:
					if (!errorToString(action.payload).includes("Already followed user")) {
						return {
							...state,
							followUserStatus: status.ERROR,
							errorMessage: errorToString(action.payload),
						}
					} else {
						return {
							...state,
							followUserStatus: status.SUCCESS,
						}
					}
			}
		
		//When following many users at once
		case types.FOLLOW_MULTIPLE_USERS:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						followMultipleUsersStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						followMultipleUsersStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						followMultipleUsersStatus: status.ERROR,
						errorMessage: errorToString(action.payload),
					}
			}

		//When unfollowing a user
		case types.UNFOLLOW_USER:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						unfollowUserStatus: status.BEGIN,
						lastUnfollowUserUid: uid,
					}
				case status.SUCCESS:
					return {
						...state,
						unfollowUserStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						unfollowUserStatus: status.ERROR,
						lastUnfollowUserUid: null,
						errorMessage: errorToString(action.payload),
					}
			}

		//When blocking a user
		case types.BLOCK_USER:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						blockUserStatus: status.BEGIN,
						lastBlockUserUid: uid,
					}
				case status.SUCCESS:
					return {
						...state,
						blockUserStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						lastBlockUserUid: null,
						blockUserStatus: status.ERROR,
					}
			}

		//When unblocking a user
		case types.UNBLOCK_USER:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						unblockUserStatus: status.BEGIN,
						lastUnblockUserUid: uid,
					}
				case status.SUCCESS:
					return {
						...state,
						unblockUserStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						lastUnblockUserUid: null,
						unblockUserStatus: status.ERROR,
					}
			}

		//Get any users followers and update the users object
		case types.GET_FOLLOWERS:
			return handleNested(state, action, 'followers', 'users', username)

		//Get any users following users and update the users object
		case types.GET_FOLLOWING:
			return handleNested(state, action, 'following', 'users', username)

		//Get any users rated tracks and update the users object
		case types.GET_USERS_TRACK_RATINGS:
			return handleNested(state, action, 'tracks', 'users', username)

		//Get any users rated albums and update the users object
		case types.GET_USERS_ALBUM_RATINGS:
			return handleNested(state, action, 'albums', 'users', username)

		//Get any users rated artists and update the users object
		case types.GET_USERS_ARTIST_RATINGS:
			return handleNested(state, action, 'artists', 'users', username)

		//Get any users diary and update the users object
		case types.GET_USERS_DIARY:
			return handleNested(state, action, 'diary', 'users', username)

		//Get any users wants and update the users object
		case types.GET_WANTS:
			return handleNested(state, action, 'wants', 'users', username)
		
		//When getting a random want for a user
		case types.GET_RANDOM_WANT:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						getRandomWantStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						getRandomWantUserUid: uid,
						getRandomWantResult: action.payload,
						getRandomWantStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						getRandomWantUserUid: null,
						getRandomWantResult: null,
						getRandomWantStatus: status.ERROR,
					}
			}

		//Get any users lists and update the users object
		case types.GET_USERS_LISTS:
			return handleNested(state, action, 'lists', 'users', username)

		//Get any users reviews and update the users object
		case types.GET_USERS_REVIEWS:
			return handleNested(state, action, 'reviews', 'users', username)

		//Get any users reviews and update the users object
		case types.GET_USERS_RECENT_ACTIVITY:
			return handleNested(state, action, 'recentActivity', 'users', username)

		//Get any users reviews and update the users object
		case types.GET_USERS_PINNED_REVIEWS:
			return handleNested(state, action, 'pinnedReviews', 'users', username)

		//Get any users reviews and update the users object
		case types.GET_USERS_PINNED_LISTS:
			return handleNested(state, action, 'pinnedLists', 'users', username)
		
		//Get any users favorite albums and update the users object
		case types.GET_FAVORITE_ALBUMS:
			return handleNested(state, action, 'favoriteAlbums', 'users', username)

		//Get any users liked lists and update the users object
		case types.GET_USERS_SUGGESTED_USERS:
			return handleNested(state, action, 'suggestedUsers', 'users', username)

		//Get any users liked lists and update the users object
		case types.GET_USERS_SUGGESTED_CHANGES:
			return handleNested(state, action, 'suggestedChanges', 'users', username)

		//Get any users reviews and update the users object
		case types.GET_USER_YEAR_RATINGS:
			let content_type = action.meta && action.meta.params ? action.meta.params.content_type : null;
			let year = action.meta && action.meta.params ? action.meta.params.year : null;
			return handleNested(state, action, `${year}-${content_type}`, 'users', username)


		case types.GET_ALL_USERS:
			return handleReducer(state, action, 'allUsers')

		case types.GET_ALL_BLOCKED_USERS:
			return handleReducer(state, action, 'blockedUsers')

		case types.GET_RECOMMENDED_USERS:
			return handleReducer(state, action, 'recommendedUsers')

		case types.GET_USER_UID:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						getUserUidStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						getUserUidStatus: status.SUCCESS,
						userUids: {
							...state.userUids,
							[action.meta.params.username]: action.payload.uid,
						}
					}
				case status.ERROR:
					return {
						...state,
						getUserUidStatus: status.ERROR,
						userUids: {
							...state.userUids,
							[action.meta.params.username]: null,
						}
					}
			}

		case types.GET_USER_STATISTICS:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						getStatisticsStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						users: {
							...state.users,
							[username]: {
								...state.users[username],
								statistics: action.payload
							},
						},
						getStatisticsStatus: status.SUCCESS,
						errorMessage: null
					}
				case status.ERROR:
					return {
						...state,
						users: {
							...state.users,
							[username]: {
								...state.users[username],
								statistics: null
							},
						},
						getStatisticsStatus: status.ERROR,
						errorMessage: errorToString(action.payload)
					}
			}

		case types.GET_USER_FOLLOW_STATUS:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						getFollowStatusStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						users: {
							...state.users,
							[username]: {
								...state.users[username],
								follow_status: action.payload
							},
						},
						getFollowStatusStatus: status.SUCCESS,
						errorMessage: null
					}
				case status.ERROR:
					return {
						...state,
						users: {
							...state.users,
							[username]: {
								...state.users[username],
								follow_status: null
							},
						},
						getFollowStatusStatus: status.ERROR,
						errorMessage: errorToString(action.payload)
					}
			}

		case types.CREATE_FAVORITE_ALBUM:
			let album = action.meta && action.meta.params ? action.meta.params.album : null;
			let priority = action.meta && action.meta.params ? action.meta.params.album : null;
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						createFavoriteAlbumStatus: status.BEGIN,
						lastCreatedFavoriteAlbumId: album,
					}
				case status.SUCCESS:
					return {
						...state,
						createFavoriteAlbumStatus: status.SUCCESS,
						errorMessage: null
					}
				case status.ERROR:
					return {
						...state,
						lastCreatedFavoriteAlbumId: null,
						createFavoriteAlbumStatus: status.ERROR,
						errorMessage: errorToString(action.payload)
					}
			}

		case types.DELETE_FAVORITE_ALBUM:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						deleteFavoriteAlbumStatus: status.BEGIN,
						lastDeletedFavoriteAlbumUid: uid,
					}
				case status.SUCCESS:
					return {
						...state,
						deleteFavoriteAlbumStatus: status.SUCCESS,
						errorMessage: null
					}
				case status.ERROR:
					return {
						...state,
						lastDeletedFavoriteAlbumUid: null,
						deleteFavoriteAlbumStatus: status.ERROR,
						errorMessage: errorToString(action.payload)
					}
			}

		case types.UPDATE_FAVORITE_ALBUMS:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						updateFavoriteAlbumsStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						updateFavoriteAlbumsStatus: status.SUCCESS,
						errorMessage: null
					}
				case status.ERROR:
					return {
						...state,
						updateFavoriteAlbumsStatus: status.ERROR,
						errorMessage: errorToString(action.payload)
					}
			}
		
		default:
			return state;
	}
};

export default users;