import styles from './navbar.module.css'
import './navbar.module.css'

import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { Link, useLocation } from 'react-router-dom'

import { usePopup } from '../../hooks/popup/popup'
import { useScrollbar } from '../../hooks/scrollbar'

import IconButton from '../../components/IconButton'
import Button from '../../components/Button'
import Tooltip from '../../components/Tooltip'

import SearchBar from './components/SearchBar'
import ActivityDropdown from './components/ActivityDropdown'
import ProfileDropdown from './components/ProfileDropdown'
import MoreDropdown from './components/MoreDropdown'
import MenuDropdown from './components/MenuDropdown'
import MusicDropdown from './components/MusicDropdown'
import MobileSearchMenu from './components/MobileSearchMenu'
import MobileNavigationMenu from './components/MobileNavigationMenu'
import { useTheme } from '../../hooks/theme'

export default function NavBar({ className, style }) {
    //General
    const { mode } = useTheme()
    const { openPopup } = usePopup()
    const { setScrollDisabled, scrollDisabled, hasScrollbar } = useScrollbar()
    const location = useLocation()

    //Auth
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
    const isPro = useSelector(state => state.me.is_pro)
    const seenNews = useSelector(state => state.local.seenNews)

    //Data
    const groups = useSelector(state => state.local.groups);
    const hasNewMessages = groups ? groups.filter((group) => group.hasNewMessages).length > 0 : false;

    //Responsive
    const isSmallScreen = useMediaQuery({ maxWidth: 960 })
    const isSmallScreen2 = useMediaQuery({ maxWidth: 1050 })
    const isTablet = useMediaQuery({ maxWidth: 768 })
    const isTablet2 = useMediaQuery({ maxWidth: 700 })
    const isMobile = useMediaQuery({ maxWidth: 630 })
    const isMinimum = useMediaQuery({ maxWidth: 400 })
    const isMinimum2 = useMediaQuery({ maxWidth: 374 })
    
    //Search state
    const [searchActive, setSearchActive] = useState(false)
    const [menuActive, setMenuActive] = useState(false)

    //Clear state on change location
    useEffect(() => {
        setSearchActive(false)
        setMenuActive(false)
    }, [location])

    //Handle the from-app query param hide the navbar
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const fromApp = urlParams.get('from-app')

    const disabled = location.pathname.includes("/onboarding")

    if (fromApp && fromApp === "true") {
        return null
    }
    
    return (
        <div className={`${styles.flex} ${className}`} style={style}>
            {/* NAV- */}
            <div className={`${styles.outerContainer}`}>
                <div className={`${styles.container}`}>
                    <Link 
                    to="/"
                    onClick={() => {
                        if (location.pathname === "/") {
                            window.location.reload()
                        }
                    }}
                    >
                        <div className={`${styles.logo}`} />
                    </Link>

                    {!isSmallScreen &&
                        <SearchBar className="margin-left-8" visible={searchActive} setVisible={setSearchActive} />
                    }

                    {isSmallScreen &&
                        <IconButton 
                        className="margin-left-8" 
                        icon={searchActive ? "close" : "search"}
                        onClick={() => {
                            setSearchActive(!searchActive)
                            setMenuActive(false)
                        }} 
                        btnSize={40}
                        />
                    }

                    {(!isMobile && (!isTablet2 || isLoggedIn)) &&
                        <div className="col-1 flex-row">
                            {isLoggedIn &&
                            <>
                                <MusicDropdown className={"margin-left-8"} />

                                <Link to={"/reviews"}>
                                    <Button
                                    className={"margin-left-4"}
                                    height={35}
                                    style={{ borderRadius: isSmallScreen ? 100 : "var(--border-radius-small)" }}
                                    text="Reviews"
                                    textStyle={{ fontSize: 14 }}
                                    type="transparent"
                                    />
                                </Link>

                                <Link to={"/lists"}>
                                    <Button
                                    className={"margin-left-4"}
                                    height={35}
                                    style={{ borderRadius: isSmallScreen ? 100 : "var(--border-radius-small)" }}
                                    text="Lists"
                                    textStyle={{ fontSize: 14 }}
                                    type="transparent"
                                    />
                                </Link>
                            </>
                            }

                            {!isLoggedIn &&
                            <>
                                <Link to={"/explore/music"}>
                                    <Button
                                    className={"margin-left-8"}
                                    height={35}
                                    style={{ borderRadius: isSmallScreen ? 100 : "var(--border-radius-small)" }}
                                    text="Music"
                                    textStyle={{ fontSize: 14 }}
                                    type="transparent"
                                    />
                                </Link>

                                <Link to={"/explore/members"}>
                                    <Button
                                    className={"margin-left-4"}
                                    height={35}
                                    style={{ borderRadius: isSmallScreen ? 100 : "var(--border-radius-small)" }}
                                    text="Members"
                                    textStyle={{ fontSize: 14 }}
                                    type="transparent"
                                    />
                                </Link>

                                <Link to={"/explore/lists"}>
                                    <Button
                                    className={"margin-left-4"}
                                    height={35}
                                    style={{ borderRadius: isSmallScreen ? 100 : "var(--border-radius-small)" }}
                                    text="Lists"
                                    textStyle={{ fontSize: 14 }}
                                    type="transparent"
                                    />
                                </Link>
                            </>
                            }
                            
                            {(!isTablet2 && (!isTablet || isLoggedIn) && (!isSmallScreen2 || isLoggedIn || isSmallScreen)) &&
                                <Link to="/pro">
                                    <div className={`margin-left-4 flex-center`} style={{ position: 'relative' }}>
                                        <Button
                                        height={38}
                                        text="Pro"
                                        textStyle={{ fontSize: 14, color: "var(--blue)" }}
                                        type="transparent"
                                        />

                                        {!isPro &&
                                            <div className={styles.proShapeWrapper}>
                                                <div className={styles.proShape} />
                                            </div>
                                        }
                                    </div>
                                </Link>
                            }
                            
                            <MoreDropdown className={"margin-left-4"} />
                        </div>
                    }

                    {(isMobile || (isTablet2 && !isLoggedIn)) &&
                        <div className="col-1 flex-row">
                            <IconButton
                            className="margin-left-8"
                            icon={menuActive ? "close" : "reorder"}
                            onClick={() => {
                                setMenuActive(!menuActive)
                                setSearchActive(false)
                            }}
                            btnSize={40}
                            />
                        </div>
                    }

                    {isLoggedIn && 
                        <div className="flex-row">
                            <IconButton
                            icon="add"
                            className="margin-right-8"
                            onClick={() => openPopup("SearchContent", { popupTitle: "Create a Rating" })}
                            size={24}
                            btnSize={40}
                            />

                            <div style={{ position: 'relative' }} className="margin-right-8">
                                <Link to="/groups">
                                    <IconButton
                                    icon="forum"
                                    btnSize={40}
                                    />
                                </Link>

                                {hasNewMessages &&
                                    <div className={styles.dot} />
                                }
                            </div>

                            <ActivityDropdown className="margin-right-8" />
                            
                            <ProfileDropdown setSearchActive={setSearchActive} setMenuActive={setMenuActive} />
                        </div>
                    }

                    {!isLoggedIn &&
                        <div className="flex-row" style={{ justifyContent: 'flex-end' }}>
                            <Button
                            text="Log In"
                            textStyle={{ fontSize: 14, color: "var(--black)" }}
                            height={35}
                            type="secondary"
                            noShadow
                            className="margin-right-8"
                            onClicked={() => openPopup("Login")}
                            marginStyle={{
                                margin: isMinimum2 ? '0px 16px' : isMinimum ? '0px 20px' : '0px 24px'
                            }}
                            />
                            
                            <Button
                            text="Sign Up"
                            textStyle={{ fontSize: 14 }}
                            height={35}
                            onClicked={() => openPopup("SignUp")}
                            marginStyle={{
                                margin: isMinimum2 ? '0px 16px' : isMinimum ? '0px 20px' : '0px 24px'
                            }}
                            />
                        </div>
                    }
                </div>

                {disabled &&
                    <div className={styles.disabled} id="disabled-navbar-tip" />
                }

                <Tooltip
                place="bottom"
                reverseColor
                id="disabled-navbar-tip"
                text="Start by setting up your account"
                offset={12}
                />
            </div>
            
            {isSmallScreen && searchActive &&
                <MobileSearchMenu showingMenu={searchActive} setShowingMenu={setSearchActive} />
            }
            
            {(isMobile || (isTablet2 && !isLoggedIn)) && menuActive &&
                <MobileNavigationMenu showingMenu={menuActive} setShowingMenu={setMenuActive} />
            }

            <div style={{ height: mode === "dark" ? 57 : 56 }} />
        </div>
    )
}