import styles from './smallreleaseitem.module.css'

import React from 'react'
import AlbumCover from '../AlbumCover'
import Icon from '../Icon'
import { firstLetterCapital } from '../../utils/captialization'
import { Link } from 'react-router-dom'
import { displayRecordType } from '../../utils/record-type-utils'
import { checkDeezerId } from '../../utils/deezer-utils'

export default function SmallReleaseItem({
    textStyle={},
    release,
    className,
    lighterStyle,
    onClick,
    addId
}) {

    let href = checkDeezerId(release) ? `/album/${release.id}?dz` : release.url_slug

    return (
        <Link
        to={href}
        onClick={(e) => {
            if (onClick) {
                e.preventDefault()
                onClick(release)
            }
        }}
        >
            <div className={`${styles.container} ${lighterStyle ? styles.lighterStyle : ""} flex-row cursor ${className}`}>
                <AlbumCover clickable size={60} albumCover={release.cover_small || release.cover_medium || release.cover} altText={release.title} skipFrameTitle />
                
                <div className={`${styles.alignCenter} margin-left-16`}>
                    <div className="grid col-1">
                        <div className="flex-row">
                            <div className="grid">
                                <h6 className={`${styles.title} one-line`} style={{ ...textStyle, marginRight: 6 }} >{release.title}</h6>
                            </div>
                            
                            {release.explicit_lyrics &&
                                <Icon icon="explicit" className="margin-right-8" style={{ marginTop: 1 }} color="var(--grey)" size={14} />
                            }
                        </div>

                        <p className={`${styles.artist} margin-top-4`}>{release.record_type && displayRecordType(release.record_type)}{release.release_date && ` • ${release.release_date.substr(0,4)}`}</p>
                    </div>  
                </div>

                {addId &&
                    <div className="grid">
                        <p className="one-line highDarkGrey">ID: {release.id}</p>
                    </div>
                }
            </div>
        </Link>
    )
}
