import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import moment from 'moment'

import { status, types } from '../../constants/action-types'

import { getLatestRatings } from '../../actions/ratings-actions'
import { getPopularContent } from '../../actions/content-actions'
import { fetchMyPollAnswers } from '../../actions/threads-actions'
import { getAllPatchNotes } from '../../actions/administration-actions'
import { getProGifts, getProducts } from '../../actions/payments-actions'
import { fetchMyGroupSettings, getGroups } from '../../actions/chat-actions'
import { getFeaturedSections, getPromos, getSuggestedUsers } from '../../actions/home-actions'
import { getAllListDrafts, getAllReviewDrafts } from '../../actions/drafts-actions'
import { getFavoriteGenres, getGenres, getStyles } from '../../actions/genres-actions'
import { fetchMyLikes, fetchMyWants, fetchMyFollows, fetchMyBlocks, validatePayment, setMyPrimaryLanguage, fetchBlockedBy } from '../../actions/me-actions'

import { usePopup } from '../../hooks/popup/popup'

//Helper function to 
function checkTime(time, minuteOffset=10) {
    if (!time) {
        return false
    }

    return moment().diff(time, "minutes") > minuteOffset;
}

export default function GeneralFetching({ children }) {
    //General
    const dispatch = useDispatch()
    const { openPopup } = usePopup()
    const location = useLocation()

    //Auth
    const username = useSelector(state => state.me.username)
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
    
    const fetchMeStatus = useSelector(state => state.me.fetchMeStatus)
    const primary_language = useSelector(state => state.me.primary_language)

    useEffect(() => {
        if (isLoggedIn) {
            //Fetch Likes
            dispatch(
                fetchMyLikes()
            )
            //Fetch Poll Answers
            dispatch(
                fetchMyPollAnswers()
            )
            //Fetch group settings
            dispatch(
                fetchMyGroupSettings()
            )
            //Fetch wants
            dispatch(
                fetchMyWants()
            )
            //Fetch follows
            dispatch(
                fetchMyFollows()
            )
            //Fetch blocks
            dispatch(
                fetchMyBlocks()
            )
            dispatch(
                fetchBlockedBy()
            )

            //Fetch me
            dispatch(
                validatePayment()
            )
            dispatch(
                getProGifts()
            )

            //Get user's favorite genres
            dispatch(
                getFavoriteGenres()
            )

            //Fetch groups
            dispatch(
                getGroups()
            )

            //Fetch drafts
            dispatch(
                getAllReviewDrafts()
            )
            dispatch(
                getAllListDrafts()
            )

        }

    }, [ isLoggedIn ])
    
    const hasFetchedPrimaryLanguage = useRef(false)
    useEffect(() => {
        //Set language for user if he doesn't have it
        if (isLoggedIn && fetchMeStatus === status.SUCCESS && !primary_language && !hasFetchedPrimaryLanguage.current) {
            hasFetchedPrimaryLanguage.current = true;
        	dispatch(
        		setMyPrimaryLanguage()
        	)
        }
        
    }, [ isLoggedIn, fetchMeStatus ])
    
    //fetch ratingsCount
    const lastFetchedRatingsCount = useSelector(state => state.local.lastFetchedRatingsCount)

    
    useEffect(() => {
        dispatch({ type: types.INITIAL_LOAD })

        dispatch( 
            getProducts() 
        )

        dispatch(
            getGenres()
        )

        dispatch(
            getStyles()
        )

        dispatch( 
            getPromos() 
        )

        //Fetch patch notes
        dispatch(
            getAllPatchNotes()
        )

        //Fetch some landing page content
        dispatch(
            getFeaturedSections()
        )

        dispatch(
            getSuggestedUsers(64)
        )

        dispatch(
            getPopularContent({
                contentType: "album",
                weekly: true,
                type: types.GET_POPULAR_ALBUMS,
            })
        )

        dispatch(
            getLatestRatings()
        )
        
        // if (!lastFetchedRatingsCount || checkTime(lastFetchedRatingsCount, 15)) {
        //     dispatch(
        //         getRatingsCount()
        //     )
        // }
    }, [])

    //If they haven't selected favorite genres, open popup
    //Required data for above
    const firstOpen = useSelector(state => state.auth.firstOpen)
	const favoriteGenres = useSelector(state => state.genres.favoriteGenres)
	const getFavoriteGenresStatus = useSelector(state => state.genres.getFavoriteGenresStatus)
	const getGenresStatus = useSelector(state => state.genres.getGenresStatus)
    
	const firstUpdateFavoriteGenres = useRef(true)
	useEffect(() => {
		if (firstUpdateFavoriteGenres.current) {
			firstUpdateFavoriteGenres.current = false;
			return;
		}
		
        setTimeout(() => {
            //Open favorite genres
            if (
                firstOpen === false && 
                favoriteGenres &&
                favoriteGenres.length <= 0 &&
                getFavoriteGenresStatus === status.SUCCESS &&
                getGenresStatus === status.SUCCESS &&
                isLoggedIn &&
                (location.pathname === "/" || location.pathname.includes(`/${username}`))
            ) {
                openPopup("FavoriteGenres")
            }
        }, 200)

	}, [ getFavoriteGenresStatus, getGenresStatus ])


    return (
        children
    )
}
