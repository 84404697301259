import { types } from '../constants/action-types'
import { post, put, get, postRaw, delete_, patch, patchRaw } from '../utils/fetch'
import { url } from '../config'
import { v4 } from 'uuid'

//groups
export const getGroups = () => (dispatch, getState) => {
    dispatch(
        get(types.GET_GROUPS, `${url}/v2/groups/?speedup=true&limit=16`, { reset: true, speedup: true, limit: 16, myUid: getState().auth.uid })
    )

    dispatch(
        get(types.GET_GROUPS_READ_STATUS, `${url}/v2/groups/read_status/?speedup=true&limit=250`, { reset: true, speedup: true, limit: 250, myUid: getState().auth.uid })
    )
};
export const fetchGroup = (groupUid, groupObj) => get(types.FETCH_GROUP, `${url}/v2/groups/${groupUid}/`, { groupUid, groupObj });

export const addGroupMember = (groupUid, userUid) => 
    post(types.ADD_GROUP_MEMBER, `${url}/v2/groups/${groupUid}/add_group_member/`, { user: userUid }, { groupUid, userUid });

export const kickGroupMember = (groupUid, userUid) => 
    post(types.KICK_GROUP_MEMBER, `${url}/v2/groups/${groupUid}/kick_group_member/`, { user: userUid }, { groupUid, userUid });

export const leaveGroup = (groupUid) => post(types.LEAVE_GROUP, `${url}/v2/groups/${groupUid}/leave_group/`, {}, { groupUid });

export const updateGroup = (groupUid, body) => patch(types.UPDATE_GROUP, `${url}/v2/groups/${groupUid}/`, body, { groupUid });
export const updateGroupInstant = (groupUid, body, originalBody) => patch(types.UPDATE_GROUP_INSTANT, `${url}/v2/groups/${groupUid}/`, body, { groupUid, originalBody, body });
export const updateGroupRaw = (groupUid, formData) => patchRaw(types.UPDATE_GROUP, `${url}/v2/groups/${groupUid}/`, formData, { groupUid });

export const getGroupWithUsers = (userUidArray, isPersonalChat) => 
    post(types.GET_GROUP_WITH_USERS, `${url}/v2/groups/get_group_with_users/`, { 
        users: userUidArray, 
        is_personal_chat: isPersonalChat 
    }, {});

export const createGroup = (name, picture, users_write, is_personal_chat) => 
    post(types.CREATE_GROUP, `${url}/v2/groups/`, {
        name, 
        picture, 
        users_write, 
        is_personal_chat
    }, {});

//messages
export const getMessages = (groupUid) => get(types.GET_MESSAGES, `${url}/v2/messages/?group=${groupUid}&limit_content=8&speedup=true&limit=24`, { reset: true, speedup: true, limit: 24, groupUid });

export const refetchMessages = (groupUid) => get(types.REFETCH_MESSAGES, `${url}/v2/messages/?group=${groupUid}&limit_content=8&speedup=true&limit=24`, { reset: true, speedup: true, limit: 24, groupUid });
export const clearNewMessages = (groupUid) => (dispatch, getState) => dispatch({ type: types.CLEAR_NEW_MESSAGES, meta: { params: { groupUid } } });

export const createMessage = ({
    groupUid, 
    message=null, 
    review_write=null, 
    rating_write=null, 
    list_write=null, 
    album_write=null, 
    artist_write=null, 
    track_write=null, 
    user_write=null, 
    thread_write=null,
    suggested_album_write=null,
    suggested_artist_write=null,
    suggested_album_change_write=null,
    suggested_artist_change_write=null,
    image=null,
    parent_write=null,
    parent_read,
    is_reply=false
}) => (dispatch, getState) => {
    const { auth, me } = getState()
    
    const creator = {
        uid: auth.uid,
        username: me.username,
        name: me.name,
        biography: me.biography,
        website: me.website,
        twitter: me.twitter,
        profile_picture: me.profile_picture,
        profile_picture_small: me.profile_picture_small,
        profile_picture_medium: me.profile_picture_medium,
        profile_picture_large: me.profile_picture_large,
        is_pro: me.is_pro,
        tier: me.tier,
        is_verified: me.is_verified
    }

    dispatch(
        post(types.CREATE_MESSAGE, `${url}/v2/messages/`, {
            group: groupUid,
            message,
            review_write,
            rating_write,
            list_write,
            album_write,
            artist_write,
            track_write,
            user_write,
            thread_write,
            suggested_album_write,
            suggested_artist_write,
            suggested_album_change_write,
            suggested_artist_change_write,
            image,
            parent_write,
            is_reply,
        }, {
            groupUid,
            message,
            tempUid: v4(),
            tempCreator: creator,
            is_reply,
            parent_read
        })
    )
};

export const updateMessage = (messageUid, groupUid, body) => patch(types.UPDATE_MESSAGE, `${url}/v2/messages/${messageUid}/`, body, { messageUid, groupUid });

export const createMessageRaw = (formData, groupUid) => postRaw(types.CREATE_MESSAGE, `${url}/v2/messages/`, formData, {}, { groupUid });

export const deleteMessage = (messageUid, groupUid) => delete_(types.DELETE_MESSAGE, `${url}/v2/messages/${messageUid}/`, { messageUid, groupUid });


// Group settings
export const fetchMyGroupSettings = () => get(types.FETCH_MY_GROUP_SETTINGS, `${url}/v2/group_settings/`);

export const updateGroupSettings = (groupSettingsUid, body, originalBody) => 
    patch(types.UPDATE_GROUP_SETTINGS, `${url}/v2/group_settings/${groupSettingsUid}/`, body, { groupSettingsUid, body, originalBody });

export const createGroupSettings = (groupUid, is_muted, accepted_eighteen_plus) => 
    post(types.CREATE_GROUP_SETTINGS, `${url}/v2/group_settings/`, { group: groupUid, is_muted, accepted_eighteen_plus }, { groupUid, is_muted, tempUid: v4() });

//Is typing
export const startIsTyping = (groupUid) => 
    post(types.SET_IS_TYPING, `${url}/v2/groups/${groupUid}/set_is_typing/`, {}, { groupUid });

export const stopIsTyping = (groupUid) => 
    post(types.STOP_IS_TYPING, `${url}/v2/groups/${groupUid}/stop_is_typing/`, {}, { groupUid });

export const getIsTyping = (groupUid) => 
    get(types.GET_IS_TYPING, `${url}/v2/groups/${groupUid}/get_is_typing/`, { groupUid });