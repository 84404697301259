import styles from './readmoretext.module.css'

import React, { useState } from 'react'

export default function ReadMoreText({
    children,
    height=216,
    disabled,
    className="",
    bottomOffset=24,
    fontSize=16
}) {

    const [open, setOpen] = useState(false)

    if (disabled) {
        return (
            <div className={className}>
                {children}
            </div>
        )
    }

    return (
        <div
        className={`${styles.container} ${open ? styles.open : ""} ${className}`}
        style={{height: open ? "initial" : height}}
        >
            {children}

            {!open &&
                <div className={styles.fade} />
            }

            {!open &&
                <p 
                onClick={() => setOpen(!open)} 
                className={`${styles.btn} ${open ? styles.open : ""}`}
                style={{ marginBottom: bottomOffset*-1, paddingBottom: bottomOffset, fontSize: fontSize }}
                >
                    {open ? "Close" : "Read more"}
                </p>
            }
        </div>
    )
}
