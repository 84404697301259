import './assets/css/normalize.css';
import './assets/css/fonts.css';
import './assets/css/style.css';
import './assets/css/date-picker-overrides.css';

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Provider, useSelector } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import Cookies from 'js-cookie';

import { store, persistor } from './store';

import { RampInit } from './components/RampUnits';

import Segment from './shared/Segment';
import Navigator from './shared/Navigator';
import CacheData from './shared/CacheData';
import AuthWrapper from './shared/AuthWrapper';
import HealthCheck from './shared/HealthCheck';
import ScrollToTop from './shared/ScrollToTop';
import ErrorHandler from './shared/ErrorHandler';
import GeneralFetching from './shared/GeneralFetching';
import StatusAlertHandler from './shared/StatusAlertHandler';

import ThemeProvider from './hooks/theme';
import PopupProvider from './hooks/popup/popup';
import AlertProvider from './hooks/alert/alert';
import AudioProvider from './hooks/audio/audio';
import ScrollbarProvider from './hooks/scrollbar';
import PlayVideoProvider from './hooks/play-video/play-video';

if (process.env.REACT_APP_STAGE === 'production') {
	console.log = console.info = console.error = console.warn = console.debug = console.trace = () => {};
}

function RampComp() {
	//General
	const location = useLocation();

	//Send new page view event on location.pathname change
	useEffect(() => {
		if (window.PageOS?.session?.newPageView) {
			window.PageOS.session.newPageView();
		}
	}, [location.pathname]);
	
	//Params
	const showAds = true; // Cookies.get("Show-Test-Ads") === "true"

	//Auth
	const allow_web_ads = useSelector(state => state.auth.healthCheck.allow_web_ads)
	const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
	const userEmail = useSelector(state => state.me.email);
	const isPro = useSelector(state => state.me.is_pro);

	if (!allow_web_ads) {
        return null
    }

	if (isLoggedIn && isPro) {
		return null;
	}

	if (!showAds) {
		return null;
	}

	return (
		<RampInit 
		publisherId={"1025399"} 
		id={"75265"} 
		forcePath={location.pathname}
		isLoggedIn={isLoggedIn}
		userEmail={userEmail}
		/>
	)
}

function BottomRailSpacer() {
	const [height, setHeight] = useState(0);

	useEffect(() => {
		// Set up resize observer to watch for height changes
		const resizeObserver = new ResizeObserver(entries => {
			const entry = entries[0];
			if (entry) {
				setHeight(entry.contentRect.height);
			}
		});

		// Set up mutation observer to watch for element being added/removed
		const mutationObserver = new MutationObserver((mutations) => {
			mutations.forEach((mutation) => {
				if (mutation.type === 'childList') {
					const element = document.getElementById('pw-oop-bottom_rail');
					if (element) {
						resizeObserver.observe(element);
					} else {
						setHeight(0);
					}
				}
			});
		});

		// Start observing DOM changes
		mutationObserver.observe(document.body, {
			childList: true,
			subtree: true
		});

		// Check if element exists initially
		const element = document.getElementById('pw-oop-bottom_rail');
		if (element) {
			resizeObserver.observe(element);
		}

		// Cleanup
		return () => {
			resizeObserver.disconnect();
			mutationObserver.disconnect();
		};
	}, []);

	return <div style={{ height }} />;
}

function App() {
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<GoogleOAuthProvider clientId="312560736458-88o2l36f9sg2bhd4udnnt0c75s4sqtmj.apps.googleusercontent.com">
					<ThemeProvider>
						<ScrollbarProvider>
							<ErrorHandler>
								<PlayVideoProvider>
									<Router>
										<CacheData>
											<ScrollToTop>
												<AlertProvider>
													<PopupProvider>
														<AudioProvider>
															<HealthCheck>	
																<StatusAlertHandler>
																	<Segment>
																		<AuthWrapper>
																			<GeneralFetching>
																				<RampComp />

																				<Navigator />
																				
																				<BottomRailSpacer />
																			</GeneralFetching>
																		</AuthWrapper>
																	</Segment>
																</StatusAlertHandler>
															</HealthCheck>
														</AudioProvider>
													</PopupProvider>
												</AlertProvider>
											</ScrollToTop>
										</CacheData>
									</Router>
								</PlayVideoProvider>
							</ErrorHandler>
						</ScrollbarProvider>
					</ThemeProvider>
				</GoogleOAuthProvider>
			</PersistGate>
		</Provider>
	);
}

export default App;
