import { types, status } from '../constants/action-types';
import { errorToString } from '../utils/error'

const defaultMe = {
    username: null,
    name: null,
    email: null,
    profile_picture: null,
    profile_picture_small: null,
    profile_picture_medium: null,
    profile_picture_large: null,
    is_verified: null,
    is_pro: null,
    tier: null,
    is_moderator: null,
    is_user_moderator: null,
    is_content_moderator: null,
    is_database_moderator: null,
    contributor_tier: null,
	payment_method: null,
    biography: null,
    website: null,
	twitter: null,
    counts: null,
	rating_counts: null,
	background: null,
	profile_frame: null,
	profile_effect: null,
	color_theme: null,
	use_theme_on_content: null,

	primary_language: null,
	direct_messages_privacy: null,
	streaming_service: null,
    
	fetchMeStatus: status.DEFAULT,
	deleteAccountStatus: status.DEFAULT,
	lastFetchedMe: null,

	completed_onboarding: null,

	wants: [],
	cachedWants: [],
	lastFetchedWants: null,
	createWantStatus: status.DEFAULT,
	lastCreatedWantContentId: null,
	deleteWantStatus: status.DEFAULT,
	lastDeletedWantContentId: null,

	follows: [],
	cachedFollows: [],
	lastFetchedFollows: null,
	
	blocks: [],
	cachedBlocks: [],
	blockedBy: [],
	lastFetchedBlocks: null,
	
	likes: [],
	cachedLikes: [],
	lastFetchedLikes: null,

	banners: {
		data: [],
		status: status.DEFAULT,
		next: null,
		count: 0,
		reset: null,
	},
	lastCreatedBanner: null,
	createBannerStatus: status.DEFAULT,
	deleteBannerStatus: status.DEFAULT,
	updateBannerStatus: status.DEFAULT,

	updatePinnedContentStatus: status.DEFAULT,

	sendDeviceTokenStatus: status.DEFAULT,

	resetFlatLists: null,
	
	errorMessage: null,
};

const me = (state = defaultMe, action) => {
	switch (action.type) {
		case types.LOG_IN:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
					}
				case status.SUCCESS:
					return {
						...state,
						username: action.payload.username,
                        name: action.payload.name,
                        email: action.payload.email,
                        profile_picture: action.payload.profile_picture,
                        profile_picture_small: action.payload.profile_picture_small,
                        profile_picture_medium: action.payload.profile_picture_medium,
                        profile_picture_large: action.payload.profile_picture_large,
                        is_verified: action.payload.is_verified,
                        is_pro: action.payload.is_pro,
                        tier: action.payload.tier,
                        is_moderator: action.payload.is_moderator,
                        is_user_moderator: action.payload.is_user_moderator,
                        is_content_moderator: action.payload.is_content_moderator,
                        is_database_moderator: action.payload.is_database_moderator,
                        contributor_tier: action.payload.contributor_tier,
						payment_method: action.payload.payment_method,
                        biography: action.payload.biography,
                        website: action.payload.website,
						twitter: action.payload.twitter,
						profile_frame: action.payload.profile_frame,
						profile_effect: action.payload.profile_effect,
						color_theme: action.payload.color_theme,
						use_theme_on_content: action.payload.use_theme_on_content,

						completed_onboarding: action.payload.completed_onboarding,
						primary_language: action.payload.primary_language,
						direct_messages_privacy: action.payload.direct_messages_privacy,
						streaming_service: action.payload.streaming_service,
                        
						errorMessage: null,
					}
				case status.ERROR:
					return {
						...state,
                        ...defaultMe,
					}
			}
		case types.FETCH_ME:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
                        ...state,
                        fetchMeStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						username: action.payload.username,
                        name: action.payload.name,
                        email: action.payload.email ? action.payload.email : (action.meta && action.meta.params && action.meta.params.email) ? action.meta.params.email : state.email,
                        profile_picture: action.payload.profile_picture,
                        profile_picture_small: action.payload.profile_picture_small,
                        profile_picture_medium: action.payload.profile_picture_medium,
                        profile_picture_large: action.payload.profile_picture_large,
                        is_verified: action.payload.is_verified,
                        is_pro: action.payload.is_pro,
                        tier: action.payload.tier,
                        is_moderator: action.payload.is_moderator,
                        is_user_moderator: action.payload.is_user_moderator,
                        is_content_moderator: action.payload.is_content_moderator,
                        is_database_moderator: action.payload.is_database_moderator,
                        contributor_tier: action.payload.contributor_tier,
						payment_method: action.payload.payment_method,
                        biography: action.payload.biography,
                        website: action.payload.website,
						twitter: action.payload.twitter,
                        counts: action.payload.counts,
                        rating_counts: action.payload.rating_counts,
						
						background: action.payload.background,
						profile_frame: action.payload.profile_frame,
						profile_effect: action.payload.profile_effect,
						color_theme: action.payload.color_theme,
						use_theme_on_content: action.payload.use_theme_on_content,

						completed_onboarding: action.payload.completed_onboarding,
						primary_language: action.payload.primary_language,
						direct_messages_privacy: action.payload.direct_messages_privacy,
						streaming_service: action.payload.streaming_service,
                        
						fetchMeStatus: status.SUCCESS,
						lastFetchedMe: new Date(),
						errorMessage: null,
					}
				case status.ERROR:
					return {
                        ...state,
                        fetchMeStatus: status.ERROR,
                        errorMessage: errorToString(action.payload),
					}
			}

		/* FOLLOWS */
		case types.FETCH_MY_FOLLOWS:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
					}
				case status.SUCCESS:
					return {
						...state,
						follows: action.payload,
						cachedFollows: state.cachedFollows.filter(cached => action.payload.findIndex(follow => follow.uid === cached.uid) === -1),
						lastFetchedFollows: new Date()
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
					}
			}

		case types.FOLLOW_USER:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						cachedFollows: [ 
							...state.cachedFollows, 
							{
								followed_user: action.meta.params.uid,
							}
						]
					}
				case status.SUCCESS:
					return {
						...state,
						cachedFollows: [ 
							...state.cachedFollows.filter(follow => follow.followed_user !== action.meta.params.uid), 
							action.payload 
						]
					}
				case status.ERROR:
					if (!errorToString(action.payload).includes("Already followed user")) {
						return {
							...state,
							cachedFollows: state.cachedFollows.filter(follow => follow.followed_user !== action.meta.params.uid)
						}
					} else {
						return state
					}
			}
	
		case types.FOLLOW_MULTIPLE_USERS:
			let users = action.meta.params.users
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						cachedFollows: [ 
							...state.cachedFollows, 
							...users.map((user) => ({
								followed_user: user.uid,
							}))
						]
					}
				case status.SUCCESS:
					return {
						...state,
						cachedFollows: [ 
							...state.cachedFollows.filter(follow => users.findIndex((user) => follow.followed_user === user.uid) === -1), 
							...action.payload 
						]
					}
				case status.ERROR:
					return {
						...state,
						cachedFollows: state.cachedFollows.filter(follow => users.findIndex((user) => follow.followed_user === user.uid) === -1)
					}
			}
		
		case types.UNFOLLOW_USER:
			switch (action.meta.status) {
				case status.BEGIN:
                    return state
				case status.SUCCESS:
					return {
						...state,
						follows: state.follows.filter(follow => follow.uid !== action.meta.params.followUid),
						cachedFollows: state.cachedFollows.filter(follow => follow.uid !== action.meta.params.followUid),
					}
				case status.ERROR:
					switch (action.meta.statusCode) {
						case 404:
							return {
								...state,
								follows: state.follows.filter(follow => follow.uid !== action.meta.params.followUid),
								cachedFollows: state.cachedFollows.filter(follow => follow.uid !== action.meta.params.followUid),
							}
						default:
							return {
								...state,
							}
					}
			}
		
		/* WANTS */
		case types.FETCH_MY_WANTS:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
					}
				case status.SUCCESS:
					return {
						...state,
						wants: action.payload,
						cachedWants: state.cachedWants.filter(cached => action.payload.findIndex(want => want.uid === cached.uid) === -1),
						lastFetchedWants: new Date()
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
					}
			}

		case types.CREATE_WANT:
			const id = action.meta.params && action.meta.params.id;
			const fromDeezer = action.meta.params && action.meta.params.fromDeezer;
			let contentType = action.meta.params && action.meta.params.contentType;
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						createWantStatus: status.BEGIN,
						lastCreatedWantContentId: id,
						cachedWants: [ 
							...state.cachedWants,
							{
								content_id: id,
								content_ct: contentType,
								...fromDeezer ? { deezer_id: id } : {}
							}
						]
					}
				case status.SUCCESS:
					return {
						...state,
						cachedWants: [ 
							...state.cachedWants.filter(want => !(want.content_id === id && want.content_ct === contentType)),
							{
								...action.payload,
								...fromDeezer ? { deezer_id: id } : {}
							}
						],
						createWantStatus: status.SUCCESS,
						errorMessage: null,
					}
				case status.ERROR:
					return {
						...state,
						createWantStatus: status.ERROR,
						lastCreatedWantContentId: null,
						errorMessage: errorToString(action.payload),
					}
			}

		case types.DELETE_WANT:
			const uid = action.meta.params && action.meta.params.uid;
			switch (action.meta.status) {
				case status.BEGIN:
					let fullWantArr = [ ...state.wants, ...state.cachedWants ]
					return {
						...state,
						deleteWantStatus: status.BEGIN,
						lastDeletedWantContentId: fullWantArr[fullWantArr.findIndex(want => want.uid === uid)] ? fullWantArr[fullWantArr.findIndex(want => want.uid === uid)].content_id : null,
					}
				case status.SUCCESS:
					return {
						...state,
						wants: state.wants.filter(want => want.uid !== uid),
						cachedWants: state.cachedWants.filter(want => want.uid !== uid),
						deleteWantStatus: status.SUCCESS,
						errorMessage: null,
					}
				case status.ERROR:
					switch (action.meta.statusCode) {
						case 404:
							return {
								...state,
								wants: state.wants.filter(want => want.uid !== uid),
								cachedWants: state.cachedWants.filter(want => want.uid !== uid),
								deleteWantStatus: status.SUCCESS,
								errorMessage: null,
							}
						default:
							return {
								...state,
								deleteWantStatus: status.ERROR,
								lastDeletedWantContentId: null,
								errorMessage: errorToString(action.payload),
							}
					}
			}

		/* BLOCKS */
		case types.FETCH_MY_BLOCKS:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
					}
				case status.SUCCESS:
					return {
						...state,
						blocks: action.payload,
						cachedBlocks: state.cachedBlocks.filter(cached => action.payload.findIndex(block => block.uid === cached.uid) === -1),
						lastFetchedBlocks: new Date()
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
					}
			}
		
		case types.FETCH_BLOCKED_BY:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
					}
				case status.SUCCESS:
					return {
						...state,
						blockedBy: action.payload,
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
					}
			}

		case types.BLOCK_USER:
			switch (action.meta.status) {
				case status.BEGIN:
                    return state
				case status.SUCCESS:
					return {
						...state,
						follows: state.follows.filter(follow => follow.followed_user !== action.payload.blocked_user),
						cachedFollows: state.cachedFollows.filter(follow => follow.followed_user !== action.payload.blocked_user),
						cachedBlocks: [ ...state.cachedBlocks, action.payload ]
					}
				case status.ERROR:
					return state
			}
		case types.UNBLOCK_USER:
			switch (action.meta.status) {
				case status.BEGIN:
                    return state
				case status.SUCCESS:
					return {
						...state,
						blocks: state.blocks.filter(block => block.uid !== action.meta.params.blockUid),
						cachedBlocks: state.cachedBlocks.filter(block => block.uid !== action.meta.params.blockUid),
					}
				case status.ERROR:
                    return state
			}

		/* LIKES */
		case types.FETCH_MY_LIKES:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
					}
				case status.SUCCESS:
					return {
						...state,
						likes: action.payload,
						cachedLikes: state.cachedLikes.filter(cached => action.payload.findIndex(like => like.uid === cached.uid) === -1),
						lastFetchedLikes: new Date()
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
					}
			}

		case types.LIKE_CONTENT:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						cachedLikes: [ 
							...state.cachedLikes, 
							{
								content_uid: action.meta.params.uid,
								content_ct: action.meta.params.contentType,
							}
						]
					}
				case status.SUCCESS:
					return {
						...state,
						cachedLikes: [ 
							...state.cachedLikes.filter(like => like.content_uid !== action.meta.params.uid),
							action.payload 
						]
					}
				case status.ERROR:
					return {
						...state,
						cachedLikes: state.cachedLikes.filter(like => like.content_uid !== action.meta.params.uid && like.content_ct !== action.meta.params.contentType)
					}
			}
			
		case types.DELETE_LIKE:
			switch (action.meta.status) {
				case status.BEGIN:
					return state
				case status.SUCCESS:
					return {
						...state,
						likes: state.likes.filter(like => like.uid !== action.meta.params.likeUid),
						cachedLikes: state.cachedLikes.filter(like => like.uid !== action.meta.params.likeUid),
					}
				case status.ERROR:
					switch (action.meta.statusCode) {
						case 404:
							return {
								...state,
								likes: state.likes.filter(like => like.uid !== action.meta.params.likeUid),
								cachedLikes: state.cachedLikes.filter(like => like.uid !== action.meta.params.likeUid),
							}
						default:
							return state
					}
			}

		/* NOTIFICATIONS */
		case types.CREATE_APNS_DEVICE:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						sendDeviceTokenStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						sendDeviceTokenStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						sendDeviceTokenStatus: status.ERROR,
						errorMessage: errorToString(action.payload),
					}
			}
		case types.CREATE_FCM_DEVICE:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						sendDeviceTokenStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						sendDeviceTokenStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						sendDeviceTokenStatus: status.ERROR,
						errorMessage: errorToString(action.payload),
					}
			}

		/* BANNERS */
		case types.GET_BANNERS:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						banners: {
							...state.banners,
							data: state.banners && state.banners.data ? state.banners.data : [],
							count: state.banners && state.banners.count ? state.banners.count : 0,
							status: status.BEGIN,
							reset: action.meta && action.meta.params ? action.meta.params.reset : false,
							next: null,
						},
					}
				case status.SUCCESS:
					return {
						...state,
						banners: {
							...state.banners,
							data: action.meta && action.meta.params && action.meta.params.reset ? action.payload.results : [ ...state.banners.data, ...action.payload.results ],
							count: action.payload.count,
							status: status.SUCCESS,
							next: action.payload.next,
						},
					}
				case status.ERROR:
					return {
						...state,
						banners: {
							...state.banners,
							data: [],
							count: 0,
							status: status.ERROR,
							next: null,
							reset: null,
						},
						errorMessage: errorToString(action.payload),
					}
			}
		
		case types.DELETE_BANNER:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						deleteBannerStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						deleteBannerStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						deleteBannerStatus: status.ERROR,
						errorMessage: errorToString(action.payload),
					}
			}
		
		case types.CREATE_BANNER:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						createBannerStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						createBannerStatus: status.SUCCESS,
						lastCreatedBanner: action.payload,
					}
				case status.ERROR:
					return {
						...state,
						createBannerStatus: status.ERROR,
						errorMessage: errorToString(action.payload),
					}
			}
		
		case types.UPDATE_BANNER:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						updateBannerStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						updateBannerStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						updateBannerStatus: status.ERROR,
						errorMessage: errorToString(action.payload),
					}
			}

		/* EXTRA */
		case types.RESET_PROFILE_FLATLISTS:
			return {
				...state,
				resetFlatLists: new Date().toISOString()
			}

		//Pinned content
		case types.UPDATE_PINNED_CONTENT:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						updatePinnedContentStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						updatePinnedContentStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						updatePinnedContentStatus: status.ERROR,
						errorMessage: errorToString(action.payload),
					}
			}
		
		case types.LOG_OUT:
			return {
				...state,
				...defaultMe,
			}

		case types.DELETE_ACCOUNT:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
                        ...state,
                        deleteAccountStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						deleteAccountStatus: status.SUCCESS,
						errorMessage: null,
					}
				case status.ERROR:
					return {
                        ...state,
                        deleteAccountStatus: status.ERROR,
                        errorMessage: errorToString(action.payload),
					}
			}

		default:
			return state;
	}
};

export default me;