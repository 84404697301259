import styles from './artist.module.css'

import React, { Suspense, lazy, useState, useEffect, useRef } from 'react'
import { useHistory, Route, Switch, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import ReactHtmlParser from 'react-html-parser';

import { status } from '../../constants/action-types';

import { deleteWant, createWant } from '../../actions/me-actions';
import { checkArtistDatabaseStatus, fetchArtist, fetchArtistFromSlug, getMyContentRating } from '../../actions/content-actions';

import { useTheme } from '../../hooks/theme';
import { usePopup } from '../../hooks/popup/popup';
import { useAlert } from '../../hooks/alert/alert';
import { useMusicbrainz } from '../../hooks/musicbrainz';
import { useStatusHandler } from '../../hooks/status-handler';

import BiographyLoader from '../../loaders/BiographyLoader'

import { oneDecimal, checkNull } from '../../utils/format-text';

import Icon from '../../components/Icon';
import Stats from '../../components/Stats';
import Button from '../../components/Button';
import MenuBar from '../../components/MenuBar';
import FullBackground from '../../components/FullBackground';
import ProfilePicture from '../../components/ProfilePicture';
import ChangeContentRating from '../../components/ChangeContentRating';

import NotFound from '../NotFound';

import ArtistHome from './components/ArtistHome';

const ContentLists = lazy(() => import('../ContentLists'));
const ContentReviews = lazy(() => import('../ContentReviews'));
const ContentRatings = lazy(() => import('../ContentRatings'));
const ArtistReleases = lazy(() => import('../ArtistReleases'));

export default function Artist() {
    //General
    const onMobile = /Mobi/.test(navigator.userAgent)

    const dispatch = useDispatch()
    const match = useRouteMatch()
    const history = useHistory()
    const location = useLocation()
    const { colors, mode } = useTheme()
    const { openPopup } = usePopup()
    const { openAlert } = useAlert()
    const { id } = useParams()
    const { getBiography } = useMusicbrainz()

    //Params
    const createRatingParam = new URLSearchParams(location.search).get("create-rating")
    const listenLaterParam = new URLSearchParams(location.search).get("listen-later")
    const fromDeezer = new URLSearchParams(location.search).get("dz") !== null
    const fromRedirect = new URLSearchParams(location.search).get("rd") !== null
    
    //Responsive
    const isBigScreen = useMediaQuery({ maxWidth: 1250 })
    const isMidScreen = useMediaQuery({ maxWidth: 1100 })
    const isSmallScreen = useMediaQuery({ maxWidth: 960 })
    const isMobile = useMediaQuery({ maxWidth: 630 })
    const isMinimum = useMediaQuery({ maxWidth: 490 })

    //Auth
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
    const username = useSelector(state => state.me.username)

    //Variable
    const isNumberId = Number.isInteger(Number(id))

    //Prefetched artist id
    const urlSlugConnectionId = useSelector(state => !isNumberId ? state.local.artistUrlSlugResult[`/artist/${id}/`] : null)
    const deezerIdConnectionId = useSelector(state => isNumberId && fromDeezer ? state.local.artistDeezerIdResult[id] : null)
    const idConnectionId = useSelector(state => isNumberId && !fromDeezer ? state.local.artistIdResult[id] : null)

    //Support to update the artist id
    const [artistId, setArtistId] = useState(urlSlugConnectionId || deezerIdConnectionId || idConnectionId || isNumberId ? id : null)

    //Backend data
    const backend = useSelector(state => state.artists.artists[artistId])
    const lastFetchedArtistId = useSelector(state => state.artists.lastFetchedArtistId)
    const lastFetchedArtistIsRefetch = useSelector(state => state.artists.lastFetchedArtistIsRefetch)
    const fetchArtistStatus = useSelector(state => state.artists.fetchArtistStatus)
    const checkArtistDatabaseResult = useSelector(state => state.artists.checkArtistDatabaseResult)

    //Wants
    const myWants = useSelector(state => state.me.wants)
    const cachedWants = useSelector(state => state.me.cachedWants)
    const wants = [ ...myWants, ...cachedWants ]
    const wantIndex = wants.findIndex(want => want.content_id == id && want.content_ct === "artist")
    const wantObj = wantIndex !== -1 ? wants[wantIndex] : null

    //Variables
    const hasManualBio = backend && (backend.biography !== null && backend.biography !== undefined && backend.biography !== "")

    //State
    const [biography, setBiography] = useState({ loading: true, biography: null, link: null })
    const [initWait, setInitWait] = useState(true)

    //Refs
    const hasFetched = useRef(false)
    const metadataTimer = useRef(null)

    //Get artist from API and refresh artistId variable
    useEffect(() => {
        //Set init wait to hide error
        setInitWait(true)
        setTimeout(() => {
            setInitWait(false)
        }, 75)
        
        if (!isNumberId) {
            if (urlSlugConnectionId) { setArtistId(urlSlugConnectionId) } else { setArtistId(null) }
        } else if (fromDeezer) {
            if (deezerIdConnectionId) { setArtistId(deezerIdConnectionId) } else { setArtistId(id) }
        } else {
            if (idConnectionId) { setArtistId(idConnectionId) } else { setArtistId(id) }
        }

        if (isNumberId) {
            dispatch( 
                fetchArtist(id, fromDeezer) 
            )
        } else {
            if (!fromRedirect || !(backend && backend.id)) {
                dispatch( 
                    fetchArtistFromSlug(id) 
                )
            }
        }

        //Fetch status of artist after 1.2 seconds and still loading
        setTimeout(() => {
            if (!hasFetched.current) {
                if (isNumberId) {
                    dispatch( 
                        checkArtistDatabaseStatus(`/artist/${id}/`, null, null) 
                    )
                } else {
                    if (!fromRedirect || !(backend && backend.id)) {
                        dispatch( 
                            checkArtistDatabaseStatus(null, fromDeezer ? id : null, fromDeezer ? null : id) 
                        )
                    }
                }
            }
        }, 1200)

        //Reset the biography
        setBiography({ loading: true, biography: null, link: null })
        
    }, [ id, fromDeezer ])

    //Potentially update the artistId if our backend return a different
    useStatusHandler({
        effectStatus: fetchArtistStatus,
        successCallback: () => {
            if (lastFetchedArtistId && lastFetchedArtistId !== artistId && !lastFetchedArtistIsRefetch) {
                setArtistId(lastFetchedArtistId)
            }
        }
    })

    //Reset the hasFetched ref when we change the artist
    useEffect(() => {
        hasFetched.current = false

        return(() => {
            if (metadataTimer.current) {
                clearInterval(metadataTimer.current)
            }
        })
    }, [ id ])

    //Get data when we have the correct artist
    useEffect(() => {
        //Have fetched backend
        if (backend && backend.id && !hasFetched.current) {
            //Set ref
            hasFetched.current = true;

            //If we have the ID, replace url with the URL slug
            if (backend && isNumberId) {
                const locationSearchList = location.search ? location.search.replace("?", "").split("&") : []
                locationSearchList.splice(locationSearchList.indexOf("dz"), 1)
                if (locationSearchList.indexOf("rd") === -1) {
                    locationSearchList.push("rd")
                }
                history.replace(`${backend.url_slug}${location.pathname.replace(`/artist/${id}`, "").replace("/", "")}${locationSearchList.length > 0 ? "?" : ""}${locationSearchList.join("&")}`)
            }

            //Fetch the user's rating
            if (isLoggedIn) {
                dispatch( 
                    getMyContentRating(backend.id, "artist", false)
                )
            }

            //If the metadata is not already completed, start a 2s timer to refetch the artist
            if (backend.deezer_id && !backend.metadata_fetched) {
                metadataTimer.current = setInterval(() => {
                    dispatch( 
                        fetchArtist(backend.id, false, true) 
                    )
                }, 2000)
            }

            //Musicbrainz biography
            if (backend && backend.name && !hasManualBio) {
                getBiography(backend.name).then(({ bio, link }) => {
                    setBiography({ loading: false, biography: bio, link: link })
                })
                .catch((err) => {
                    setBiography({ loading: false, biography: null, link: null })
                    console.warn("No bio found", err)
                })
            }

            //Run CTAs based on params
            if (createRatingParam === "true") {
                if (onMobile) {
                    document.location.href = `musicboard://artist/${backend.id}`
                } else if (isLoggedIn) {
                    openPopup("CreateReview", { content: backend, cacheRating: true })
                }
            } else if (listenLaterParam === "true") {
                if (onMobile) {
                    document.location.href = `musicboard://artist/${backend.id}`
                } else if (isLoggedIn) {
                    if (wantObj) {
                        openAlert({
                            p: `${backend.name} is already in your`,
                            a: "Listen Later.",
                            href: `/${username}/wantlist`,
                            timer: 5000,
                            style: "red"
                        })
                    } else {
                        dispatch(
                            createWant(backend.id, "artist", false)
                        )
                    }
                }
            }

        }

    }, [ backend && backend.id ])

    //Clear the metadata timer when the artist is fetched
    useEffect(() => {
        if (backend && backend.id && backend.metadata_fetched) {
            clearInterval(metadataTimer.current)
        }
    }, [ backend && backend.id, backend && backend.metadata_fetched ])

    //Render loading or 404
    if(!backend || !backend.id) {
        if(!initWait && (fetchArtistStatus === status.SUCCESS || fetchArtistStatus === status.ERROR)) {
            return (
                <NotFound />
            )
        } else {
            return (
                <div className="full-height">
                    <div className="full-center">
                        <Icon className="margin-auto" icon="loading" color={colors.darkLightGrey} size={32} />

                        {checkArtistDatabaseResult && 
                         checkArtistDatabaseResult.result && 
                         (checkArtistDatabaseResult.urlSlug === `/artist/${id}/` || checkArtistDatabaseResult.id === id || checkArtistDatabaseResult.deezerId === id) && 
                         (checkArtistDatabaseResult.result.exists === false || checkArtistDatabaseResult.result.deezer_fetched === false) &&
                            <p className={styles.loadingInformation}>
                                You're the first on Musicboard to view this artist!<br />
                                Please give us a moment to get all the information for you.<br />
                                It might take a few seconds to load.
                            </p>
                        }
                    </div>
                </div>
            )
        }
    }

    //Render
    return (
        <div>
            <MenuBar
            tabs={[
                {
                    text: "Home",
                    redirect: backend.url_slug,
                    extraActive: `/artist/${id}`
                },
                {
                    text: "Discography",
                    redirect: `${backend.url_slug}releases`,
                    extraActive: `/artist/${id}/releases`
                },
                {
                    text: "Reviews",
                    redirect: `${backend.url_slug}reviews?order_by=helpful`,
                    extraActive: `/artist/${id}/reviews?order_by=helpful`
                },
                !isMinimum && {
                    text: "Lists",
                    redirect: `${backend.url_slug}lists?order_by=-like_count`,
                    extraActive: `/artist/${id}/lists?order_by=-like_count`
                },
            ]}
            >
                <div className="padding-bottom-32" style={{ position: 'relative', zIndex: 1 }}>
                    {backend && backend.background && backend.background.background_large &&
                        <FullBackground
                        whiteGradient
                        image={backend.background.background_original?.includes(".gif") ? backend.background.background_original : backend.background.background_large}
                        placeholder={backend.background.background_small}
                        marginHeight={200}
                        altText={`Background for ${backend.name}`}
                        />
                    }

                    <div className="page-container">
                        <div className="section-handler-end padding-top-64">
                            <div className="section-3 section-margin">
                                <div className={isSmallScreen ? "flex-column flex-center" : "flex-row align-end"}>
                                    <ProfilePicture
                                    size={isMobile ? 200 : isMidScreen ? 240 : isBigScreen ? 270 : 300}
                                    profilePicture={backend.picture_large || backend.picture}
                                    altText={backend.name}
                                    />

                                    <div className={isSmallScreen ? "flex-column flex-center margin-top-24" : "margin-left-24 margin-bottom-16 col-1"}>
                                        <h4 className={`black text-2xl ${isSmallScreen && "text-center"}`} style={isMobile ? { fontSize: 28, lineHeight: '38px' } : isMidScreen ? { fontSize: 32, lineHeight: '42px' } : isBigScreen ? { fontSize: 36, lineHeight: '46px' } : {}}>{backend.name}</h4>
                                        
                                        <div className="flex-row">
                                            {(hasManualBio || biography.biography) &&
                                                <div className={`highDarkGrey margin-top-8 ${styles.biographyWrapper}`}>
                                                    {ReactHtmlParser(hasManualBio ? `<p>${backend.biography}</p>` : biography.biography)}

                                                    <div className={styles.biographyFade} />

                                                    <div 
                                                    className={styles.biographyBtn} 
                                                    onClick={() => openPopup("Biography", { 
                                                        biography: hasManualBio ? `<p>${backend.biography}</p>` : biography.biography, 
                                                        link: hasManualBio ? null : biography.link, 
                                                        popupTitle: backend.name 
                                                    })}
                                                    >
                                                        <p className={styles.biographyBtnText}>Read more...</p>
                                                    </div>
                                                </div>
                                            }

                                            {!hasManualBio && !biography.biography && biography.loading &&
                                                <BiographyLoader className="margin-top-8" />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={`section-2 ${isSmallScreen ? "margin-top-32" : "margin-bottom-16"}`}>
                                <Stats
                                hideIcons={(isMidScreen && !isSmallScreen) || isMobile}
                                statistics={[
                                    {
                                        number: backend && backend.ratings_count ? checkNull(backend.ratings_count) : '0',
                                        description: "Total ratings"
                                    },
                                    {
                                        number: backend && backend.average_rating ? oneDecimal(backend.average_rating/2) : 0,
                                        subText: " / 5",
                                        description: "Average rating",
                                        star: true,
                                        active: backend && backend.average_rating
                                    },
                                    {
                                        number: (backend && backend.myRatings && backend.myRatings.data && backend.myRatings.data[0]) ? (backend.myRatings.data[0].rating/2) : 0,
                                        subText: " / 5",
                                        description: "Your rating",
                                        star: true,
                                        active: (backend && backend.myRatings && backend.myRatings.data && backend.myRatings.data[0] && backend.myRatings.data[0].rating)
                                    },
                                ]}
                                />

                                {(backend && backend.myRatings && backend.myRatings.data && backend.myRatings.data[0]) &&
                                    <div className={`${isMobile ? "margin-top-32" : "margin-top-16"} ${styles.myRatingContainer}`}>
                                        <div className={styles.myRating}>
                                            <ChangeContentRating contentId={backend.id} contentType="artist" fromDeezer={false} iconSize={24} />
                                        </div>

                                        <div className={styles.myRatingOverlayBtn}>
                                            <Button
                                            text={!backend.myRatings.data[0].rating ? "Logged artist" : "Edit rating"}
                                            iconName="edit"
                                            showIcon
                                            style={{ cursor: "default" }}
                                            type={!backend.myRatings.data[0].rating ? "green" : "yellow"}
                                            />
                                        </div>
                                    </div>
                                }

                                {!(backend && backend.myRatings && backend.myRatings.data && backend.myRatings.data[0]) &&
                                    <Button
                                    className="margin-top-16"
                                    text={isLoggedIn ? "Rate Artist" : "Sign up to rate this artist"}
                                    iconName={isLoggedIn ? "star" : "lock"}
                                    showIcon
                                    onClicked={() => {
                                        if (isLoggedIn) {
                                            openPopup("CreateReview", { content: backend, cacheRating: true })
                                        } else {
                                            openPopup("SignUp")
                                        }
                                    }}
                                    />
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </MenuBar>
            
            <Suspense
            fallback={
                <div className='flex-center empty-text margin-top-16 margin-bottom-16'>
                    <Icon icon="loading" />
                </div>
            }
            >
                <Switch>
                    <Route
                    exact 
                    path={`${match.url}`} 
                    render={() => (
                        <ArtistHome 
                        backend={backend} 
                        biography={(hasManualBio || biography.biography) ? ReactHtmlParser(hasManualBio ? `<p>${backend.biography}</p>` : biography.biography) : null}
                        titleSlug={id}
                        />
                    )} 
                    />

                    <Route
                    exact 
                    path={`${match.path}/reviews`} 
                    render={() => (
                        <ContentReviews backend={backend} titleSlug={id} />
                    )}
                    />

                    <Route
                    exact 
                    path={`${match.path}/lists`} 
                    render={() => (
                        <ContentLists backend={backend} titleSlug={id} />
                    )}
                    />
                    
                    <Route
                    exact 
                    path={`${match.path}/ratings`} 
                    render={() => (
                        <ContentRatings backend={backend} titleSlug={id} />
                    )}
                    />

                    <Route
                    exact 
                    path={`${match.path}/releases`} 
                    render={() => (
                        <ArtistReleases backend={backend} titleSlug={id} />
                    )}
                    />
                </Switch>
            </Suspense>

        </div>
    )
}
