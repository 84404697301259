import styles from './audio.module.css'

import React, { useEffect, useReducer, useContext, useState, useRef } from 'react'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ReactJkMusicPlayer from 'react-jinke-music-player';

import { types } from '../../constants/action-types';

import { usePopup } from '../popup/popup';

import { checkDeezerId } from '../../utils/deezer-utils';

import Icon from '../../components/Icon';
import Tooltip from '../../components/Tooltip';
import AlbumCover from '../../components/AlbumCover';
import { getNormal } from '../../utils/fetch-normal';
import { url } from '../../config';
import { useMediaQuery } from 'react-responsive';


const AudioContext = React.createContext();

export const useAudio = () => {
    const { state, dispatch } = useContext(AudioContext)

    //audios = [ { id, deezer_id, url_slug, name: '', singer: '', cover: '', musicSrc: '', duration } ]
    const playTracks = (audios=[], playIndex=0, contentId) => {
        dispatch({
            type: 'PLAY_TRACKS',
            payload: {
                audios,
                playIndex,
                contentId,
            }
        })
    }
    
    const pause = () => {
        dispatch({
            type: 'PAUSE_TRACKS',
            payload: Math.random(),
        })
    }

    const resume = () => {
        dispatch({
            type: 'RESUME_TRACKS',
            payload: Math.random(),
        })
    }

    return {
        isMounted: state.isMounted,
        isMountedPre: state.isMountedPre,
        contentId: state.contentId,
        audios: state.audios,
        playIndex: state.playIndex,
        playing: state.playing,
        activeTrack: state.activeTrack,
        playTracks,
        pause,
        resume,
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'PLAY_TRACKS':
            return {
                ...state,
                isMounted: action.payload.audios.length > 0,
                isMountedPre: action.payload.audios.length > 0,
                audios: action.payload.audios,
                playIndex: action.payload.playIndex,
                contentId: action.payload.contentId,
            }
        case 'PAUSE_TRACKS':
            return {
                ...state,
                pauseValue: action.payload,
            }
        case 'RESUME_TRACKS':
            return {
                ...state,
                resumeValue: action.payload,
            }
        case 'CLEAR_TRACKS_PRE':
            return {
                ...state,
                isMountedPre: false,
                contentId: null,
            }
        case 'CLEAR_TRACKS':
            return {
                ...state,
                isMounted: false,
                isMountedPre: false,
                contentId: null,
                audios: [],
                playIndex: 0,
            }
        case 'SET_PLAYING':
            return {
                ...state,
                playing: action.payload,
            }
        case 'SET_ACTIVE_TRACK':
            return {
                ...state,
                activeTrack: action.payload,
            }
        default:
            throw new Error()
    }
}

const defaultAudio = { 
    isMounted: false,
    isMountedPre: false,
    contentId: null,
    audios: [],
    playIndex: 0,
    playing: false,
    activeTrack: null,
    pauseValue: null,
    resumeValue: null,
}
  
const AudioProvider = ({ init, children }) => {
    //General
    const [ state, dispatch ] = useReducer(reducer, defaultAudio);
    const reduxDispatch = useDispatch()
    const { openPopup } = usePopup()

    //Reducer state
    const { isMountedPre, isMounted, audios, playIndex, pauseValue } = state;

    //Responsive
    const onMobile = /Mobi/.test(navigator.userAgent)
    const isMidScreen = useMediaQuery({ maxWidth: 1060 })
    const isSmallScreen = useMediaQuery({ maxWidth: 960 })
    const isTabletScreen = useMediaQuery({ maxWidth: 860 })

    //Data
    const defaultVolume = useSelector(state => state.local.volume)
    const isMinimized = true

    //State
    const [currentPlayIndex, setCurrentPlayIndex] = useState(null)
    const [activeTrack, setActiveTrack] = useState(null)
    const [playing, setPlaying] = useState(false)
    const [playMode, setPlayMode] = useState('order')
    const [progress, setProgress] = useState(0)
    const [duration, setDuration] = useState(1)
    const [volume, setVolume] = useState(defaultVolume)
    const [isEditingProgress, setIsEditingProgress] = useState(false)
    const [closing, setClosing] = useState(false)
    const [loadingRate, setLoadingRate] = useState(false)
    const [showVolumeSlider, setShowVolumeSlider] = useState(false);

    //Refs
    const volumeSliderRef = useRef();

    // Close the slider when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (volumeSliderRef.current && !volumeSliderRef.current.contains(event.target)) {
                setShowVolumeSlider(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    //Refs
    const audioInstanceRef = useRef(null)

    //Keep state in sync for playing
    useEffect(() => {
        dispatch({
            type: 'SET_PLAYING',
            payload: playing,
        })
    }, [playing])

    //Keep state in sync for activeTrack
    useEffect(() => {
        dispatch({
            type: 'SET_ACTIVE_TRACK',
            payload: activeTrack,
        })
    }, [activeTrack])

    useEffect(() => {
        if (audios.length === 0) {
            audioInstanceRef.current = null;
            setCurrentPlayIndex(null)
            setActiveTrack(null)
            setPlaying(false)
            setProgress(0)
            setDuration(1)
        } else {
            setCurrentPlayIndex(playIndex)
            setActiveTrack(audios[playIndex])
            setPlaying(true)
            setProgress(0)
            setDuration(audios[playIndex].duration && audios[playIndex].duration < 30 ? audios[playIndex].duration : 30)
        }
    }, [audios, playIndex])

    //Pause based on pauseValue
    useEffect(() => {
        if (pauseValue && audioInstanceRef.current) {
            audioInstanceRef.current.pause()
        }
    }, [pauseValue])

    //Resume based on resumeValue
    useEffect(() => {
        if (state.resumeValue && audioInstanceRef.current) {
            audioInstanceRef.current.play()
        }
    }, [state.resumeValue])

    //Set default volume
    useEffect(() => {
        if (audioInstanceRef.current) {
            audioInstanceRef.current.volume = defaultVolume
        }
    }, [Boolean(audioInstanceRef.current)])

    //Helper function
    const volumeClick = () => {
        audioInstanceRef.current.volume = volume > 0.02 ? 0 : 1
        setVolume(volume > 0.02 ? 0 : 1)
        reduxDispatch({ type: types.SET_VOLUME, payload: volume > 0.02 ? 0 : 1 })
    }

    return (
        <AudioContext.Provider value={{ state, dispatch }}>
            <div className={`${isMountedPre ? "is-mounted-pre" : ""} ${isMounted ? "is-mounted" : ""}`}>
                {children}
            </div>

            {activeTrack &&
                <ReactJkMusicPlayer
                style={{ pointerEvents: 'none', display: 'none', width: 0, height: 0, overflow: 'hidden', transform: 'translateX(-10000px)' }}
                
                playMode={playMode}
                defaultVolume={0}
                audioLists={audios}
                clearPriorAudioLists={true}
                playIndex={currentPlayIndex}
                volumeFade={{
                    fadeIn: 200,
                    fadeOut: 200,
                }}
    
                // When the user has moved/jumped to a new location in audio
                onAudioSeeked={(audioInfo) => {
                    console.log('audio seeked', audioInfo)
                    setProgress(audioInfo.currentTime)
                }}
    
                // When the volume has changed  min = 0.0  max = 1.0
                onAudioVolumeChange={(currentVolume) => {
                    console.log('audio volume change:', currentVolume)
                    setVolume(currentVolume)
                }}
    
                // The single song is ended handle
                onAudioEnded={(currentPlayId, audioLists, audioInfo) => {
                    console.log('audio ended', currentPlayId, audioLists, audioInfo)
                }}
    
                // audio load abort
                onAudioAbort={(currentPlayId, audioLists, audioInfo) => {
                    console.log('audio abort', currentPlayId, audioLists, audioInfo)
                }}
    
                // audio reload handle
                onAudioReload={(audioInfo) => {
                    console.log('audio reload:', audioInfo)
                }}
    
                // audio load failed error handle
                onAudioError={(errMsg, currentPlayId, audioLists, audioInfo) => {
                    console.error('audio error', errMsg, currentPlayId, audioLists, audioInfo)
                }}
    
                onAudioListsChange={(currentPlayId, audioLists, audioInfo) => {
                    console.log({
                        type: 'audio lists change:', currentPlayId, audioLists, audioInfo
                    })
                }}
    
                onAudioPlayTrackChange={(currentPlayId, audioLists, audioInfo) => {
                    console.log('audio play track change:', {
                        currentPlayId,
                        audioLists,
                        audioInfo,
                    })
                    const playIndex = 
                        currentPlayId && currentPlayId !== "" ? 
                            audioLists.findIndex(audio => audio.__PLAYER_KEY__ === currentPlayId) 
                        : 0
                    if (!currentPlayIndex || currentPlayIndex !== playIndex) {
                        setCurrentPlayIndex(playIndex)
                    }
                }}
    
                onAudioListsPanelChange={(panelVisible) => {
                    console.log('audio lists panel visible:', panelVisible)
                }}
    
                onAudioListsSortEnd={(oldIndex, newIndex) => {
                    console.log('audio lists sort end:', oldIndex, newIndex)
                }}
    
                onAudioLyricChange={(lineNum, currentLyric) => {
                    console.log('audio lyric change:', lineNum, currentLyric)
                }}
    
                onAudioPlay={(audioInfo) => {
                    console.log('audio playing', audioInfo)
                    setPlaying(true)
                }}
                onAudioPause={(audioInfo) => {
                    console.log('audio paused', audioInfo)
                    setPlaying(false)
                }}
                onAudioProgress={(audioInfo) => {
                    if (!activeTrack || activeTrack.id !== audioInfo.id) {
                        setActiveTrack(audioInfo)
                        setDuration(
                            audioInfo.duration && audioInfo.duration < 30 ? audioInfo.duration : 30
                        )
                    }
                    console.log('audio progress', audioInfo)
                    if (!isEditingProgress) {
                        setProgress(audioInfo.currentTime)
                    }
                }}
    
                showMediaSession
                getAudioInstance={(instance) => {
                    audioInstanceRef.current = instance
                }}
                />
            }

            {activeTrack &&
                <div style={{ height: 87 }} />
            }

            {activeTrack && !isTabletScreen && // !isMinimized &&
                <div className={`${styles.container} ${closing ? styles.closing : ""} flex-row`}>
                    <div className='flex-row col-2 padding-left-16 padding-right-12 padding-top-12 padding-bottom-12'>
                        <Link to={checkDeezerId(activeTrack.album) ? `/album/${activeTrack.album.id}?tracks=${activeTrack.id}&dz` : `${activeTrack.album.url_slug}?tracks=${activeTrack.id}&dz`}>
                            <AlbumCover
                            size={56}
                            clickable
                            albumCover={activeTrack.cover}
                            altText={activeTrack.name}
                            />
                        </Link>

                        <div className='col-1 margin-left-12 grid'>
                            <Link to={checkDeezerId(activeTrack.album) ? `/album/${activeTrack.album.id}?tracks=${activeTrack.id}&dz` : `${activeTrack.album.url_slug}?tracks=${activeTrack.id}&dz`} className="grid">
                                <h5 className={`black one-line ${styles.title}`}>
                                    {activeTrack.name}
                                </h5>
                            </Link>

                            <Link to={checkDeezerId(activeTrack.artist) ? `/artist/${activeTrack.artist.id}` : activeTrack.artist.url_slug} className="grid">
                                <p className={`highDarkGrey one-line margin-top-4 ${styles.artist}`} style={{ marginBottom: 1 }}>
                                    {activeTrack.singer}
                                </p>
                            </Link>
                        </div>
                    </div>

                    <div className='col-3 flex-column flex-center margin-left-24 margin-right-24'>
                        <div className='flex-row'>
                            <div
                            onClick={() => {
                                if (progress < 3) {
                                    audioInstanceRef.current.playPrev()
                                } else {
                                    audioInstanceRef.current.currentTime = 0
                                }
                            }}
                            className={`${styles.iconMg}`}
                            >
                                <Icon icon={"fast-rewind"} size={28} style={{ display: 'block' }} className={`cursor`} />
                            </div>

                            <div 
                            onClick={() => {
                                if (playing) {
                                    audioInstanceRef.current.pause()
                                } else {
                                    audioInstanceRef.current.play()
                                }
                            }}
                            className={`${styles.iconMg}`}
                            >
                                <Icon 
                                icon={playing ? "pause-circle" : "play-circle"} 
                                className={`black cursor`} 
                                size={40} 
                                style={{ display: 'block' }} 
                                />
                            </div>

                            <div
                            onClick={() => {
                                audioInstanceRef.current.playNext()
                            }}
                            className={`${styles.iconMg}`}
                            >
                                <Icon icon={"fast-forward"} size={28} style={{ display: 'block' }} className={`cursor`} />
                            </div>
                        </div>
                        
                        <div className='flex-row margin-top-4' style={{ width: '100%' }}>
                            <p className='highDarkGrey' style={{ fontSize: 12 }}>0:{progress ? `${progress < 10 ? "0" : ""}${progress?.toFixed(0)}` : "00"}</p>

                            <div className={`${styles.progressContainer} col-1 margin-left-12 margin-right-12`}>
                                <div className={`${styles.progressSliderWrapper}`}>
                                    <div className={`${styles.progressSliderInner}`} style={{ width: `${progress && duration ? progress / duration * 100 : 0}%` }} />
                                </div>

                                <div className={`${styles.progressSliderThumb}`} style={{ left: `${progress && duration ? progress / duration * 99 : 0}%` }} />

                                <input 
                                type='range' 
                                className={`${styles.progressSlider}`} 
                                value={progress} 
                                min={0}
                                max={duration} 
                                step={0.1}
                                onChange={(e) => {
                                    console.log({ value: e.target.value })
                                    setProgress(Number(e.target.value))
                                }}
                                onMouseDown={(e) => {
                                    setIsEditingProgress(true)
                                }}
                                onMouseUp={(e) => {
                                    setIsEditingProgress(false)
                                    audioInstanceRef.current.currentTime = Number(e.target.value)
                                }}
                                />
                            </div>

                            <p className='highDarkGrey' style={{ fontSize: 12 }}>0:{duration ? `${duration < 10 ? "0" : ""}${Number(duration).toFixed(0)}` : "30"}</p>
                        </div>
                    </div>

                    <div className='flex-row justify-end col-2 padding-left-12 padding-right-16 padding-top-12 padding-bottom-12'>
                        {!isSmallScreen && // !isMidScreen &&
                        <>
                            <div 
                            id={"audio-rate-star"}
                            className={`${styles.iconMgSmall} cursor`}
                            onClick={() => {
                                setLoadingRate(true)

                                getNormal(`${url}/v2/dz_tracks/${activeTrack.id}/`, 10000)
                                .then((res) => {
                                    setLoadingRate(false)
                                    openPopup("CreateReview", { content: res, cacheRating: false })
                                })
                                .catch((err) => {
                                    setLoadingRate(false)
                                    console.error(err)
                                })
                            }}
                            >
                                {loadingRate &&
                                    <div className='flex-center' style={{ width: 26, height: 26 }}>
                                        <Icon icon="loading" size={20} />
                                    </div>
                                }

                                {!loadingRate &&
                                    <Icon icon={"star"} size={26} style={{ display: 'block' }} />
                                }
                            </div>

                            <Tooltip id="audio-rate-star" text={"Rate track"} offset={16} />
                        </>}

                        {// !isSmallScreen &&
                        <>
                            {playMode === "order" && 
                                <div 
                                id={"audio-enable-loop"}
                                className={`${styles.iconMgSmall} cursor`}
                                onClick={() => setPlayMode('orderLoop')}
                                >
                                    <Icon icon={"repeat"} size={26} color={"var(--black)"} style={{ display: 'block' }} />
                                </div>
                            }

                            {playMode === "orderLoop" && 
                                <div 
                                id={"audio-enable-single"}
                                className={`${styles.iconMgSmall} cursor`}
                                onClick={() => setPlayMode('singleLoop')}
                                >
                                    <Icon icon={"repeat"} size={26} color={"var(--blue)"} style={{ display: 'block' }} />
                                </div>
                            }

                            {!(playMode === "order" || playMode === "orderLoop") && 
                                <div 
                                id={"audio-enable-order"}
                                className={`${styles.iconMgSmall} cursor`}
                                onClick={() => setPlayMode('order')}
                                >
                                    <Icon icon={"repeat-one"} size={26} color={"var(--blue)"} style={{ display: 'block' }} />
                                </div>
                            }

                            {playMode === "order" &&
                                <Tooltip id="audio-enable-loop" text={"Enable repeat"} offset={16} />
                            }
                            {playMode === "orderLoop" &&
                                <Tooltip id="audio-enable-single" text={"Enable repeat song"} offset={16} />
                            }
                            {!(playMode === "order" || playMode === "orderLoop") &&
                                <Tooltip id="audio-enable-order" text={"Disable repeat"} offset={16} />
                            }
                        </>
                        }

                        {volume > 0.02 &&
                            <div
                            id={volume > 0.02 ? "audio-enable-mute" : "audio-volume-unmute"}
                            onClick={volumeClick}
                            className={`${styles.iconMgSmall} cursor`}
                            >
                                <Icon 
                                icon={volume > 0.66 ? "volume-up" : volume > 0.33 ? "volume-down" : "volume-mute"} 
                                size={26} 
                                style={{ display: 'block' }} 
                                />
                            </div>
                        }
                        
                        {volume <= 0.02 &&
                            <div
                            id={"audio-volume-unmute"}
                            onClick={volumeClick}
                            className={`${styles.iconMgSmall} cursor`}
                            >
                                <Icon 
                                icon={"volume-off"} 
                                size={26} 
                                style={{ display: 'block' }} 
                                />
                            </div>
                        }

                        {volume > 0.02 &&
                            <Tooltip id="audio-enable-mute" text={"Mute"} offset={16} />
                        }
                        {volume <= 0.02 &&
                            <Tooltip id="audio-volume-unmute" text={"Unmute"} offset={16} />
                        }

                        <div className={`${styles.progressContainer} ${styles.volumeContainer}`}>
                            <div className={`${styles.progressSliderWrapper}`}>
                                <div className={`${styles.progressSliderInner}`} style={{ width: `${volume / 1 * 100}%` }} />
                            </div>

                            <div className={`${styles.progressSliderThumb}`} style={{ left: `${volume / 1 * 100}%` }} />

                            <input 
                            type='range' 
                            className={`${styles.progressSlider}`} 
                            value={volume} 
                            min={0}
                            max={1} 
                            step={0.01}
                            onChange={(e) => {
                                console.log({ value: e.target.value })
                                audioInstanceRef.current.volume = Number(e.target.value)
                            }}
                            onMouseUp={() => {
                                reduxDispatch({ type: types.SET_VOLUME, payload: volume })
                            }}
                            />
                        </div>

                        <div 
                        id={"audio-close"}
                        className={`${styles.iconMgSmall} cursor`}
                        onClick={() => {
                            setClosing(true)

                            dispatch({
                                type: 'CLEAR_TRACKS_PRE',
                            })

                            setTimeout(() => {
                                setClosing(false)

                                dispatch({
                                    type: 'CLEAR_TRACKS',
                                })
                            }, 300)
                        }}
                        >
                            <Icon icon={"cancel"} size={26} style={{ display: 'block' }} />
                        </div>

                        <Tooltip id="audio-close" text={"Stop"} offset={16} />
                    </div>
                </div>
            }

            {activeTrack && isTabletScreen &&
                <div className={`${styles.container} ${styles.mobile} ${closing ? styles.closing : ""}`}>
                    <div className={`flex-row`}>
                        <div className='flex-row col-2 padding-left-12 padding-right-12 padding-top-12 padding-bottom-12'>
                            <Link to={checkDeezerId(activeTrack.album) ? `/album/${activeTrack.album.id}?tracks=${activeTrack.id}&dz` : `${activeTrack.album.url_slug}?tracks=${activeTrack.id}&dz`}>
                                <AlbumCover
                                size={46}
                                clickable
                                albumCover={activeTrack.cover}
                                altText={activeTrack.name}
                                />
                            </Link>

                            <div className='col-1 margin-left-12 grid'>
                                <Link to={checkDeezerId(activeTrack.album) ? `/album/${activeTrack.album.id}?tracks=${activeTrack.id}&dz` : `${activeTrack.album.url_slug}?tracks=${activeTrack.id}&dz`} className="grid">
                                    <h5 className={`black one-line ${styles.title}`}>
                                        {activeTrack.name}
                                    </h5>
                                </Link>

                                <Link to={checkDeezerId(activeTrack.artist) ? `/artist/${activeTrack.artist.id}` : activeTrack.artist.url_slug} className="grid">
                                    <p className={`highDarkGrey one-line margin-top-4 ${styles.artist}`} style={{ marginBottom: 1 }}>
                                        {activeTrack.singer}
                                    </p>
                                </Link>
                            </div>
                        </div>


                        <div className='flex-row justify-end padding-left-12 padding-right-16 padding-top-12 padding-bottom-12'>
                            {!onMobile &&
                                <div onMouseLeave={() => setShowVolumeSlider(false)} className={styles.volumeSliderWrapper}>
                                    <div
                                    className={`${styles.iconMgSmall} cursor`}
                                    onMouseEnter={() => setShowVolumeSlider(true)}
                                    onClick={() => setShowVolumeSlider(true)}
                                    >
                                        <Icon 
                                        icon={volume > 0.66 ? "volume-up" : volume > 0.33 ? "volume-down" : volume > 0.02 ? "volume-mute" : "volume-off"} 
                                        size={26} 
                                        style={{ display: 'block' }} 
                                        />
                                    </div>

                                    <div
                                    ref={volumeSliderRef}
                                    className={`${styles.volumeSliderContainer} ${showVolumeSlider ? styles.active : ""}`}
                                    >
                                        <div className={styles.volumeSliderInnerContainer}>
                                            <div className={`${styles.progressContainer} ${styles.volumeVerticalContainer}`}>
                                                <div className={`${styles.progressSliderWrapper}`}>
                                                    <div className={`${styles.progressSliderInner}`} style={{ height: `${volume / 1 * 100}%` }} />
                                                </div>

                                                <div className={`${styles.progressSliderThumb}`} style={{ bottom: `${volume / 1 * 100}%` }} />

                                                <input 
                                                type='range' 
                                                className={`${styles.progressSlider}`} 
                                                value={volume} 
                                                min={0}
                                                max={1} 
                                                step={0.01}
                                                orientation="vertical" // Make the slider vertical
                                                onChange={(e) => {
                                                    console.log({ value: e.target.value })
                                                    audioInstanceRef.current.volume = Number(e.target.value)
                                                }}
                                                onMouseUp={() => {
                                                    reduxDispatch({ type: types.SET_VOLUME, payload: volume })
                                                }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            <div 
                            onClick={() => {
                                if (playing) {
                                    audioInstanceRef.current.pause()
                                } else {
                                    audioInstanceRef.current.play()
                                }
                            }}
                            className={`${styles.iconMgSmall}`}
                            >
                                <Icon 
                                icon={playing ? "pause-circle" : "play-circle"} 
                                className={`black cursor`} 
                                size={40} 
                                style={{ display: 'block' }} 
                                />
                            </div>

                            <div 
                            className={`${styles.iconMgSmall} cursor`}
                            onClick={() => {
                                setClosing(true)

                                dispatch({
                                    type: 'CLEAR_TRACKS_PRE',
                                })

                                setTimeout(() => {
                                    setClosing(false)

                                    dispatch({
                                        type: 'CLEAR_TRACKS',
                                    })
                                }, 300)
                            }}
                            >
                                <Icon icon={"cancel"} size={26} style={{ display: 'block' }} />
                            </div>
                        </div>
                    </div>

                    <div className='flex-row padding-bottom-4 padding-left-12 padding-right-12'>
                        <div className={`${styles.progressContainer} col-1`}>
                            <div className={`${styles.progressSliderWrapper}`}>
                                <div className={`${styles.progressSliderInner}`} style={{ width: `${progress && duration ? progress / duration * 100 : 0}%` }} />
                            </div>

                            <div className={`${styles.progressSliderThumb}`} style={{ left: `${progress && duration ? progress / duration * 99 : 0}%` }} />

                            <input 
                            type='range' 
                            className={`${styles.progressSlider}`} 
                            value={progress} 
                            min={0}
                            max={duration} 
                            step={0.1}
                            onChange={(e) => {
                                console.log({ value: e.target.value })
                                setProgress(Number(e.target.value))
                            }}
                            onMouseDown={(e) => {
                                setIsEditingProgress(true)
                            }}
                            onMouseUp={(e) => {
                                setIsEditingProgress(false)
                                audioInstanceRef.current.currentTime = Number(e.target.value)
                            }}
                            />
                        </div>
                    </div>
                </div>
            }
        </AudioContext.Provider>
    )
}
export default AudioProvider;