import { types, status } from '../constants/action-types';
import { errorToString } from '../utils/error'
import { handleReducer } from '../utils/reducer-helpers';

const defaultRatings = {
	latestRatings: {
		data: [],
		status: status.DEFAULT,
		next: null,
		count: null,
	},

	ratings: {},
	fetchRatingStatus: status.DEFAULT,
	lastFetchedRatingUid: null,

    lastUpdatedRating: null,
    updateRatingStatus: status.DEFAULT,

    lastCreatedRating: null,
	createRatingStatus: status.DEFAULT,
	
	lastDeletedRatingUid: null,
	deleteRatingStatus: status.DEFAULT,
	
	errorMessage: null,
};

const ratings = (state = defaultRatings, action) => {
	let uid = action.meta && action.meta.params ? action.meta.params.uid : null

	switch (action.type) {
		case types.SET_INIT_RATING:
			return {
				...state,
				ratings: {
					...state.ratings,
					[action.payload.uid]: {
						...state.ratings[action.payload.uid],
						...action.payload
					}
				},
				fetchRatingStatus: status.SUCCESS,
				lastFetchedRatingUid: action.payload.uid,
				errorMessage: null,
			}

		case types.GET_LATEST_RATINGS:
			return handleReducer(state, action, 'latestRatings')

		case types.FETCH_RATING:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
                        ...state,
                        fetchRatingStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
                        ...state,
                        ratings: {
							...state.ratings,
							[action.payload.uid]: action.payload
						},
						fetchRatingStatus: status.SUCCESS,
						lastFetchedRatingUid: action.payload.uid,
						errorMessage: null,
					}
				case status.ERROR:
					return {
                        ...state,
                        fetchRatingStatus: status.ERROR,
						lastFetchedRatingUid: null,
                        errorMessage: errorToString(action.payload),
					}
			}

		case types.UPDATE_RATING:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
                        ...state,
                        updateRatingStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
                        ...state,
                        lastUpdatedRating: action.payload,
						updateRatingStatus: status.SUCCESS,
						errorMessage: null,
					}
				case status.ERROR:
					return {
                        ...state,
                        updateRatingStatus: status.ERROR,
                        errorMessage: errorToString(action.payload),
					}
            }

		case types.CREATE_RATING:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						createRatingStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						ratings: {
							...state.ratings,
							[action.payload.uid]: action.payload
						},
						lastCreatedRating: action.payload,
						createRatingStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						createRatingStatus: status.ERROR,
					}
			}
		
		case types.DELETE_RATING:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						deleteRatingStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						lastDeletedRatingUid: uid,
						deleteRatingStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						deleteRatingStatus: status.ERROR,
					}
			}

		case types.LIKE_CONTENT:
			if (action.meta.params.contentType !== "rating") return state
			if (!action.meta.params.detailed) return state

			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						ratings: {
							...state.ratings,
							[action.meta.params.uid]: {
								...state.ratings[action.meta.params.uid],
								like_count: state.ratings[action.meta.params.uid].like_count + 1
							}
						},
					}
				case status.SUCCESS:
                    return state
				case status.ERROR:
					return {
						...state,
						ratings: {
							...state.ratings,
							[action.meta.params.uid]: {
								...state.ratings[action.meta.params.uid],
								like_count: state.ratings[action.meta.params.uid].like_count - 1
							}
						},
					}
			}

		case types.DELETE_LIKE:
			if (action.meta.params.contentType !== "rating") return state
			if (!action.meta.params.detailed) return state

			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						ratings: {
							...state.ratings,
							[action.meta.params.uid]: {
								...state.ratings[action.meta.params.uid],
								like_count: state.ratings[action.meta.params.uid].like_count - 1
							}
						},
					}
				case status.SUCCESS:
                    return state
				case status.ERROR:
					return {
						...state,
						ratings: {
							...state.ratings,
							[action.meta.params.uid]: {
								...state.ratings[action.meta.params.uid],
								like_count: state.ratings[action.meta.params.uid].like_count + 1
							}
						},
					}
			}
	
		default:
			return state;
	}
};

export default ratings;