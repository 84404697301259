import styles from './profiledropdown.module.css'

import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { googleLogout } from '@react-oauth/google';

import { logOut } from '../../../../actions/auth-actions'

import { useTheme } from '../../../../hooks/theme'
import { usePopup } from '../../../../hooks/popup/popup'

import Icon from '../../../../components/Icon'
import Dropdown from '../../../../components/Dropdown'
import UserProfilePicture from '../../../../components/UserProfilePicture'

export default function ProfileDropdown({ style, className, setSearchActive, setMenuActive }) {
    //General
    const { colors, mode } = useTheme()
    const dispatch = useDispatch()
    const {openPopup} = usePopup()

    //Auth
    const username = useSelector(state => state.me.username)
    const me = useSelector(state => state.me)

    //State
    const [visible, setVisible] = useState(false)

    //Options
    const options = [
        {
            text: 'Reviews',
            icon: "subject",
            redirect: `/${username}/reviews`
        },
        {
            text: 'Lists',
            icon: "list",
            redirect: `/${username}/lists`
        },
        {
            text: 'Listen Later',
            icon: "access-time",
            redirect: `/${username}/wantlist`
        },
    ]
    const endOptions = [
        {
            text: 'Contributions',
            icon: 'contribute',
            redirect: "/contributions",
            iconSize: 20
        },
        {
            text: 'Help',
            icon: 'help',
            redirect: "/help",
            onPress: () => {
                setVisible(false)
                openPopup("Help")
            }
        },
        {
            text: 'Settings',
            icon: 'settings',
            redirect: "/settings"
        },
        {
            text: 'Change theme',
            icon: "palette",
            redirect: "/settings/preferences",
        },
        {
            text: 'Log out',
            icon: "cancel",
            onPress: () => {
                dispatch(
                    logOut()
                )
                googleLogout()
            }
        },
    ]

    return (
        <div className={`flex-row ${className}`}  style={{ position: 'relative', ...style }}>
            <div 
            onClick={() => {
                setVisible(!visible)
                setSearchActive(false)
                setMenuActive(false)
            }}
            className="cursor"
            >
                <UserProfilePicture 
                profilePicture={me.profile_picture?.includes(".gif") ? me.profile_picture : me.profile_picture_small || me.profile_picture} 
                profileFrame={me.is_pro && me.tier === 1 ? me.profile_frame : null} 
                clickable 
                size={mode === "light" ? 40 : 38} 
                altText={me.name !== "" ? me.name : me.username}
                />
            </div>

            <Dropdown 
            simpleAnimation
            visible={visible}
            offset={44} 
            vertical="top" 
            position="right" 
            appendId={"profile"} 
            hideDropdown={() => setVisible(false)}
            >
                <Link to={`/${me.username}`} onClick={() => setVisible(false)}>
                    <div className={`flex-row ${styles.menuItem}`}>
                        <UserProfilePicture 
                        profilePicture={me.profile_picture?.includes(".gif") ? me.profile_picture : me.profile_picture_small || me.profile_picture} 
                        profileFrame={me.is_pro && me.tier === 1 ? me.profile_frame : null} 
                        clickable 
                        size={64} 
                        altText={me.name !== "" ? me.name : me.username}
                        skipFrameTitle
                        />

                        <div className="margin-left-12 margin-right-12">
                            <h5 className="black">{me.name !== "" ? me.name : me.username}</h5>
                            <p className="highDarkGrey one-line margin-top-4">Go to my profile</p>
                        </div>
                    </div>
                </Link>

                <div className="bottom-divider margin-top-8 margin-bottom-8" />

                {options.map(item => (
                    <Link
                    to={item.redirect}
                    onClick={(e) => {
                        if (item.onPress) {
                            e.preventDefault()
                            item.onPress()
                        } else {
                            setVisible(false)
                        }
                    }}
                    key={`profiledropdown-option-${item.text}`}
                    className={`flex-row cursor ${styles.menuItem}`}
                    >
                        <Icon icon={item.icon} size={20} className={`${styles.menuItemIcon} margin-right-16`} />

                        <h6 className={`${styles.menuItemText}`}>{item.text}</h6>
                    </Link>
                ))}

                <div className="bottom-divider margin-top-8 margin-bottom-8" />
                
                {endOptions.map(item => (
                    <Link
                    to={item.redirect ? item.redirect : ""}
                    onClick={(e) => {
                        if (item.onPress) {
                            e.preventDefault()
                            item.onPress()
                        } else {
                            setVisible(false)
                        }
                    }}
                    key={`profiledropdown-endoption-${item.text}`}
                    className={`flex-row cursor ${styles.menuItem}`}
                    >
                        <Icon icon={item.icon} size={item.iconSize ? item.iconSize : 20} className={`${styles.menuItemIcon} margin-right-16`} color={item.iconColor && item.iconColor} />
                        <h6 className={`${styles.menuItemText} margin-right-16`}>{item.text}</h6>
                    </Link>
                ))}
            </Dropdown>
            
        </div>
    )
}
