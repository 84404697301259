import { types, status } from '../constants/action-types';
import { errorToString } from '../utils/error'
import { handleReducer, handleReducerWithFiltering } from '../utils/reducer-helpers';

const defaultLists = {
	featuredReviews: {
        count: null,
        next: null,
        data: [],
        status: status.DEFAULT,
    },
	featuredLists: {
        count: null,
        next: null,
        data: [],
        status: status.DEFAULT,
    },
	discover: {
        count: null,
        next: null,
        data: [],
        status: status.DEFAULT,
    },
    promos: {
        count: null,
        next: null,
        data: [],
        status: status.DEFAULT,
    },
	friendsLists: {
        count: null,
        next: null,
        data: [],
        status: status.DEFAULT,
    },
	friendsReviews: {
        count: null,
        next: null,
        data: [],
        status: status.DEFAULT,
    },
	friendsRatings: {
        count: null,
        next: null,
        data: [],
        status: status.DEFAULT,
    },
	popularUsers: {
        count: null,
        next: null,
        data: [],
        status: status.DEFAULT,
    },
	suggestedUsers: {
        count: null,
        next: null,
        data: [],
        status: status.DEFAULT,
    },
	activeUsers: {
        count: null,
        next: null,
        data: [],
        status: status.DEFAULT,
    },
	featuredSections: {
        count: null,
        next: null,
        data: [],
        status: status.DEFAULT,
	},
	newReleases: {
        count: null,
        next: null,
        data: [],
        status: status.DEFAULT,
	},
	timelineReviews: {
        count: null,
        next: null,
        data: [],
        status: status.DEFAULT,
	},
	timelineLists: {
        count: null,
        next: null,
        data: [],
        status: status.DEFAULT,
	},
	
	resetFlatLists: null,
    
	errorMessage: null,
};

const home = (state = defaultLists, action) => {
	switch (action.type) {
		case types.GET_FEATURED_REVIEWS:
			return handleReducer(state, action, 'featuredReviews')

		case types.GET_FEATURED_LISTS:
			return handleReducer(state, action, 'featuredLists')

		case types.GET_DISCOVER:
			return handleReducer(state, action, 'discover')

		case types.GET_PROMOS:
			return handleReducer(state, action, 'promos')

		case types.GET_FRIENDS_LISTS:
			return handleReducer(state, action, 'friendsLists')

		case types.GET_FRIENDS_REVIEWS:
			return handleReducer(state, action, 'friendsReviews')

		case types.GET_FRIENDS_RATINGS:
			return handleReducer(state, action, 'friendsRatings')

		case types.GET_POPULAR_USERS:
			return handleReducer(state, action, 'popularUsers')

		case types.GET_SUGGESTED_USERS:
			return handleReducerWithFiltering(state, action, 'suggestedUsers', (oldUser, newUser) => oldUser.uid === newUser.uid)

		case types.GET_ACTIVE_USERS:
			return handleReducer(state, action, 'activeUsers')

		case types.GET_FEATURED_SECTIONS:
			return handleReducer(state, action, 'featuredSections')

		case types.GET_NEW_RELEASES:
			return handleReducer(state, action, 'newReleases')

		case types.GET_TIMELINE_REVIEWS:
			return handleReducer(state, action, 'timelineReviews')

		case types.GET_TIMELINE_LISTS:
			return handleReducer(state, action, 'timelineLists')

		case types.RESET_HOME_FLATLISTS:
			return {
				...state,
				resetFlatLists: new Date().toISOString()
			}

        case types.ANSWER_POLL:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
                        timelineReviews: {
                            ...state.timelineReviews,
                            data: state.timelineReviews.data.map(item => {
                                if (item.type === "thread" && item.uid === action.meta.params.threadUid) {
                                    return {
                                        ...item,
                                        poll_options: item.poll_options.map(option => {
                                            if (option.uid === action.meta.params.pollOptionUid) {
                                                return {
                                                    ...option,
                                                    answer_count: option.answer_count + 1
                                                }
                                            }
                                            else if (option.uid === action.meta.params.removedPollOptionUid) {
                                                return {
                                                    ...option,
                                                    answer_count: Math.max(option.answer_count - 1, 0)
                                                }
                                            }
                                            return option
                                        })
                                    }
                                }
                                return item
                            })
                        }
					}
				case status.SUCCESS:
					return state
				case status.ERROR:
					return state
			}
		
		case types.DELETE_POLL_ANSWER:
			switch (action.meta.status) {
				case status.BEGIN:
					return state
				case status.SUCCESS:
					return {
						...state,
                        timelineReviews: {
                            ...state.timelineReviews,
                            data: state.timelineReviews.data.map(item => {
                                if (item.type === "thread" && item.uid === action.meta.params.threadUid) {
                                    return {
                                        ...item,
                                        poll_options: item.poll_options.map(option => {
                                            if (option.uid === action.meta.params.removedPollOptionUid) {
                                                return {
                                                    ...option,
                                                    answer_count: Math.max(option.answer_count - 1, 0)
                                                }
                                            }
                                            return option
                                        })
                                    }
                                }
                                return item
                            })
                        }
					}
				case status.ERROR:
                    return state
			}
	
		default:
			return state;
	}
};

export default home;