import styles from './album.module.css'

import React, { Suspense, lazy, useState, useEffect, useRef } from 'react'
import { useHistory, useParams, useLocation, Link, useRouteMatch, BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';

import { status } from '../../constants/action-types';

import { deleteWant, createWant } from '../../actions/me-actions';
import { fetchAlbum, fetchAlbumFromSlug, getMyContentRating, checkAlbumDatabaseStatus } from '../../actions/content-actions';

import OneLineLoader from '../../loaders/OneLineLoader';

import { useTheme } from '../../hooks/theme';
import { usePopup } from '../../hooks/popup/popup';
import { useAlert } from '../../hooks/alert/alert';
import { useStatusHandler } from '../../hooks/status-handler';

import { oneDecimal, checkNull } from '../../utils/format-text';
import { displayRecordType } from '../../utils/record-type-utils';

import Icon from '../../components/Icon';
import Stats from '../../components/Stats';
import Button from '../../components/Button';
import MenuBar from '../../components/MenuBar';
import AlbumCover from '../../components/AlbumCover';
import FullBackground from '../../components/FullBackground';
import ProfilePicture from '../../components/ProfilePicture';
import ChangeContentRating from '../../components/ChangeContentRating';

import NotFound from '../NotFound';

import AlbumHome from './components/AlbumHome';

const ContentLists = lazy(() => import('../ContentLists'));
const ContentReviews = lazy(() => import('../ContentReviews'));
const ContentRatings = lazy(() => import('../ContentRatings'));

export default function Album(props) {
    //general
    const { colors } = useTheme()
    const { openPopup } = usePopup()
    const { openAlert } = useAlert()
    const onMobile = /Mobi/.test(navigator.userAgent)
    const { id, nameSlug, artistSlug } = useParams()
    const match = useRouteMatch()
    const location = useLocation()
    const history = useHistory()
    const dispatch = useDispatch()

    //Params
    const createRatingParam = new URLSearchParams(location.search).get("create-rating")
    const listenLaterParam = new URLSearchParams(location.search).get("listen-later")
    const fromDeezer = new URLSearchParams(location.search).get("dz") !== null
    const fromRedirect = new URLSearchParams(location.search).get("rd") !== null

    //Responsive
    const isBigScreen = useMediaQuery({ maxWidth: 1250 })
    const isMidScreen = useMediaQuery({ maxWidth: 1100 })
    const isSmallScreen = useMediaQuery({ maxWidth: 960 })
    const isMobile = useMediaQuery({ maxWidth: 630 })
    const isMinimum = useMediaQuery({ maxWidth: 490 })

    //Auth
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
    const username = useSelector(state => state.me.username)

    //Prefetched album id
    const urlSlugConnectionId = useSelector(state => nameSlug && artistSlug ? state.local.albumUrlSlugResult[`/album/${nameSlug}/${artistSlug}/`] : null)
    const deezerIdConnectionId = useSelector(state => id && fromDeezer ? state.local.albumDeezerIdResult[id] : null)
    const idConnectionId = useSelector(state => id && !fromDeezer ? state.local.albumIdResult[id] : null)

    //Support to update the album id
    const [albumId, setAlbumId] = useState(urlSlugConnectionId || deezerIdConnectionId || idConnectionId || id)

    //Data
    const backend = useSelector(state => state.albums.albums[albumId])
    const lastFetchedAlbumId = useSelector(state => state.albums.lastFetchedAlbumId)
    const lastFetchedAlbumIsRefetch = useSelector(state => state.albums.lastFetchedAlbumIsRefetch)
    const fetchAlbumStatus = useSelector(state => state.albums.fetchAlbumStatus)
    const checkAlbumDatabaseResult = useSelector(state => state.albums.checkAlbumDatabaseResult)

    //Want data
    const myWants = useSelector(state => state.me.wants)
    const cachedWants = useSelector(state => state.me.cachedWants)
    const wants = [ ...myWants, ...cachedWants ]
    const wantIndex = wants.findIndex(want => want.content_id == id && want.content_ct === "album")
    const wantObj = wantIndex !== -1 ? wants[wantIndex] : null

    //State
    const [initWait, setInitWait] = useState(true)

    //Refs
    const hasFetched = useRef(false)
    const metadataTimer = useRef(null)

    //Get album from API and refresh albumId variable
    useEffect(() => {
        //Set init wait to hide error
        setInitWait(true)
        setTimeout(() => {
            setInitWait(false)
        }, 75)

        if (nameSlug && artistSlug) {
            if (urlSlugConnectionId) { setAlbumId(urlSlugConnectionId) } else { setAlbumId(null) }
        } else if (fromDeezer) {
            if (deezerIdConnectionId) { setAlbumId(deezerIdConnectionId) } else { setAlbumId(id) }
        } else {
            if (idConnectionId) { setAlbumId(idConnectionId) } else { setAlbumId(id) }
        }
        
        if (nameSlug && artistSlug) {
            if (!fromRedirect || !(backend && backend.id)) {
                dispatch( 
                    fetchAlbumFromSlug(nameSlug, artistSlug)
                )
            }
        } else {
            dispatch( 
                fetchAlbum(id, fromDeezer) 
            )
        }

        //Fetch status of album after 1.2 seconds and still loading
        setTimeout(() => {
            if (!hasFetched.current) {
                if (nameSlug && artistSlug) {
                    if (!fromRedirect || !(backend && backend.id)) {
                        dispatch( 
                            checkAlbumDatabaseStatus(`/album/${nameSlug}/${artistSlug}/`, null, null)
                        )
                    }
                } else {
                    dispatch( 
                        checkAlbumDatabaseStatus(null, fromDeezer ? id : null, fromDeezer ? null : id)
                    )
                }
            }
        }, 1200)
    }, [ id, nameSlug, artistSlug, fromDeezer ])

    //Potentially update the albumId if our backend return a different
    useStatusHandler({
        effectStatus: fetchAlbumStatus,
        successCallback: () => {
            if (lastFetchedAlbumId && lastFetchedAlbumId !== albumId && !lastFetchedAlbumIsRefetch) {
                setAlbumId(lastFetchedAlbumId)
            }
        }
    })

    //Reset the hasFetched ref when we change the album
    useEffect(() => {
        hasFetched.current = false

        return(() => {
            if (metadataTimer.current) {
                clearInterval(metadataTimer.current)
            }
        })
    }, [ id, nameSlug, artistSlug ])

    //Get data when we have the correct album
    useEffect(() => {
        //Have fetched backend
        if (backend && backend.id && !hasFetched.current) {
            //Set ref
            hasFetched.current = true;
            
            //If we have the ID, replace url with the URL slug
            if (backend && !nameSlug && !artistSlug) {
                const locationSearchList = location.search ? location.search.replace("?", "").split("&") : []
                locationSearchList.splice(locationSearchList.indexOf("dz"), 1)
                if (locationSearchList.indexOf("rd") === -1) {
                    locationSearchList.push("rd")
                }
                history.replace(`${backend.url_slug}${location.pathname.replace(`/album/${id}`, "").replace("/", "")}${locationSearchList.length > 0 ? "?" : ""}${locationSearchList.join("&")}`)
            } 
            //If we have the url slug of a duplicate, replace url with the URL slug
            else if (
                backend && nameSlug && artistSlug &&
                backend.url_slug !== `/album/${nameSlug}/${artistSlug}/`
            ) {
                const locationSearchList = location.search ? location.search.replace("?", "").split("&") : []
                locationSearchList.splice(locationSearchList.indexOf("dz"), 1)
                if (locationSearchList.indexOf("rd") === -1) {
                    locationSearchList.push("rd")
                }
                history.replace(`${backend.url_slug}${location.pathname.replace(`/album/${id}`, "").replace(`/album/${nameSlug}/${artistSlug}`, "").replace("/", "")}${locationSearchList.length > 0 ? "?" : ""}${locationSearchList.join("&")}`)
            }

            //Fetch the user's rating
            if (isLoggedIn) {
                dispatch( 
                    getMyContentRating(backend.id, 'album', false) 
                )
            }

            //If the metadata is not already completed, start a 2s timer to refetch the album
            if (backend.deezer_id && (
                !backend.metadata_fetched ||
                backend.tracks.filter(track => !track.metadata_fetched).length > 0
            )) {
                metadataTimer.current = setInterval(() => {
                    dispatch( 
                        fetchAlbum(backend.id, false, false, true) 
                    )
                }, 2000)
            }

            //Run CTAs based on params
            if (createRatingParam === "true") {
                if (onMobile) {
                    document.location.href = `musicboard://album/${backend.id}`
                } else if (isLoggedIn) {
                    openPopup("CreateReview", { content: backend, cacheRating: true })
                }
            } else if (listenLaterParam === "true") {
                if (onMobile) {
                    document.location.href = `musicboard://album/${backend.id}`
                } else if (isLoggedIn) {
                    if (wantObj) {
                        openAlert({
                            p: `${backend.title} is already in your`,
                            a: "Listen Later.",
                            href: `/${username}/wantlist`,
                            timer: 5000,
                            style: "red"
                        })
                    } else {
                        dispatch(
                            createWant(backend.id, "album", false)
                        )
                    }
                }
            }

        }

    }, [ backend && backend.id ])

    //Clear the metadata timer when the album is fetched
    useEffect(() => {
        if (backend && backend.id && 
            backend.metadata_fetched && 
            backend.tracks.filter(track => !track.metadata_fetched).length === 0
        ) {
            clearInterval(metadataTimer.current)
        }
    }, [ backend && backend.id, backend && backend.metadata_fetched, fetchAlbumStatus ])

    //Render loading or not found
    if(!backend || !backend.id) {
        if (!initWait && (fetchAlbumStatus === status.SUCCESS || fetchAlbumStatus === status.ERROR)) {
            return (
                <NotFound />
            )
        } else {
            return (
                <div className="full-height">
                    <div className="full-center">
                        <Icon className="margin-auto" icon="loading" color={colors.darkLightGrey} size={32} />

                        {checkAlbumDatabaseResult && 
                         checkAlbumDatabaseResult.result && 
                         (checkAlbumDatabaseResult.urlSlug === `/album/${nameSlug}/${artistSlug}/` || checkAlbumDatabaseResult.id === id || checkAlbumDatabaseResult.deezerId === id) && 
                         (checkAlbumDatabaseResult.result.exists === false || checkAlbumDatabaseResult.result.deezer_fetched === false) &&
                            <p className={styles.loadingInformation}>
                                You're the first on Musicboard to view this album!<br />
                                Please give us a moment to get all the information for you.<br />
                                It might take a few seconds to load.
                            </p>
                        }
                    </div>
                </div>
            )
        }
    }

    //Render
    return (
        <div>
            <MenuBar
            tabs={[
                {
                    text: "Home",
                    redirect: backend.url_slug,
                    extraActive: `/album/${id}`
                },
                {
                    text: "Reviews",
                    redirect: `${backend.url_slug}reviews?order_by=helpful`,
                    extraActive: `/album/${id}/reviews?order_by=helpful`
                },
                {
                    text: "Lists",
                    redirect: `${backend.url_slug}lists?order_by=-like_count`,
                    extraActive: `/album/${id}/lists?order_by=-like_count`
                },
                !isMinimum && {
                    text: "Ratings",
                    redirect: `${backend.url_slug}ratings`,
                    extraActive: `/album/${id}/ratings`
                },
            ]}
            >
                <div className="padding-bottom-32" style={{ position: 'relative', zIndex: 1 }}>
                    {backend && backend.background && backend.background.background_large &&
                        <FullBackground
                        whiteGradient={true}
                        image={backend.background.background_original?.includes(".gif") ? backend.background.background_original : backend.background.background_large}
                        placeholder={backend.background.background_small}
                        marginHeight={200}
                        altText={`Background for ${backend.title}`}
                        />
                    }

                    <div className="page-container section-handler-end padding-top-64">
                        <div className="section-3 section-margin">
                            <div className={isSmallScreen ? "flex-column flex-center" : "flex-row align-end"}>
                                <AlbumCover
                                size={isMobile ? 200 : isMidScreen ? 240 : isBigScreen ? 270 : 300}
                                albumCover={backend.cover_large || backend.cover}
                                altText={backend.title}
                                />

                                <div className={isSmallScreen ? "flex-column flex-center margin-top-24" : "margin-left-24 margin-bottom-16"}>
                                    <h4 className={`black text-2xl ${isSmallScreen && "text-center"}`} style={isMobile ? { fontSize: 28, lineHeight: '38px' } : isMidScreen ? { fontSize: 32, lineHeight: '42px' } : isBigScreen ? { fontSize: 36, lineHeight: '46px' } : {}}>{backend.title}</h4>

                                    {(!isBigScreen || isSmallScreen) &&
                                        <div className="flex-row margin-top-8">
                                            {backend.record_type &&
                                                <p className="highDarkGrey margin-right-12">{displayRecordType(backend.record_type)}</p>
                                            }
                                            
                                            {(backend.release_date || (!backend.release_date && !backend.metadata_fetched)) &&
                                                <div className="dot margin-right-12" />
                                            }

                                            {backend.release_date &&
                                                <p className={`highDarkGrey margin-right-12`}>{moment(backend.release_date).format(backend.release_date_precision === "year" ? "YYYY" : backend.release_date_precision === "month" ? "YYYY-MM" : "YYYY-MM-DD")}</p>
                                            }
                                            
                                            {!backend.release_date && !backend.metadata_fetched &&
                                                <OneLineLoader width={76} height={20} y={7} className="margin-right-12" />
                                            }
                                            
                                            {(backend.nb_tracks || (!backend.nb_tracks && !backend.metadata_fetched)) &&
                                                <div className="dot margin-right-12" />
                                            }

                                            {backend.nb_tracks &&
                                                <p className="highDarkGrey">{backend.nb_tracks} Track{backend.nb_tracks > 1 ? "s" : ""}</p>
                                            }

                                            {!backend.nb_tracks && !backend.metadata_fetched &&
                                                <OneLineLoader width={44} height={20} y={7} />
                                            }
                                        </div>
                                    }

                                    <div className="flex-row margin-top-16">
                                        {backend.album_artists.filter((_, index) => index < 3).map(({ artist }, index) => (
                                            <Link to={artist.url_slug} key={`Album-${backend.id}-ArtistPicture-${artist.id}-${index}`}>
                                                <ProfilePicture 
                                                className={`${styles.artistPicture} ${index === 0 ? styles.artistPictureFirst : ""}`}
                                                style={{ zIndex: index*-1+backend.album_artists.length }}
                                                size={32} 
                                                clickable 
                                                profilePicture={artist.picture_small || artist.picture} 
                                                altText={artist.name} 
                                                />
                                            </Link>
                                        ))}

                                        <div className="grid col-1">
                                            <p className={`medium margin-left-12 one-line ${styles.artistList}`} style={{ fontSize: 16 }}>
                                                {backend.album_artists.map(({ artist }, index) => (
                                                    <span key={`Album-${backend.id}-ArtistList-${artist.id}-${index}`}>
                                                        <Link to={artist.url_slug} className={styles.artistLink}>
                                                            {artist.name}
                                                        </Link>
                                                        
                                                        {index !== backend.album_artists.length - 1 && ", "}
                                                    </span>
                                                ))}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={`section-2 ${isSmallScreen ? "margin-top-32" : "margin-bottom-16"}`}>
                            <Stats
                            hideIcons={(isMidScreen && !isSmallScreen) || isMobile}
                            statistics={[
                                {
                                    number: backend && backend.ratings_count ? checkNull(backend.ratings_count) : '0',
                                    description: "Total ratings"
                                },
                                {
                                    number: backend && backend.average_rating ? oneDecimal(backend.average_rating/2) : 0,
                                    subText: " / 5",
                                    description: "Average rating",
                                    star: true,
                                    active: backend && backend.average_rating
                                },
                                {
                                    number: (backend && backend.myRatings && backend.myRatings.data && backend.myRatings.data[0]) ? (backend.myRatings.data[0].rating/2) : 0,
                                    subText: " / 5",
                                    description: "Your rating",
                                    star: true,
                                    active: (backend && backend.myRatings && backend.myRatings.data && backend.myRatings.data[0] && backend.myRatings.data[0].rating)
                                },
                            ]}
                            />

                            {(backend && backend.myRatings && backend.myRatings.data && backend.myRatings.data[0]) &&
                                <div className={`${isMobile ? "margin-top-32" : "margin-top-16"} ${styles.myRatingContainer}`}>
                                    <div className={styles.myRating}>
                                        <ChangeContentRating contentId={backend.id} contentType="album" fromDeezer={false} iconSize={24} />
                                    </div>

                                    <div className={styles.myRatingOverlayBtn}>
                                        <Button
                                        text={!backend.myRatings.data[0].rating ? backend.record_type ? `Logged ${(backend.record_type === "compile" || backend.record_type === "deluxe" || backend.record_type === "unofficial") ? "Album" : displayRecordType(backend.record_type)}` : "Logged album" : "Edit rating"}
                                        iconName="edit"
                                        showIcon
                                        style={{ cursor: "default" }}
                                        type={!backend.myRatings.data[0].rating ? "green" : "yellow"}
                                        />
                                    </div>
                                </div>
                            }

                            {!(backend && backend.myRatings && backend.myRatings.data && backend.myRatings.data[0]) &&
                                <Button
                                className="margin-top-16"
                                text={isLoggedIn ? backend.record_type ? `Rate ${(backend.record_type === "compile" || backend.record_type === "deluxe" || backend.record_type === "unofficial") ? "Album" : displayRecordType(backend.record_type)}` : `Rate Album` : "Sign up to rate this album"}
                                iconName={isLoggedIn ? "star" : "lock"}
                                showIcon
                                onClicked={() => {
                                    if (isLoggedIn) {
                                        openPopup("CreateReview", { content: backend, cacheRating: true })
                                    } else {
                                        openPopup("SignUp")
                                    }
                                }}
                                />
                            }
                        </div>
                    </div>
                </div>
            </MenuBar>
            
            <Suspense 
            fallback={
                <div className='flex-center empty-text margin-top-16 margin-bottom-16'>
                    <Icon icon="loading" />
                </div>
            }
            >
                <Switch>
                    <Route
                    exact 
                    path={`${match.url}`} 
                    render={() => (
                        <AlbumHome backend={backend} titleSlug={nameSlug} />
                    )} 
                    />

                    <Route
                    exact 
                    path={`${match.path}/reviews`} 
                    render={() => (
                        <ContentReviews backend={backend} titleSlug={nameSlug} />
                    )}
                    />

                    <Route
                    exact 
                    path={`${match.path}/lists`} 
                    render={() => (
                        <ContentLists backend={backend} titleSlug={nameSlug} />
                    )}
                    />

                    <Route
                    exact 
                    path={`${match.path}/ratings`} 
                    render={() => (
                        <ContentRatings backend={backend} titleSlug={nameSlug} />
                    )}
                    />
                </Switch>
            </Suspense>
        </div>
    )
}
