import styles from './singlecomment.module.css'

import React from 'react'
import moment from 'moment'


import ParseUserText from '../../../ParseUserText'
import ProfilePicture from '../../../ProfilePicture'
import { formatTimeAgo } from '../../../../utils/format-time'
import UserRedirect from '../../../UserRedirect'
import Icon from '../../../Icon'
import { checkNull } from '../../../../utils/format-text'
import { usePopup } from '../../../../hooks/popup/popup'
import { useDispatch, useSelector } from 'react-redux'
import { deleteLike, likeContent } from '../../../../actions/likes-actions'
import { status } from '../../../../constants/action-types'
import { Link } from 'react-router-dom'
import VerifiedIcon from '../../../VerifiedIcon'
import ProIcon from '../../../ProIcon'
import UserProfilePicture from '../../../UserProfilePicture'
import { useTrack } from '../../../../hooks/tracking'

function SingleComment({ comment, isReply, rootCommentContentType, rootCommentContentUid, rootCommentUid }) {
    //General
    const { openPopup } = usePopup()
    const dispatch = useDispatch()
    const track = useTrack()

    //Auth
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
    const isModerator = useSelector(state => state.me.is_moderator)
    const isUserModerator = useSelector(state => state.me.is_user_moderator)
    const myUid = useSelector(state => state.auth.uid)
    const isMe = myUid === comment.creator.uid;

    //likeObj
    const deleteLikeContentUid = useSelector(state => state.likes.deleteLikeContentUid)
    const deleteLikeStatus = useSelector(state => state.likes.deleteLikeStatus)
    const myLikes = useSelector(state => state.me.likes);
    const cachedLikes = useSelector(state => state.me.cachedLikes);
    const likes = [ ...myLikes, ...cachedLikes ];
    const likeIndex = likes.findIndex(like => like.content_ct === "comment" && like.content_uid === comment.uid);
    const likeObj = likeIndex !== -1 ? likes[likeIndex] : null;

    //Blocks
    const myBlocks = useSelector(state => state.me.blocks)
    const cachedBlocks = useSelector(state => state.me.cachedBlocks)
    const blocks = [...myBlocks, ...cachedBlocks]
    const blockObj = blocks.findIndex(block => block.blocked_user === comment.creator.uid) !== -1 ? blocks[blocks.findIndex(block => block.blocked_user === comment.creator.uid)] : null

    //blockedBy
    const blockedByObj = useSelector(state => 
        state.me.blockedBy && state.me.blockedBy.find(block => block.creator === comment.creator.uid)
    )

    if (blockedByObj) {
        return null
    }

    if (blockObj) {
        return null
    }

    return (
        <div className={`flex-row align-top ${styles.container} ${isReply ? "margin-top-12 margin-left-48" : "margin-top-16"}`}>
            <Link to={`/${comment.creator.username}`}>
                <UserProfilePicture 
                clickable 
                profilePicture={comment.creator.profile_picture?.includes(".gif") ? comment.creator.profile_picture : comment.creator.profile_picture_small || comment.creator.profile_picture} 
                profileFrame={comment.creator.is_pro && comment.creator.tier === 1 ? comment.creator.profile_frame : null} 
                size={rootCommentUid ? 24 : 32} 
                altText={comment.creator.name !== "" ? comment.creator.name : comment.creator.username}
                />
            </Link>

            <div className={rootCommentUid ? "margin-left-12" : "margin-left-16"}>
                <div className={`${styles.content}`}>
                    <div className="flex-row">
                        <Link to={`/${comment.creator.username}`}>
                            <div className="flex-row">
                                <h6 className={`${styles.username}`}>{comment.creator.username}</h6>

                                {(comment.creator.is_pro || comment.creator.is_moderator || comment.creator.contributor_tier) &&
                                    <ProIcon
                                    size={"small"}
                                    className="margin-left-8"
                                    is_pro={comment.creator.is_pro}
                                    is_moderator={comment.creator.is_moderator}
                                    contributor_tier={comment.creator.contributor_tier}
                                    />
                                }

                                {comment.creator.is_verified &&
                                    <VerifiedIcon size={12} className="margin-left-8" />
                                }
                            </div>
                        </Link>
                    </div>

                    <ParseUserText 
                    className="textColor"
                    style={{ marginTop: 6 }}
                    userTextClassName={`blue ${styles.hoverUnderline}`}
                    text={comment.comment}
                    extraTextElement={comment.has_edited_comment && <span style={{ fontSize: 12, color: "var(--highDarkGrey)" }}> (edited)</span>}
                    appendId={`SingleComment-${comment.uid}`}
                    />
                </div>

                <div className="flex-row margin-top-8">
                    <div 
                    className="cursor" 
                    style={{ height: 18 }}
                    onClick={() => {
                        if (isLoggedIn) {
                            if (likeObj) {
                                dispatch(
                                    deleteLike("comment", comment.uid, likeObj.uid, true, rootCommentContentType, rootCommentContentUid)
                                )
                            } else {
                                track("Liked content", { type: "comment" })

                                dispatch(
                                    likeContent("comment", comment.uid, true, rootCommentContentType, rootCommentContentUid)
                                )
                            }
                        } else {
                            openPopup("SignUp")
                        }
                    }}
                    >
                        <Icon 
                        size={18} 
                        icon={
                            (deleteLikeStatus === status.BEGIN && deleteLikeContentUid === comment.uid) ? 
                                "loading" : 
                                "favorite"
                        } 
                        className={`${styles.favorite} ${(likeObj && !(deleteLikeStatus === status.BEGIN && deleteLikeContentUid === comment.uid)) ? styles.active : ""} ${!(deleteLikeStatus === status.BEGIN && deleteLikeContentUid === comment.uid) ? styles.notLoading : ""}`} 
                        />
                    </div>

                    {!isMe &&
                        <div 
                        className="cursor margin-left-16" 
                        style={{ height: 18 }}
                        onClick={() => {
                            if (!isLoggedIn) {
                                openPopup("Login")
                                return;
                            }
                            
                            openPopup("ReportContent", { content: comment, type: "comment" })
                        }}
                        >
                            <Icon 
                            size={18} 
                            icon="flag" 
                            className={`${styles.clickable}`} 
                            />
                        </div>
                    }

                    {(isModerator || isUserModerator) &&
                        <div 
                        className="margin-left-16" 
                        style={{ height: 18 }}
                        >
                            <a 
                            href={`https://api.musicboard.app/admin/reviews/comment/${comment.id}`}
                            target='_blank'
                            rel='noreferrer noopener'
                            >
                                <Icon 
                                size={18} 
                                icon="settings" 
                                className={`${styles.clickable}`} 
                                />
                            </a>
                        </div>
                    }

                    <h6 
                    onClick={() => {
                        openPopup("ListLikes", { contentType: "comment", contentUid: comment.uid })
                    }} 
                    className={`${styles.clickable} margin-left-16 cursor`}
                    >
                        {checkNull(comment.like_count)} {checkNull(comment.like_count) === 1 ? "Like" : "Likes"}
                    </h6>

                    {isMe &&
                        <h6 
                        onClick={() => {
                            openPopup("EditComment", { commentUid: comment.uid, rootCommentUid, contentUid: rootCommentContentUid, contentType: rootCommentContentType, text: comment.comment })
                        }} 
                        className={`${styles.clickable} margin-left-16 cursor`}
                        >
                            Edit
                        </h6>
                    }

                    {isMe &&
                        <h6 
                        onClick={() => {
                            openPopup("DeleteComment", { commentUid: comment.uid, rootCommentUid, contentUid: rootCommentContentUid, contentType: rootCommentContentType })
                        }} 
                        className={`${styles.clickable} margin-left-16 cursor`}
                        >
                            Delete
                        </h6>
                    }

                    <h6 
                    onClick={() => {
                        openPopup("WriteComment", { text: `@${comment.creator.username} `, commentUid: rootCommentUid ? rootCommentUid : comment.uid, contentUid: rootCommentContentUid, contentType: rootCommentContentType })
                    }} 
                    className={`${styles.clickable} margin-left-16 cursor`}
                    >
                        Reply
                    </h6>

                    <p className="highDarkGrey margin-left-16">{formatTimeAgo(comment.created_at)}</p>
                </div>
            </div>
        </div>
    )
}

export default React.memo(SingleComment)
