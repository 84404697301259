import styles from './SponsoredReviewThis.module.css'

import React from 'react'
import { Link } from 'react-router-dom'

import Icon from '../Icon'
import AlbumCover from '../AlbumCover'

export default function SponsoredReviewThis({ promo, className }) {
    return (
        <div>
            <div 
            className={`${className} ${styles.container} link-wrapper`}
            style={{
                '--r': promo.color_r,
                '--g': promo.color_g,
                '--b': promo.color_b,
            }}
            >
                <Link 
                className="link-overlay"
                to={promo.content.url_slug} 
                />

                <div 
                className={`flex-row link-inner ${styles.inner}`}
                >
                    <Link to={promo.content.url_slug}>
                        <AlbumCover 
                        albumCover={promo.image_medium}
                        size={96}
                        clickable
                        />
                    </Link>

                    <div className='col-1 flex-column align-top margin-right-16 margin-left-16'>
                        <h6
                        className={styles.featuredText}
                        style={{ color: `rgba(${promo.color_r}, ${promo.color_g}, ${promo.color_b}, 1)` }}
                        >
                            {promo.tag}
                        </h6>

                        <Link to={promo.content.url_slug}>
                            <h4 className={`margin-top-4 ${styles.title}`}>{promo.title}</h4>
                        </Link>

                        <Link to={promo.content.artist.url_slug}>
                            <p className={`text-mid margin-top-4 ${styles.artist}`}>{promo.artist}</p>
                        </Link>
                    </div>

                    <div className='flex-column-max-size'>
                        <button 
                        className={`${styles.button} link-clickable`}
                        style={{ color: "var(--white)" }}
                        >
                            <Icon 
                            icon="spotify" 
                            size={20} 
                            className='margin-right-8' 
                            color={"var(--white)"} 
                            />

                            {promo.cta_label}
                        </button>
                        
                        <button 
                        className={`${styles.button} margin-top-8 link-clickable`}
                        style={{ backgroundColor: `rgba(${promo.color_r}, ${promo.color_g}, ${promo.color_b}, 0.1)`, color: "var(--black)" }}
                        >
                            {"Review"}
                        </button>
                    </div>
                </div>
            </div>

            {promo.show_sponsored_tag && (
                <p className={styles.tag}>Sponsored</p>
            )}
        </div>
    )
}
