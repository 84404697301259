import { store } from '../store';

const customFetch = (path, opt, timeoutLimit = 10000, signal) => {
    /* global fetch */
    const req = fetch(path, signal ? { ...opt, signal: signal } : opt);
    return timeoutPromise(req, timeoutLimit, 'timeout')
  }
  
  function timeoutPromise(promise, timeout, error = '') {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(error);
      }, timeout);
      promise.then(resolve, reject);
    });
  }

const defaultHeader = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'X-New-Payment-System': 'true',
    'X-New-Contribution-System': 'true',
};

const opts = {
    put: (data) => ({
        method: 'PUT',
        body: data && JSON.stringify(data),
        rawBody: data,
        headers: { ...defaultHeader },
    }),
    patch: (data) => ({
        method: 'PATCH',
        body: data && JSON.stringify(data),
        rawBody: data,
        headers: { ...defaultHeader },
    }),
    patchRaw: (data) => ({
        method: 'PATCH',
        body: data,
        rawBody: data,
        headers: { 'X-New-Payment-System': 'true', 'X-New-Contribution-System': 'true', },
    }),
    get: () => ({
      method: 'GET',
      headers: { ...defaultHeader },
    }),
    post: (data) => ({
      method: 'POST',
      body: JSON.stringify(data),
      rawBody: data,
      headers: { ...defaultHeader },
    }),
    postRaw: (data) => ({
      method: 'POST',
      body: data,
      rawBody: data,
      headers: { 'X-New-Payment-System': 'true', 'X-New-Contribution-System': 'true', },
    }),
    delete: () => ({
      method: 'DELETE',
      headers: { ...defaultHeader },
    }),
};

export const _ajaxRequest = (url, opts, timeout, signal, accessToken, tokenType, skipAuth=false) => new Promise((resolve, reject) => {

        console.log("try to fetch", url, opts)

        const state = store.getState();

        //Maybe we want to skip adding the Authorization header. Used for uploading to S3
        if (!skipAuth) {
          //Custom accessToken and tokenType
          if (accessToken && tokenType) {
            opts.headers = {
              ...opts.headers,
              Authorization: `${tokenType} ${accessToken}`, 
            };
  
          }
          else if (state.auth && state.auth.token) {
              opts.headers = {
                ...opts.headers,
                Authorization: `Token ${state.auth.token}`, 
              };
          } else if (state.auth && state.auth.tokenType && state.auth.accessToken) {
              opts.headers = {
                ...opts.headers,
                Authorization: `${state.auth.tokenType} ${state.auth.accessToken}`,
              };
          }
      }

        let statusCode = -1;

        customFetch(url, opts, timeout, signal)
        .then(res => {
            statusCode = res.status;
            return res.text(); 
        })
        .then(response => {
            console.log(url, {response}, statusCode)
            try {
                const json = response ? JSON.parse(response) : null;
                if (statusCode >= 200 && statusCode < 300){
                    console.log('feftch data', json);
                    resolve(json);
                } else{
                    console.log("failed to fetch", JSON.stringify(json), statusCode);
                    reject(json);
                }
            }
            catch(err) {
                console.log("failed to fetch", JSON.stringify(err));
                reject(err)
            };
        })
        .catch(error => reject(error));

});


export const putNormal = (path, body, timeout = 60000, signal, accessToken, tokenType, skipAuth) => _ajaxRequest(path, opts.put(body), timeout, signal, accessToken, tokenType, skipAuth);
export const patchNormal = (path, body, timeout = 60000, signal, accessToken, tokenType, skipAuth) => _ajaxRequest(path, opts.patch(body), timeout, signal, accessToken, tokenType, skipAuth);
export const patchRawNormal = (path, body, timeout = 60000, signal, accessToken, tokenType, skipAuth) => _ajaxRequest(path, opts.patchRaw(body), timeout, signal, accessToken, tokenType, skipAuth);
export const postNormal = (path, body, timeout = 60000, signal, accessToken, tokenType, skipAuth) => _ajaxRequest(path, opts.post(body), timeout, signal, accessToken, tokenType, skipAuth);
export const postRawNormal = (path, body, timeout = 60000, signal, accessToken, tokenType, skipAuth) => _ajaxRequest(path, opts.postRaw(body), timeout, signal, accessToken, tokenType, skipAuth);
export const getNormal = (path, timeout = 60000, signal, accessToken, tokenType, skipAuth) => _ajaxRequest(path, opts.get(), timeout, signal, accessToken, tokenType, skipAuth);
export const deleteNormal = (path, timeout = 60000, signal, accessToken, tokenType, skipAuth) => _ajaxRequest(path, opts.delete(), timeout, signal, accessToken, tokenType, skipAuth);
